/* ------------------------------------------------------------------------------

  Template Name: Rio Aarman
  Description: Rio Aarman Ecommerce
  Author: templatecookie
  Author URI: 
  Version: 1.0
  
    1.  Global
        1.1 Typography
        1.2 General
        1.3 Reuseable

    2.  Layout  

        2.1 Loader
        2.2 Header
        2.3 Mobile Menu
        2.4 Banner
        2.5 Sales Banner
        2.6 Products
        2.7 Category
        2.8 Newsletter
        2.9 Hero
        2.10 Blog Sidebar
        2.11 Shop Sidebar
        2.12 Breadcrumb
        2.13 Contact-form
        2.14 Maps
        2.15 Footer

    3.  Components

        3.1 Bar
        3.2 Dots
        3.3 Arrow
        3.4 Buttons
        3.5 Brands
        3.6 Form
        3.7 Cards
        3.8 Countdown
        3.9 Accordion
        3.10 Social Icons
        3.11 Pagination
        3.12 Counter
        3.13 Navigation Card
        3.14 Progress Bar
        3.15 Counter Button
        3.16 Range Slider

  4.  Pages

        4.1 Home
        4.2 About
        4.3 Blog Details
        4.4 FAQ
        4.5 Blogs
        4.6 Shop
        4.7 Product Details
        4.8 Dashboard
        4.9 Account
        4.10 Error

/*-------------------------------------------------------------------------------
  1. Global
-------------------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&amp;display=swap");
@import url("https://fonts.cdnfonts.com/css/segoe-script");

.font-title--xxxl {
  font-size: 72px;
  font-weight: 600;
  position: relative;
  text-transform: capitalize;
}

.font-title--xxxl-n {
  font-size: 72px;
  font-weight: 400;
  position: relative;
  text-transform: capitalize;
}

.font-title--xxl {
  font-size: 56px;
  font-weight: 600;
  position: relative;
  text-transform: capitalize;
}

.font-title--xxl-n {
  font-size: 56px;
  font-weight: 400;
  position: relative;
  text-transform: capitalize;
}

.font-title--xl {
  font-size: 48px;
  font-weight: 600;
  position: relative;
  text-transform: capitalize;
}

.font-title--xl-n {
  font-size: 48px;
  font-weight: 400;
  position: relative;
  text-transform: capitalize;
}

.font-title--lg {
  font-size: 40px;
  font-weight: 600;
  position: relative;
  text-transform: capitalize;
}

.font-title--lg-n {
  font-size: 40px;
  font-weight: 400;
  position: relative;
  text-transform: capitalize;
}

.font-title--md {
  font-size: 36px;
  font-weight: 600;
  position: relative;
  text-transform: capitalize;
}

.font-title--md-n {
  font-size: 36px;
  font-weight: 400;
  position: relative;
  text-transform: capitalize;
}

.font-title--sm {
  font-size: 32px;
  font-weight: 600;
  position: relative;
  text-transform: capitalize;
}

.font-title--sm-n {
  font-size: 32px;
  font-weight: 400;
  position: relative;
  text-transform: capitalize;
}

.font-body--xxxl-400 {
  font-size: 24px;
  font-weight: 400;
}

.font-body--xxxl-500 {
  font-size: 24px;
  font-weight: 500;
}

.font-body--xxxl-600 {
  font-size: 24px;
  font-weight: 600;
}

.font-body--xxl-400 {
  font-size: 20px;
  font-weight: 400;
}

.font-body--xxl-500 {
  font-size: 20px;
  font-weight: 500;
}

.font-body--xxl-600 {
  font-size: 20px;
  font-weight: 600;
}

.font-body--xl-400 {
  font-size: 18px;
  font-weight: 400;
}

.font-body--xl-500 {
  font-size: 18px;
  font-weight: 500;
}

.font-body--xl-600 {
  font-size: 18px;
  font-weight: 600;
}

.font-body--lg-400 {
  font-size: 16px;
  font-weight: 400;
}

.font-body--lg-500 {
  font-size: 16px;
  font-weight: 500;
}

.font-body--lg-600 {
  font-size: 16px;
  font-weight: 600;
}

.font-body--md-400 {
  font-size: 14px;
  font-weight: 400;
}

.font-body--md-500 {
  font-size: 14px;
  font-weight: 500;
}

.font-body--md-600 {
  font-size: 14px;
  font-weight: 600;
}

.font-body--sm-400 {
  font-size: 12px;
  font-weight: 400;
}

.font-body--sm-500 {
  font-size: 12px;
  font-weight: 500;
}

.font-body--sm-600 {
  font-size: 12px;
  font-weight: 600;
}

.section--xxl {
  padding: 100px 0px;
}

.section--my-xxl {
  margin: 100px 0px;
}

.section--xl {
  padding: 80px 0px;
}

.section--my-xl {
  margin: 80px 0px;
}

.section--lg {
  padding: 60px 0px;
}

.section--my-lg {
  margin: 60px 0px;
}

.section--md {
  padding: 30px 0px;
}

.section--my-md {
  margin: 30px 0px;
}

.section--sm {
  padding: 15px 0px;
}

.section--my-sm {
  margin: 15px 0px;
}

.section--soft-primary {
  background-color: #84d187;
}

.bg--soft-primary {
  background-color: #84d187;
}

.section--primary {
  background-color: #01b0df;
}

.bg--primary {
  background-color: #01b0df;
}

.section--hard-primary {
  background-color: #2c742f;
}

.bg--hard-primary {
  background-color: #2c742f;
}

.section--warning {
  background-color: #ff8a00;
}

.bg--warning {
  background-color: #ff8a00;
}

.section--danger {
  background-color: #ea4b48;
}

.bg--danger {
  background-color: #ea4b48;
}

.section--white {
  background-color: #fff;
}

.bg--white {
  background-color: #fff;
}

.section--gray-9 {
  background-color: #1a1a1a;
}

.bg--gray-9 {
  background-color: #1a1a1a;
}

.section--gray-8 {
  background-color: #333333;
}

.bg--gray-8 {
  background-color: #333333;
}

.section--gray-7 {
  background-color: #4d4d4d;
}

.bg--gray-7 {
  background-color: #4d4d4d;
}

.section--gray-6 {
  background-color: #666666;
}

.bg--gray-6 {
  background-color: #666666;
}

.section--gray-5 {
  background-color: #808080;
}

.bg--gray-5 {
  background-color: #808080;
}

.section--gray-4 {
  background-color: #999999;
}

.bg--gray-4 {
  background-color: #999999;
}

.section--gray-3 {
  background-color: #b2b2b2;
}

.bg--gray-3 {
  background-color: #b2b2b2;
}

.section--gray-2 {
  background-color: #cccccc;
}

.bg--gray-2 {
  background-color: #cccccc;
}

.section--gray-1 {
  background-color: #e5e5e5;
}

.bg--gray-1 {
  background-color: #e5e5e5;
}

.section--gray-0 {
  background-color: #f2f2f2;
}

.bg--gray-0 {
  background-color: #f2f2f2;
}

.section--gray {
  background-color: #f7f7f7;
}

.bg--gray {
  background-color: #f7f7f7;
}

.section--green-9 {
  background-color: #002603;
}

.bg--green-9 {
  background-color: #002603;
}

.section--green-8 {
  background-color: #173b1a;
}

.bg--green-8 {
  background-color: #173b1a;
}

.section--green-7 {
  background-color: #2b572e;
}

.bg--green-7 {
  background-color: #2b572e;
}

.section--green-6 {
  background-color: #406b42;
}

.bg--green-6 {
  background-color: #406b42;
}

.section--green-5 {
  background-color: #618062;
}

.bg--green-5 {
  background-color: #618062;
}

.section--green-4 {
  background-color: #7a997c;
}

.bg--green-4 {
  background-color: #7a997c;
}

.section--green-3 {
  background-color: #96b297;
}

.bg--green-3 {
  background-color: #96b297;
}

.section--green-2 {
  background-color: #b4ccb4;
}

.bg--green-2 {
  background-color: #b4ccb4;
}

.section--green-1 {
  background-color: #dae5da;
}

.bg--green-1 {
  background-color: #dae5da;
}

.section--green-0 {
  background-color: #edf2ee;
}

.bg--green-0 {
  background-color: #edf2ee;
}

/** 1.1 Typography */
.font-title--xxxl,
.font-title--xxxl-n {
  line-height: 86.4px;
}
@media (max-width: 767px) {
  .font-title--xxxl,
  .font-title--xxxl-n {
    font-size: 28px;
    line-height: 38px;
  }
}

.font-title--xxl,
.font-title--xxl-n {
  line-height: 67.2px;
}
@media (max-width: 767px) {
  .font-title--xxl,
  .font-title--xxl-n {
    font-size: 28px;
    line-height: 38px;
  }
}

.font-title--xl,
.font-title--xl-n {
  line-height: 57.6px;
}
@media (max-width: 767px) {
  .font-title--xl,
  .font-title--xl-n {
    font-size: 28px;
    line-height: 38px;
  }
}

.font-title--lg,
.font-title--lg-n {
  line-height: 48px;
}
@media (max-width: 767px) {
  .font-title--lg,
  .font-title--lg-n {
    font-size: 28px;
    line-height: 38px;
  }
}

.font-title--md,
.font-title--md-n {
  line-height: 43.2px;
}
@media (max-width: 767px) {
  .font-title--md,
  .font-title--md-n {
    font-size: 28px;
    line-height: 38px;
  }
}

.font-title--sm,
.font-title--sm-n {
  line-height: 38.4px;
}
@media (max-width: 767px) {
  .font-title--sm,
  .font-title--sm-n {
    font-size: 28px;
    line-height: 38px;
  }
}

.font-body--xxxl-400,
.font-body--xxxl-500,
.font-body--xxxl-600 {
  line-height: 36px;
}

.font-body--xxl-400,
.font-body--xxl-500,
.font-body--xxl-600 {
  line-height: 30px;
}

.font-body--xl-400,
.font-body--xl-500,
.font-body--xl-600 {
  line-height: 27px;
}

.font-body--lg-400,
.font-body--lg-500,
.font-body--lg-600 {
  line-height: 24px;
}

.font-body--md-400,
.font-body--md-500,
.font-body--md-600 {
  line-height: 21px;
}

.font-body--sm-400,
.font-body--sm-500,
.font-body--sm-600 {
  line-height: 15.6px;
}

/* 1.2 General */
* {
  margin: 0;
  padding: 0;
}

img {
  border: 0;
  outline: 0;
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}

button {
  margin: 0;
  border: 0;
  outline: none;
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
button:focus {
  outline: none;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
}

a {
  display: inline-block;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

.box-layout {
  max-width: 1400px;
  margin: 40px auto;
  background-color: #fff;
  padding: 0px 40px;
}
@media (max-width: 1200px) {
  .box-layout {
    padding: 0px;
    margin: 0px;
  }
}

@media (min-width: 1280px) {
  .frz-mobile-footer {
    display: none;
  }
  .gym {
    display: none;
  }
}
/** 1.3 Reuseable */
.modal-backdrop {
  background: #000000;
  opacity: 0.8;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-40 {
  margin-bottom: 40px;
}

.bv_mainselect {
  padding: 5px 5px 0;
}

.bv_mainselect > .bv_background {
  min-width: 150px;
  padding: 10px 16px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  position: relative;
  color: #4d4d4d;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.bv_mainselect > .bv_background .arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  -webkit-transform: translateY(-100%) rotate(45deg);
  transform: translateY(-100%) rotate(45deg);
  border-width: 0 2px 2px 0;
}
.bv_mainselect .bv_background.bv_ul_inner {
  padding: 4px 5px;
}

/** 2.1 Loader Layout */
.loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
}

/** 2.2 Header Layout */
.header__top-content,
.header__center-content,
.header__bottom-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.header .cart-bag {
  position: relative;
  margin-right: 16px;
}
.header .cart-bag .item-number {
  position: absolute;
  top: -2px;
  right: -4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #01b0df;
  font-size: 10px;
  line-height: 10px;
  color: #fff;
  font-weight: 500;
}
.header .cart-bag:hover {
  color: #2c742f;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header .cart-bag:hover .item-number {
  top: 0px;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.header .fav:hover,
.header .user-icon:hover {
  color: #01b0df;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header__cart-single .header__telephone-number {
  margin-right: 30px;
}
.header__cart-single .icon {
  position: relative;
  color: #1a1a1a;
  margin-right: 25px;
}
.header__cart-single .icon:hover {
  color: #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.header__cart-single .icon:nth-last-child(1) {
  margin-right: 0px;
}
.header__cart-single .icon.shopping-bag .numb {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #2c742f;
  border: 1px solid #fff;
  top: 0;
  right: 0px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
}
.header__telephone-number.dark span {
  color: #1a1a1a;
}
.header__brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header__top {
  padding: 13px 0px;
  -webkit-box-shadow: 0px 1px 0px 0px #e5e5e5;
  box-shadow: 0px 1px 0px 0px #e5e5e5;
}
.header__top-content .header__top-left p,
.header__top-content .header__dropdown .bv_mainselect .bv_background,
.header__top-content .header__in a,
.header__top-content .header__in span {
  font-size: 1rem !important;
  line-height: 15.6px !important;
  color: #ffffff;
}
.header__top-left p {
  color: #ffffff;
}
.header__top-left p span {
  margin-right: 8px;
}
.header__top-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header__top-right .header__dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid #e5e5e5;
}
.header__top-right .header__dropdown .bv_mainselect {
  padding: 0px;
  border-radius: 0px;
  border-color: #b2b2b2;
  text-transform: capitalize;
}
.header__top-right .header__dropdown .bv_mainselect .bv_atual.bv_background {
  min-width: 60px;
  color: #666666;
  border: 0px;
  padding: 0px;
  padding-right: 20px;
  background: transparent;
}
.header__top-right
  .header__dropdown
  .bv_mainselect
  .bv_atual.bv_background:nth-last-child(1) {
  padding-right: 0px;
}
.header__top-right
  .header__dropdown
  .bv_mainselect
  .bv_atual.bv_background
  .arrow {
  border: solid #1a1a1a;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  pointer-events: none;
  margin-left: 8px;
}
.header__top-right .header__dropdown .bv_mainselect .bv_ul_inner {
  width: 150px !important;
  text-transform: capitalize;
  z-index: 999;
}
@media (max-width: 767px) {
  .header__top-right .header__dropdown .bv_mainselect .bv_ul_inner {
    height: 150px !important;
    overflow-y: auto;
  }
}
.header__top-right .header__dropdown .bv_mainselect .bv_ul_inner li:hover {
  background-color: #01b0df;
  color: #fff;
}
.header__top-right
  .header__dropdown
  .bv_mainselect
  .bv_ul_inner
  li.bv_disabled {
  background-color: #84d187 !important;
  color: #fff;
}
.header__top-right .header__in {
  padding-left: 20px;
  color: #666666;
}
.header__top-right .header__in a {
  color: #666666;
  font-weight: 400;
}
.header__center {
  padding: 24px 0px;
}
.header__input-form {
  width: 498px;
  height: 45px;
  position: relative;
}
.header__input-form input {
  width: inherit;
  height: inherit;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  font-size: 15px;
  line-height: 21px;
  color: #1a1a1a;
  padding-left: 45px;
}
.header__input-form input::-webkit-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.header__input-form input:-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.header__input-form input::-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.header__input-form input::placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.header__input-form input:hover,
.header__input-form input:focus {
  border-color: #84d187;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.header__input-form .search-btn,
.header__input-form .search-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.header__input-form .search-icon {
  left: 18.5px;
  color: #1a1a1a;
}
.header__input-form .search-btn {
  right: 0px;
  border-radius: 0px 6px 6px 0px;
}
.header__cart,
.header__cart-item-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 767px) {
  .header__cart {
    margin-left: 12px;
  }
}
.header__cart-item-content-info h5 {
  font-size: 11px;
  line-height: 13.2px;
  font-weight: 400;
  color: #4d4d4d;
  white-space: nowrap;
}
.header__cart-item-content-info span {
  font-size: 14px;
  line-height: 14px;
  color: #1a1a1a;
  font-weight: 500;
}
.header__cart-item {
  -webkit-box-flex: 0;
  -ms-flex: 0;
  flex: 0;
}
.header__cart-item a {
  padding-right: 18px;
  border-right: 1px solid #cccccc;
}
@media (max-width: 767px) {
  .header__cart-item a {
    padding-right: 5px;
    border: 0px;
  }
}
.header__cart-item:nth-last-child(1) a {
  border-right: 0px;
  padding-right: 0px;
}
.header__cart-item-content {
  padding-left: 20px;
}
@media (max-width: 767px) {
  .header__cart-item-content {
    padding-left: 5px;
  }
}
.header__contact-info-number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header__contact-info-number span {
  color: #1a1a1a;
  margin-right: 16px;
}
.header__contact-info-number--info h5 {
  font-size: 14px;
  line-height: 21px;
  color: #999999;
}
.header__contact-info-number--info p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #1a1a1a;
}
.header__bottom {
  background-color: #333333;
}
.header__bottom--white {
  background-color: #fff;
  border-top: 1px solid #e5e5e5;
}
.header__navigation-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header__navigation-menu-link {
  margin-right: 35px;
  position: relative;
  padding: 19.5px 0px;
}
.header__navigation-menu-link:nth-last-child(1) {
  margin-right: 0px;
}
.header__navigation-menu-link a {
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
}
.header__navigation-menu-link.active a {
  color: #fff;
}
.header__navigation-menu-link:hover .header__navigation-drop-menu {
  top: 100%;
  opacity: 9;
  color: #01b0df;
  pointer-events: all;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header__navigation-menu-link:hover a {
  color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.header__navigation-menu-link:hover a span svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.header__navigation-menu--two .header__navigation-menu-link.active a {
  color: #01b0df;
}
.header__navigation-menu--two .header__navigation-menu-link a {
  color: #666666;
}

.header__navigation-drop-menu::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

.header__navigation-drop-menu::-webkit-scrollbar-thumb {
  background-color: #01b0df;
  border-radius: 10px;
}

.header__navigation-drop-menu::-webkit-scrollbar-track {
  background-color: #000 !important;
  border-radius: 10px;
}
.header__navigation-drop-menu {
  position: absolute;
  top: 90%;
  left: 0;
  z-index: 99;
  width: 240px;
  height: 350px;

  overflow-y: scroll;
  border-radius: 6px;

  background-color: #fff;
  -webkit-box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.12);
  opacity: 0;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.header__navigation-drop-menu-link:hover,
.header__navigation-drop-menu-link.active {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(0, 179, 7, 0.1)),
      to(rgba(0, 179, 7, 0.1))
    ),
    -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(#ffffff));
  background: linear-gradient(0deg, rgba(0, 179, 7, 0.1), rgba(0, 179, 7, 0.1)),
    linear-gradient(0deg, #ffffff, #ffffff);
}
.header__navigation-drop-menu-link:hover a,
.header__navigation-drop-menu-link.active a {
  color: #01b0df !important;
}
.header__navigation-drop-menu-link a {
  font-size: 14px;
  line-height: 21px;
  color: #4d4d4d !important;
  font-weight: 400;
  padding: 8px 20px;
  width: 100%;
}
.header__telephone-number span {
  color: #fff;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
}
.header__telephone-number span svg {
  margin-right: 5px;
}
.header--one .header__top {
  background-color: #01b0df;
}
.header--one .header__top-left p {
  color: #fff;
}
.header--one
  .header__top-right
  .header__dropdown
  .bv_mainselect
  .bv_atual.bv_background {
  min-width: 60px !important;
  color: #fff;
}
.header--one
  .header__top-right
  .header__dropdown
  .bv_mainselect
  .bv_atual.bv_background
  .arrow {
  border-color: #b2b2b2;
}
.header--one .header__top-right .header__in a {
  color: #fff;
}
.header--two .header__dropdown {
  border-right: 0px;
}
.header--two .header__top {
  padding: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.header--two .header__top-content {
  padding: 13px 0px;
  -webkit-box-shadow: 0px 1px 0px 0px #e5e5e5;
  box-shadow: 0px 1px 0px 0px #e5e5e5;
}
@media (max-width: 767px) {
  .header--two .header__top-left {
    display: none;
  }
}
@media (max-width: 767px) {
  .header--two .header__top-right {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .header--two .header__top-right div:nth-child(1) {
    width: 30%;
  }
}
@media (max-width: 767px) {
  .header--two .header__top-right div:nth-child(2) {
    width: 70%;
  }
}
@media (max-width: 767px) {
  .header--two .header__top-right .header__in {
    text-align: right;
  }
}
.header--two .header__bottom {
  background-color: transparent;
}
.header--two .header__bottom-content {
  background-color: #1a1a1a;
}
.header--two .header__bottom-right {
  padding-right: 30px;
}
@media (max-width: 767px) {
  .header--two .header__bottom-right {
    padding-right: 0px;
  }
}
.header--two .header__bottom-left,
.header--two .header__category-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 767px) {
  .header--two .header__bottom-left,
  .header--two .header__category-content {
    width: 100%;
  }
}
.header--two .header__category-content {
  position: relative;
  background-color: #01b0df;
}
@media (max-width: 767px) {
  .header--two .header__category-content-item {
    width: 100%;
  }
}
.header--two .header__category-content-item a {
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 25px;
}
@media (max-width: 767px) {
  .header--two .header__category-content-item a {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.header--two .header__category-content-item a .bar {
  position: relative;
  width: 18px;
  height: 1.5px;
  background-color: #fff;
  border-radius: 4px;
  display: inline-block;
  margin-right: 12px;
}
.header--two .header__category-content-item a .bar::after,
.header--two .header__category-content-item a .bar::before {
  content: "";
  position: absolute;
  left: 0;
  width: 18px;
  height: 1.5px;
  background-color: #fff;
  border-radius: 4px;
  display: inline-block;
}
.header--two .header__category-content-item a .bar::after {
  top: -7px;
}
.header--two .header__category-content-item a .bar::before {
  bottom: -7px;
}
.header--two .header__category-content-item a .toggle-icon {
  margin-left: 12px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.header--two .header__category-content-dropdown {
  position: absolute;
  top: 90%;
  left: 0;
  width: 250px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 24px 0px #0000001f;
  box-shadow: 0px 0px 24px 0px #0000001f;
  border: 1px solid #e5e5e5;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  padding: 10px 0px;
}
@media (max-width: 767px) {
  .header--two .header__category-content-dropdown {
    width: 100%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
.header--two .header__category-content-dropdown li:hover {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(32, 181, 38, 0.1)),
      to(rgba(32, 181, 38, 0.1))
    ),
    -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(#ffffff));
  background: linear-gradient(
      0deg,
      rgba(32, 181, 38, 0.1),
      rgba(32, 181, 38, 0.1)
    ),
    linear-gradient(0deg, #ffffff, #ffffff);
}
.header--two .header__category-content-dropdown li:hover a .icon {
  color: #01b0df;
}
.header--two .header__category-content-dropdown li a {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  text-transform: capitalize;
  color: #1a1a1a;
  padding: 9.5px 20px;
}
@media (max-width: 767px) {
  .header--two .header__category-content-dropdown li a {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
.header--two .header__category-content-dropdown li a .icon {
  color: #808080;
  margin-right: 12px;
}
.header--two
  .header__category-content:hover
  .header__category-content-item
  a
  .toggle-icon {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.header--two
  .header__category-content:hover
  .header__category-content-dropdown {
  top: 100%;
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header--two .header__category-content.dark {
  background-color: #333333;
}
.header--two .header__category-content.dark .header__category-content-item {
  padding-right: 30px;
}
@media (max-width: 767px) {
  .header--two .header__category-content.dark .header__category-content-item {
    padding-right: 15px;
  }
}
.header--two .header__category-content.dark .header__category-content-item a {
  padding: 0px;
  font-size: 16px;
  line-height: 24px;
}
.header--two
  .header__category-content.dark
  .header__category-content-item
  a
  .toggle-icon {
  margin-left: 70px;
}
@media (max-width: 1200px) {
  .header--two
    .header__category-content.dark
    .header__category-content-item
    a
    .toggle-icon {
    margin-left: 25px;
  }
}
@media (max-width: 767px) {
  .header--two
    .header__category-content.dark
    .header__category-content-item
    a
    .toggle-icon {
    margin-left: 8px;
  }
}
.header--two
  .header__category-content.dark
  .header__category-content-item
  .bar-content {
  padding: 23px 20px;
  background-color: #01b0df;
  margin-right: 16px;
}
@media (max-width: 1200px) {
  .header--two
    .header__category-content.dark
    .header__category-content-item
    .bar-content {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .header--two
    .header__category-content.dark
    .header__category-content-item
    .bar-content {
    padding: 14px;
    margin-right: 12px;
  }
}
.header--two
  .header__category-content.dark
  .header__category-content-item
  .bar-content
  .bar {
  margin-right: 0px;
  width: 24px;
  height: 2px;
}
.header--two
  .header__category-content.dark
  .header__category-content-item
  .bar-content
  .bar::after,
.header--two
  .header__category-content.dark
  .header__category-content-item
  .bar-content
  .bar::before {
  width: 24px;
  height: 2px;
}
.header--two
  .header__category-content.dark
  .header__category-content-item
  .bar-content
  .bar::before {
  bottom: -9px;
}
.header--two
  .header__category-content.dark
  .header__category-content-item
  .bar-content
  .bar::after {
  top: -9px;
}
.header--two .header__category-content.dark .header__category-content-dropdown {
  width: 100%;
}
.header--two
  .header__category-content.dark
  .header__category-content-dropdown
  li
  a {
  padding: 9.5px 20px;
}
.header--two .header__category-content-menu-bar button {
  color: #fff;
  width: 64px;
  height: 64px;
  background-color: #01b0df;
}
.header--two .header__category-content .bv_mainselect {
  padding: 0px;
}
.header--two .header__category-content .bv_atual.bv_background {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 248px !important;
  height: 64px;
  border-radius: 0px;
  padding: 0px;
  border: 0px;
  background-color: #333333;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #fff;
  padding-left: 16px;
}
@media (max-width: 1200px) {
  .header--two .header__category-content .bv_atual.bv_background {
    width: 150px !important;
  }
}
.header--two .header__category-content .bv_atual.bv_background .arrow {
  position: absolute;
  top: 40%;
  right: 30px;
  border: solid #fff;
  border-width: 0px 1.8px 1.8px 0px;
}
.header--two .header__category-content .bv_ul_inner.bv_background {
  z-index: 9;
}
.header--two .header__navigation-menu {
  margin-left: 32px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .header--two .header__navigation-menu-link {
    margin-right: 12px !important;
  }
}
.header--two .header__activity-icons button {
  margin-right: 28px;
  color: #fff;
}
.header--two .header__activity-icons button:nth-last-child(1) {
  margin-right: 0px;
}
@media (max-width: 767px) {
  .header--two .header__activity-icons a svg,
  .header--two .header__activity-icons button svg {
    height: 22px;
  }
}
.header--four .header__top {
  padding: 13px 0px;
  background-color: #333333;
}
.header--four .header__top-content {
  padding: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.header--four .header__top-left p,
.header--four .header__top-left a,
.header--four .header__top-right .header__in p,
.header--four .header__top-right .header__in a {
  color: #b2b2b2;
}
.header--four .header__top-right .header__dropdown {
  border-right: 1px solid #ffffff1a;
}
.header--four .header__top-right .bv_mainselect .bv_atual.bv_background {
  color: #b2b2b2;
}
.header--four .header__top-right .bv_mainselect .bv_atual.bv_background .arrow {
  border: solid #b2b2b2;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  pointer-events: none;
  margin-left: 8px;
}
.header--four .header__top-right .bv_mainselect .bv_ul_inner {
  width: 150px !important;
  text-transform: capitalize;
  z-index: 999;
}
.header--four .header__top-right .bv_mainselect .bv_ul_inner li:hover {
  background-color: #01b0df;
  color: #fff;
}
.header--four .header__top-right .bv_mainselect .bv_ul_inner li.bv_disabled {
  background-color: #84d187 !important;
  color: #fff;
}
.header--four .header__navigation-menu-link.active a {
  color: #01b0df;
}
.header--four .header__navigation-menu-link a {
  color: #808080;
}
@media (max-width: 575px) {
  .header--four .header__telephone-number {
    display: none;
  }
}
.header--four .header__telephone-number span {
  color: #1a1a1a;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.header--four .header__telephone-number span svg {
  color: #1a1a1a;
}
.header--four .header__bottom {
  background-color: #f2f2f2;
}
.header--four .header__bottom-content {
  background-color: #f2f2f2;
}
@media (max-width: 575px) {
  .header--four .header__bottom-content {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.header--four .header__category-content .bv_atual.bv_background {
  background-color: #01b0df;
  width: 150px !important;
  padding-left: 0px;
}
.header--four .header__cart-item-content {
  padding-left: 5px;
}
@media (max-width: 575px) {
  .header--four .header__cart-item-content-info {
    display: none;
  }
}
.header--four .header__cart-item button {
  padding-right: 5px;
}
.header--five .header__top {
  background-color: #edf2ee;
}
.header--five .header__top-left p {
  color: #2b572e;
}
.header--five
  .header__top-right
  .header__dropdown
  .bv_mainselect
  .bv_atual.bv_background {
  color: #2b572e;
}
.header--five
  .header__top-right
  .header__dropdown
  .bv_mainselect
  .bv_atual.bv_background
  .arrow {
  color: #2b572e;
}
.header--five .header__center-content .header__navigation-menu {
  width: 48%;
}
@media (max-width: 1200px) {
  .header--five .header__center-content .header__navigation-menu {
    width: 65%;
  }
}
@media (max-width: 991px) {
  .header--five .header__center-content .header__navigation-menu {
    display: none;
  }
}
.header--five .header__center-content .header__cart {
  width: 33%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (max-width: 1200px) {
  .header--five .header__center-content .header__cart {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}
@media (max-width: 767px) {
  .header--five .header__center-content .header__cart {
    width: 100%;
  }
}
.header--five .header__center-content .header__brand {
  width: 30%;
}
@media (max-width: 1200px) {
  .header--five .header__center-content .header__brand {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .header--five .header__center-content .header__brand {
    width: auto;
  }
}
.header--five .header__center-content .header__cart {
  margin-left: 0px;
}
.header--five .header__center-content .header__navigation-menu-link.active a {
  color: #1a1a1a;
}
.header--five .header__center-content .header__navigation-menu-link a {
  color: #808080;
}
@media (max-width: 1200px) {
  .header--five .header__center-content .header__telephone-number {
    display: none;
  }
}
.header--five .header__center-content .header__telephone-number span {
  color: #1a1a1a;
  font-weight: 500;
}
.header--five .header__sidebar-btn {
  padding-right: 12px;
}
@media (max-width: 767px) {
  .header--five .header__cart-single .heart-icon,
  .header--five .header__cart-single .user-icon {
    display: none;
  }
}
@media (max-width: 767px) {
  .header--five .header__cart-single .icon {
    margin-right: 15px;
  }
}
.header--five .header__navigation-menu-link {
  margin-right: 15px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .header--two .header__navigation-menu-link {
    margin-right: 25px;
  }

  .header--two .header__activity-icons button {
    margin-right: 15px;
  }
}
@media (min-width: 992px) {
  .header__sidebar-btn {
    display: none;
  }
}
@media (max-width: 991px) {
  .header__sidebar-btn {
    padding-right: 12px;
  }
  .header__input-form {
    display: none;
  }

  .header--one {
    -webkit-box-shadow: 0px 1px 0px 0px #e5e5e5;
    box-shadow: 0px 1px 0px 0px #e5e5e5;
  }
  .header--one .header__top-left {
    display: none;
  }
  .header--one .header__top-right {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
  }
  .header--one .header__top-right .header__dropdown {
    border: 0px;
  }
  .header--one .header__top-right .header__dropdown .bv_mainselect {
    border-right: 1px solid #b2b2b2;
  }
  .header--one
    .header__top-right
    .header__dropdown
    .bv_mainselect:nth-last-child(1) {
    border: 0px;
    margin-left: 20px;
  }
  .header--one .header__bottom {
    display: none;
  }
  .header--one .header__cart-item-content-info {
    display: none;
  }
  .header--one .header__cart-item button {
    border: 0px;
    padding: 0px;
  }

  .header--two .header__navigation-menu {
    display: none;
  }

  .header--two .header__contact-info-number {
    display: none;
  }

  .header--two .header__brand {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .header--two .header__activity-icons button {
    margin-right: 8px;
  }

  .header__activity-icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .header__activity-icons .user-icon {
    display: none;
  }
}
/** 2.3 Mobile Menu Layout */
.header__sidebar {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: -340px;
  width: 150px;
  height: 100%;
  padding: 24px 0px;
  z-index: 9999;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

@media (min-width: 992px) {
  .rt {
    display: none;
  }
  .header__sidebar {
    display: none;
  }
  .header__sidebar.active {
    left: 0px;
    width: 308px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
}

@media (max-width: 380px) {
  .cards-sm h5 {
    margin-top: 12px;
    color: #1a1a1a;
  }
  .shipping-container {
    display: none;
  }
  .header__sidebar.active {
    width: 270px;
  }
}
.header__mobile-sidebar {
  height: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  overflow-y: auto;
}
.header__mobile-input {
  position: relative;
  width: 260px;
  height: 44px;
  margin: auto;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  overflow: hidden;
  margin: 0px 24px;
}
@media (max-width: 380px) {
  .header__mobile-input {
    width: 230px;
  }
}
.header__mobile-input input {
  width: 80%;
  height: inherit;
  border: 0px;
  font-size: 13px;
  line-height: 21px;
  text-transform: capitalize;
  color: #808080;
  padding-left: 16px;
}
.header__mobile-input input::-webkit-input-placeholder {
  font-size: inherit;
  line-height: inherit;
}
.header__mobile-input input:-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
}
.header__mobile-input input::-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
}
.header__mobile-input input::placeholder {
  font-size: inherit;
  line-height: inherit;
}
.header__mobile-input .search-btn {
  position: absolute;
  top: 50%;
  right: 0px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 44px;
  height: 44px;
  border-radius: 0px 6px 6px 0px;
  background-color: #01b0df;
  color: #fff;
}
.header__mobile-menu {
  margin-top: 12px;
  overflow: hidden;
}
.header__mobile-menu-item {
  padding: 16px 0px;
  border-bottom: 1px solid #e5e5e5;
}
.header__mobile-menu-item:nth-last-child(1) {
  border-bottom: 0px;
}
.header__mobile-menu-item-link {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #1a1a1a;
  margin-left: 24px;
  width: 100%;
  position: relative;
}
.header__mobile-menu-item-link.active {
  color: #01b0df;
}
.header__mobile-menu-item-link:hover {
  color: inherit;
}
.header__mobile-menu-item-link .drop-icon {
  position: absolute;
  right: 48px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #808080;
}
.header__mobile-menu-item-link .drop-icon svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.header__mobile-dropdown-menu {
  height: 0px;
  overflow: hidden;
  pointer-events: none;
  margin-left: 24px;
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.header__mobile-dropdown-menu-link {
  margin: 12px 0px;
}
.header__mobile-dropdown-menu-link:nth-last-child(1) {
  margin-bottom: 0px;
}
.header__mobile-dropdown-menu-link.active a {
  color: #01b0df;
}
.header__mobile-dropdown-menu-link a {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  width: 100%;
  text-transform: capitalize;
}
.header__mobile-dropdown-menu-link a:hover {
  color: #01b0df;
}
.header__mobile-action,
.header__mobile-user {
  padding-left: 24px;
}
@media (max-width: 380px) {
  .header__mobile-action,
  .header__mobile-user {
    padding-left: 15px;
  }
}
.header__mobile-action a {
  margin-right: 16px;
}
.header__mobile-action a:nth-last-child(1) {
  margin-right: 0px;
}
.header__mobile-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header__mobile-user--img {
  margin-right: 16px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}
.header__mobile-user--img img {
  width: inherit;
  height: inherit;
}
.header__mobile-user--info h2 {
  color: #1a1a1a;
}
.header__mobile-user--info p {
  color: #808080;
}
.header__cross {
  position: absolute;
  top: 30px;
  right: -40px;
  color: #fff;
}
.header__mobile-menu-item.active .header__mobile-menu-item-link .drop-icon {
  color: #1a1a1a;
}
.header__mobile-menu-item.active .header__mobile-menu-item-link .drop-icon svg {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.header__mobile-menu-item.active .header__mobile-dropdown-menu {
  height: auto;
  overflow: visible;
  pointer-events: all;
}

body.overlay {
  position: relative;
}
body.overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.8;
  z-index: 999;
}

.section {
  position: relative;
}
.section__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 32px;
}
.section__head .title {
  display: block;
  font-size: 16px;
  line-height: 19.2px;
  color: #01b0df;
  font-weight: 400;
  font-family: "Segoe Script", sans-serif;
  text-transform: uppercase;
}
.section__head a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.section__head a:hover {
  color: #2c742f;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.section__head a span {
  margin-left: 12px;
}
.section__head p {
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin-top: 12px;
  width: 640px;
  margin: auto;
  text-align: center;
}
@media (max-width: 767px) {
  .section__head p {
    max-width: 100%;
    font-size: 14px;
  }
}
.section__head--one {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .section__head--one {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .section__head--one .section--title-one {
    margin-bottom: 0px;
  }
}
.section__head--two,
.section__head--four {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.section__head--two h2,
.section__head--four h2 {
  text-align: center;
}
.section__head--three {
  margin-bottom: 70px;
}
.section__head--three-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.section__head--three-center .title-bar {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.section__head--three-left .title-bar {
  left: 20px;
}
.section__head--three .section-title--two {
  position: relative;
}
.section__head--three .title-bar {
  position: absolute;
  width: 40px;
  height: 4px;
  background-color: #01b0df;
  bottom: -16px;
}
.section__head--three .title-bar::after,
.section__head--three .title-bar::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 4px;
  background-color: #84d187;
}
.section__head--three .title-bar::after {
  right: -15px;
}
.section__head--three .title-bar::before {
  left: -15px;
}
.section__head--four {
  margin-bottom: 40px;
}
.section__title {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #01b0df;
  margin-bottom: 8px;
}
.section--title-one,
.section--title-two {
  color: #1a1a1a;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .section--title-one,
  .section--title-two {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .section__head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .section__head--three {
    margin-bottom: 30px;
  }
  .section__head--three .section--title-two .title-bar {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .section__head--three .arrows,
  .section__head--three a {
    margin-top: 35px;
  }
  .section--title-one,
  .section--ttitle-two,
  .section--title-three,
  .section--title-four,
  .section--title-five {
    font-size: 28px;
    line-height: 38px;
  }
  .section--xxl,
  .section--xl,
  .section--lg,
  .section--md {
    padding: 40px 0px;
  }
}
/** 2.4 Banner Layout */
.banner {
  position: relative;
}
.banner__wrapper-text {
  z-index: 1;
}
@media (min-width: 575px) {
  .banner--03 .swiper-pagination,
  .banner--04 .swiper-pagination {
    bottom: 34px;
  }
}
.banner--03 .swiper-pagination-bullet,
.banner--04 .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.banner--03 .swiper-pagination-bullet-active,
.banner--04 .swiper-pagination-bullet-active {
  width: 16px;
  border-radius: 50px;
  -webkit-transition: all 0.6s linear ease-in-out;
  transition: all 0.6s linear ease-in-out;
}
.banner--04 .container,
.banner--05 .container {
  position: relative;
}
.banner--04 .container .arrows .swiper-button--prev,
.banner--04 .container .arrows .swiper-button--next,
.banner--05 .container .arrows .swiper-button--prev,
.banner--05 .container .arrows .swiper-button--next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}
@media (max-width: 1200px) {
  .banner--04 .container .arrows .swiper-button--prev,
  .banner--04 .container .arrows .swiper-button--next,
  .banner--05 .container .arrows .swiper-button--prev,
  .banner--05 .container .arrows .swiper-button--next {
    top: auto;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    bottom: 30px;
  }
}
@media (max-width: 767px) {
  .banner--04 .container .arrows .swiper-button--prev,
  .banner--04 .container .arrows .swiper-button--next,
  .banner--05 .container .arrows .swiper-button--prev,
  .banner--05 .container .arrows .swiper-button--next {
    bottom: -15px;
  }
}
.banner--04 .container .arrows .swiper-button--prev,
.banner--05 .container .arrows .swiper-button--prev {
  left: -30px;
}
@media (max-width: 1200px) {
  .banner--04 .container .arrows .swiper-button--prev,
  .banner--05 .container .arrows .swiper-button--prev {
    left: auto;
    right: 60px;
  }
}
.banner--04 .container .arrows .swiper-button--next,
.banner--05 .container .arrows .swiper-button--next {
  right: -30px;
}
@media (max-width: 1200px) {
  .banner--04 .container .arrows .swiper-button--next,
  .banner--05 .container .arrows .swiper-button--next {
    right: 0px;
  }
}
@media (max-width: 767px) {
  .banner--04 .container .arrows .swiper-button--next,
  .banner--05 .container .arrows .swiper-button--next {
    right: 15px;
  }
}
.banner--01 {
  margin: 24px 0px;
}
@media (max-width: 767px) {
  .banner--01 {
    margin-bottom: 12px;
  }
}
.banner--01 .banner__wrapper-img {
  position: relative;
  z-index: 1;
  width: 100%;
  border-radius: 0px !important;
  overflow: hidden;
}
.banner--01 .banner__wrapper-img img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.banner__wrapper-img:after, .overlayt:after{
  content: '\A';
    position: absolute;
    width: 100%; height:100%;
    top:0; left:0;
    background:rgba(0,0,0,0.6);
    opacity: .6;
}

.banner--01 .banner__wrapper-text {
  position: absolute;
}
.banner--01 .banner__wrapper--img-02,
.banner--01 .banner__wrapper--img-03 {
  height: 288px;
}
.banner--01 .banner__wrapper--img-02 .banner__wrapper-text .button,
.banner--01 .banner__wrapper--img-03 .banner__wrapper-text .button {
  background-color: #000;
  color: #fff !important;
  text-transform: capitalize;
  font-weight: 600;
  padding: 10px;
}
.banner--01 .banner__wrapper--img-01 {
  height: 600px;
}
.banner--01 .banner__wrapper--img-01::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90.02deg,
    rgb(0 0 0 / 32%) 0.03%,
    rgba(0, 0, 0, 0) 91.31%
  );
}
.banner--01 .banner__wrapper--img-01 .banner__wrapper-text {
  top: 165px;
  left: 65px;
}
.banner--01 .banner__wrapper--img-01 .banner__wrapper-text h2 {
  width: 70%;
  color: #fff;
}
.banner--01 .banner__wrapper--img-01 .banner__wrapper-text .sale-off {
  margin: 30px 0px;
  padding-left: 12px;
  border-left: 2px solid #84d187;
}
.banner--01 .banner__wrapper--img-01 .banner__wrapper-text .sale-off h5 {
  color: #fff;
  margin-bottom: 12px;
}
.banner--01 .banner__wrapper--img-01 .banner__wrapper-text .sale-off h5 span {
  text-transform: uppercase;
  font-weight: 600;
  padding: 4px 12px;
  background-color: #ff8a00;
  border-radius: 5px;
}
.banner--01 .banner__wrapper--img-01 .banner__wrapper-text .sale-off p {
  color: #cccccc;
  font-size: 14px;
  line-height: 21px;
}
.banner--01 .banner__wrapper--img-01 .banner__wrapper-text .button {
  background-color: #fff;
  color: #01b0df !important;
}
.banner--01 .banner__wrapper--img-01 .banner__wrapper-text .button:hover {
  background-color: #e5e5e5;
}
.banner--01 .banner__wrapper--img-02 {
  margin-bottom: 24px;
}
.banner--01 .banner__wrapper--img-02 .banner__wrapper-text {
  top: 32px;
  left: 32px;
}
.banner--01 .banner__wrapper--img-02 .banner__wrapper-text h5 {
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 8px;
}
.banner--01 .banner__wrapper--img-02 .banner__wrapper-text h2 {
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 12px;
}
.banner--01 .banner__wrapper--img-02 .banner__wrapper-text p {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  margin-bottom: 24px;
}
.banner--01 .banner__wrapper--img-03 .banner__wrapper-text {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}
.banner--01 .banner__wrapper--img-03 .banner__wrapper-text h5,
.banner--01 .banner__wrapper--img-03 .banner__wrapper-text h2 {
  color: #fff;
}
.banner--01 .banner__wrapper--img-03 .banner__wrapper-text h5 {
  text-transform: uppercase;
  margin-bottom: 12px;
}
.banner--01 .banner__wrapper--img-03 .banner__wrapper-text h2 {
  max-width: 72%;
  margin: auto;
  margin-bottom: 32px;
}
.banner--02 {
  margin-top: 24px;
}
.banner--02 .banner__wrapper-img {
  position: relative;
  width: 100%;
  height: 560px;
  overflow: hidden;
  z-index: 2;
}
.banner--02 .banner__wrapper-img::after {
  position: absolute;
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.3)),
    color-stop(56.91%, rgba(0, 0, 0, 0))
  );
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0) 56.91%
  );
}
.banner--02 .banner__wrapper-img img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.banner--02 .banner__wrapper-text {
  position: absolute;
  top: 50%;
  left: 48px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.banner--02 .banner__wrapper-text h2 {
  color: #fff;
  max-width: 400px;
  text-transform: capitalize;
  opacity: 0.3;
}
.banner--02 .banner__wrapper-text .sale-off {
  border-left: 1px solid transparent;
  padding-left: 12px;
  margin: 20px 0px 33px;
}
.banner--02 .banner__wrapper-text .sale-off h5 {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
  max-width: 135px;
}
.banner--02 .banner__wrapper-text .sale-off h5 span {
  color: rgba(255, 255, 255, 0.6);
}
.banner--02 .banner__wrapper-text a.button {
  background-color: #2c742f;
}
.banner--02 .swiper-pagination {
  position: absolute;
  left: 48px;
  bottom: 32px;
  text-align: left;
}
.banner--02 .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #fff;
}
.banner--02
  .swiper-container-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 48px;
  -webkit-transform: unset;
  transform: unset;
}
.banner--02 .swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.banner--02
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-prev,
.banner--02
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-next,
.banner--02
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-next-next {
  left: 0 !important;
  -webkit-transform: unset !important;
  transform: unset !important;
}
.banner--02 .swiper-slide-active .banner__wrapper-text h2,
.banner--02 .swiper-slide-active .banner__wrapper-text h5 span,
.banner--02 .swiper-slide-active .banner__wrapper-text a.button {
  -webkit-transition: all 0.6s ease-in;
  transition: all 0.6s ease-in;
}
.banner--02 .swiper-slide-active .banner__wrapper-text h2 {
  opacity: 1;
}
.banner--02 .swiper-slide-active .banner__wrapper-text h5 span {
  color: #fff;
}
.banner--02 .swiper-slide-active .banner__wrapper-text .sale-off {
  border-color: #01b0df;
  -webkit-transition: all 0.9s linear;
  transition: all 0.9s linear;
}
.banner--02 .swiper-slide-active .banner__wrapper-text a.button {
  background-color: #01b0df;
}
@media (max-width: 991px) {
  .banner--03 .swiper-container {
    width: 95%;
    margin: auto;
    margin-top: 24px;
    background-color: #edf2ee;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}
.banner--03__content {
  width: 1520px;
  height: 740px;
  border-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  margin: auto;
  background-color: #edf2ee;
  padding-left: 85px;
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}
@media (max-width: 1680px) {
  .banner--03__content {
    width: 95%;
  }
}
@media (max-width: 1200px) {
  .banner--03__content {
    padding-left: 18px;
  }
}
@media (max-width: 991px) {
  .banner--03__content {
    height: auto;
    padding-bottom: 115px;
  }
}
@media (max-width: 767px) {
  .banner--03__content {
    padding-left: 0px;
  }
}
@media (max-width: 767px) {
  .banner--03__text-content {
    text-align: center;
  }
}
.banner--03__text-content .title {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #01b0df;
  -webkit-transform: translateY(-60px);
  transform: translateY(-60px);
  opacity: 0.3;
}
@media (max-width: 991px) {
  .banner--03__text-content .title {
    -webkit-transform: translate(-150px, 0px);
    transform: translate(-150px, 0px);
  }
}
@media (max-width: 767px) {
  .banner--03__text-content .title {
    margin-bottom: 10px;
  }
}
.banner--03__text-content h2 {
  color: #1a1a1a;
  margin-bottom: 28px;
  opacity: 0.3;
}
@media (max-width: 1200px) {
  .banner--03__text-content h2 {
    font-size: 62px;
    line-height: 76px;
  }
}
@media (max-width: 991px) {
  .banner--03__text-content h2 {
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 14px;
  }
}
@media (max-width: 767px) {
  .banner--03__text-content h2 {
    font-size: 32px;
    line-height: 42px;
  }
}
.banner--03__text-content h6 {
  font-weight: 400;
  color: #1a1a1a;
  text-transform: capitalize;
  margin-bottom: 12px;
}
.banner--03__text-content h6 span {
  font-weight: 600;
  color: #ff8a00;
  text-transform: uppercase;
  opacity: 0;
}
@media (max-width: 991px) {
  .banner--03__text-content h6 {
    font-size: 28px;
    line-height: 38px;
  }
}
@media (max-width: 767px) {
  .banner--03__text-content h6 {
    font-size: 24px;
    line-height: 28px;
  }
}
.banner--03__text-content p {
  color: #808080;
  text-transform: capitalize;
}
.banner--03__text-content a.button {
  margin-top: 32px;
  background-color: #84d187;
}
.banner--03__img-wrapper {
  opacity: 0.3;
}
.banner--03__img-wrapper img {
  max-width: 100%;
}
.banner--03 .swiper-slide {
  opacity: 0 !important;
}
.banner--03 .swiper-slide-active {
  opacity: 1 !important;
}
.banner--03 .swiper-slide-active .banner--03__text-content h5,
.banner--03 .swiper-slide-active .banner--03__text-content h2,
.banner--03 .swiper-slide-active .banner--03__text-content h6 span {
  opacity: 1;
  -webkit-transition: all 0.9s linear;
  transition: all 0.9s linear;
}
.banner--03 .swiper-slide-active .banner--03__text-content h5 {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
@media (max-width: 991px) {
  .banner--03 .swiper-slide-active .banner--03__text-content h5 {
    -webkit-transform: translate(0px);
    transform: translate(0px);
  }
}
.banner--03 .swiper-slide-active .banner--03__text-content a.button {
  background-color: #01b0df;
  -webkit-transform: all 0.9s linear;
  transform: all 0.9s linear;
}
.banner--03 .swiper-slide-active .banner--03__img-wrapper {
  opacity: 1;
  -webkit-transition: all 0.9s linear;
  transition: all 0.9s linear;
}
@media (max-width: 767px) {
  .banner--04 .swiper-pagination {
    bottom: 0px;
  }
}
.banner--04__content {
  height: 700px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 991px) {
  .banner--04__content {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .banner--04__content {
    padding: 30px 0px 90px 0px;
  }
}
.banner--04__img-wrapper {
  opacity: 0.3;
  position: relative;
}
.banner--04__img-wrapper .off-sale {
  position: absolute;
  top: 15px;
  right: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ff8a00;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .banner--04__img-wrapper .off-sale {
    top: 0px;
    right: 0px;
  }
}
@media (max-width: 767px) {
  .banner--04__img-wrapper .off-sale {
    width: 60px;
    height: 60px;
    right: 0px;
  }
}
.banner--04__img-wrapper .off-sale p {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.02em;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
}
.banner--04__img-wrapper .off-sale p span {
  font-size: 32px;
  line-height: 38.4px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .banner--04__img-wrapper .off-sale p span {
    font-size: 18px;
    line-height: 18px;
  }
}
.banner--04__text-content h5 {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #01b0df;
  font-family: "Segoe Script", sans-serif;
  -webkit-transform: translateX(90px);
  transform: translateX(90px);
  opacity: 0.3;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.banner--04__text-content h2 {
  font-size: 56px;
  line-height: 67.2px;
  color: #002603;
  margin-bottom: 20px;
  opacity: 0.3;
}
@media (max-width: 767px) {
  .banner--04__text-content h2 {
    font-size: 32px;
    line-height: 42px;
  }
}
.banner--04__text-content p {
  font-weight: 400;
  color: #1a1a1a;
  font-size: 18px;
}
.banner--04__text-content a.button {
  margin-top: 32px;
  background-color: #84d187;
}
.banner--04 .swiper-slide {
  opacity: 0 !important;
}
.banner--04 .swiper-slide-active {
  opacity: 1 !important;
}
.banner--04 .swiper-slide-active .banner--04__img-wrapper {
  opacity: 1;
}
.banner--04 .swiper-slide-active .banner--04__text-content h5,
.banner--04 .swiper-slide-active .banner--04__text-content h2,
.banner--04 .swiper-slide-active .banner--04__text-content p {
  -webkit-transition: all 0.9s linear;
  transition: all 0.9s linear;
}
.banner--04 .swiper-slide-active .banner--04__text-content h5,
.banner--04 .swiper-slide-active .banner--04__text-content h2 {
  opacity: 1;
}
.banner--04 .swiper-slide-active .banner--04__text-content h5 {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}
.banner--04 .swiper-slide-active .banner--04__text-content p {
  color: #618062;
}
.banner--04 .swiper-slide-active .banner--04__text-content a.button {
  background-color: #01b0df;
}
.banner--05 .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}
.banner--05 .swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
@media (max-width: 991px) {
  .banner--05 .swiper-container {
    width: 100%;
  }
}
.banner--05 .container .arrows .swiper-button--next,
.banner--05 .container .arrows .swiper-button--prev {
  bottom: 30px;
}
.banner--05__overlay-img {
  position: absolute;
  color: #dae5da;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 991px) {
  .banner--05__overlay-img {
    display: none;
  }
}
.banner--05__overlay-img.one {
  top: 0;
  left: 85px;
}
.banner--05__overlay-img.two {
  bottom: 24px;
  left: 302px;
}
.banner--05__overlay-img.three {
  top: 0;
  right: 0;
}
.banner--05__overlay-img.four {
  right: 168px;
  bottom: -60px;
}
.banner--05__overlay-img.five {
  top: 70px;
  left: 165px;
}
.banner--05__overlay-img.six {
  left: 140px;
  bottom: 35px;
}
.banner--05__overlay-img.seven {
  top: 0;
  right: 768px;
}
.banner--05__overlay-img.eight {
  top: 48px;
  right: 300px;
}
.banner--05__overlay-img.nine {
  right: 0;
  bottom: 0;
}
.banner--05 .banner--03 .container .arrows .swiper-button--next {
  right: 30px;
}
.banner--05 .banner--03 .container .arrows .swiper-button--prev {
  right: 90px;
}
.banner--05 .banner--03 .swiper-container {
  width: 100%;
}
.banner--05 .banner--03__content {
  background-color: transparent;
  height: 580px;
  padding-left: 0px;
  width: 100%;
  z-index: 2;
}
@media (max-width: 991px) {
  .banner--05 .banner--03__content {
    height: 100%;
  }
}

.banner-sale__img-wrapper {
  position: relative;
  width: 100%;
  height: 358px;
  border-radius: 10px;
  overflow: hidden;
}
.banner-sale__img-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 991px) {
  .banner-sale__img-wrapper::after {
    background: rgba(0, 38, 3, 0.8);
  }
}
.banner-sale__img-wrapper img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.banner-sale__text-content {
  position: absolute;
  top: 50%;
  left: 60%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}
.banner-sale__text-content h5 {
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  margin-bottom: 12px;
}
.banner-sale__text-content h2 {
  font-size: 56px;
  line-height: 67.2px;
  color: #ff8a00;
  font-weight: 600;
  margin-bottom: 16px;
}
.banner-sale__text-content h2 span {
  color: #fff;
  font-weight: 400;
}
.banner-sale__text-content p {
  color: #fff;
  opacity: 70%;
  max-width: 75%;
  margin-bottom: 28px;
}
.banner-sale__text-content a.button:hover {
  background-color: #fff;
  color: #01b0df;
}
.banner-sale--two__img-wrapper {
  position: relative;
  width: 100%;
  height: 360px;
  border-radius: 10px;
  overflow: hidden;
}
.banner-sale--two__img-wrapper img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.banner-sale--two__text-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 40px;
}
@media (max-width: 767px) {
  .banner-sale--two__text-content {
    left: 15px;
  }
}
.banner-sale--two__text-content .title {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #fff;
  text-transform: uppercase;
}
.banner-sale--two__text-content h5 {
  color: #fff;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .banner-sale--two__text-content h5 {
    font-size: 28px;
    line-height: 38px;
  }
}
.banner-sale--two__text-content p {
  color: rgba(255, 255, 255, 0.8);
}
.banner-sale--two__text-content p span {
  color: #fff;
  margin-left: 8px;
  padding: 4px 8px;
  border-radius: 5px;
  background-color: #ff8a00;
}
.banner-sale--two__text-content a.button {
  margin-top: 24px;
}

@media (max-width: 1200px) {
  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .banner--01 .banner__wrapper--img-02 .banner__wrapper-text {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .banner--01 .banner__wrapper--img-03 .banner__wrapper-text h2,
  .banner--01 .banner__wrapper--img-03 .banner__wrapper-text .h2 {
    max-width: 95%;
  }

  .banner-sale__text-content {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .banner-sale__text-content p {
    max-width: 100%;
  }
}
@media (max-width: 1199px) {
  .banner--01 .banner__wrapper--img-03 .banner__wrapper-text h5,
  .banner--01 .banner__wrapper--img-03 .banner__wrapper-text .h5,
  .banner--01 .banner__wrapper--img-02 .banner__wrapper-text h5,
  .banner--01 .banner__wrapper--img-02 .banner__wrapper-text .h5,
  .banner--01 .banner__wrapper--img-02 .banner__wrapper-text p {
    margin-bottom: 0px;
  }

  .banner--01 .banner__wrapper--img-03 .banner__wrapper-text h2,
  .banner--01 .banner__wrapper--img-03 .banner__wrapper-text .h2,
  .banner--01 .banner__wrapper--img-03 .banner__wrapper-text .h2 {
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
  }
}
@media (max-width: 991px) {
  .banner__wrapper-img-sm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 2px;
  }
  .banner__wrapper-img-sm .banner__wrapper-img {
    margin-right: 12px;
    margin-bottom: 0px;
  }
  .banner__wrapper-img-sm .banner__wrapper-img:nth-last-child(1) {
    margin-right: 0px;
  }
}
@media (max-width: 767px) {
  .banner-slider--one .banner__wrapper-img {
    overflow: hidden;
  }
  .banner-slider--one .banner__wrapper--img-01 {
    height: 288px;
  }
  .banner-slider--one
    .banner__wrapper--img-01
    .banner__wrapper-text
    .button
    span
    svg,
  .banner-slider--one
    .banner__wrapper--img-02
    .banner__wrapper-text
    .button
    span
    svg,
  .banner-slider--one
    .banner__wrapper--img-03
    .banner__wrapper-text
    .button
    span
    svg {
    opacity: 0.3;
    -webkit-transform: translateY(-3px) rotate(-45deg);
    transform: translateY(-3px) rotate(-45deg);
  }
  .banner-slider--one .banner__wrapper--img-01 .banner__wrapper-text h2,
  .banner-slider--one
    .banner__wrapper--img-01
    .banner__wrapper-text
    .sale-off
    span {
    opacity: 0;
  }
  .banner-slider--one .banner__wrapper--img-01 .banner__wrapper-text h2 {
    -webkit-transform: translateX(-350px);
    transform: translateX(-350px);
    pointer-events: none;
    -webkit-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
  }
  .banner-slider--one .banner__wrapper--img-02 .banner__wrapper-text h5,
  .banner-slider--one .banner__wrapper--img-02 .banner__wrapper-text h2,
  .banner-slider--one .banner__wrapper--img-03 .banner__wrapper-text h5,
  .banner-slider--one .banner__wrapper--img-03 .banner__wrapper-text h2 {
    opacity: 0.6;
  }
  .banner-slider--one .banner__wrapper--img-02 .banner__wrapper-text h5,
  .banner-slider--one .banner__wrapper--img-03 .banner__wrapper-text h5 {
    -webkit-transform: translateY(-250px);
    transform: translateY(-250px);
  }
  .banner-slider--one .banner__wrapper--img-02 .banner__wrapper-text h2,
  .banner-slider--one .banner__wrapper--img-03 .banner__wrapper-text h2 {
    -webkit-transform: translateX(-350px);
    transform: translateX(-350px);
  }
  .banner-slider--one .swiper-slide {
    opacity: 0 !important;
  }
  .banner-slider--one .swiper-slide-active {
    opacity: 1 !important;
    -webkit-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
  }
  .banner-slider--one
    .swiper-slide-active
    .banner__wrapper--img-01
    .banner__wrapper-text
    .button
    span
    svg,
  .banner-slider--one
    .swiper-slide-active
    .banner__wrapper--img-02
    .banner__wrapper-text
    .button
    span
    svg,
  .banner-slider--one
    .swiper-slide-active
    .banner__wrapper--img-03
    .banner__wrapper-text
    .button
    span
    svg {
    opacity: 1;
    -webkit-transform: translateY(0px) rotate(0deg);
    transform: translateY(0px) rotate(0deg);
    -webkit-transition: all 0.6s linear;
    transition: all 0.6s linear;
  }
  .banner-slider--one
    .swiper-slide-active
    .banner__wrapper--img-01
    .banner__wrapper-text
    h2 {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    -webkit-transition: all 0.9s linear;
    transition: all 0.9s linear;
    pointer-events: all;
  }
  .banner-slider--one
    .swiper-slide-active
    .banner__wrapper--img-01
    .banner__wrapper-text
    .sale-off
    span {
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }
  .banner-slider--one
    .swiper-slide-active
    .banner__wrapper--img-02
    .banner__wrapper-text
    h5,
  .banner-slider--one
    .swiper-slide-active
    .banner__wrapper--img-02
    .banner__wrapper-text
    h2,
  .banner-slider--one
    .swiper-slide-active
    .banner__wrapper--img-03
    .banner__wrapper-text
    h5,
  .banner-slider--one
    .swiper-slide-active
    .banner__wrapper--img-03
    .banner__wrapper-text
    h2 {
    opacity: 1;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  .banner-slider--one
    .swiper-slide-active
    .banner__wrapper--img-02
    .banner__wrapper-text
    h5,
  .banner-slider--one
    .swiper-slide-active
    .banner__wrapper--img-03
    .banner__wrapper-text
    h5 {
    -webkit-transition: all 0.6s linear;
    transition: all 0.6s linear;
  }
  .banner-slider--one
    .swiper-slide-active
    .banner__wrapper--img-02
    .banner__wrapper-text
    h2,
  .banner-slider--one
    .swiper-slide-active
    .banner__wrapper--img-03
    .banner__wrapper-text
    h2 {
    -webkit-transition: all 0.9s ease-in;
    transition: all 0.9s ease-in;
  }

  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text,
  .banner--01 .banner__wrapper--img-02 .banner__wrapper-text,
  .banner--01 .banner__wrapper--img-03 .banner__wrapper-text {
    left: 15px;
  }

  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text h2,
  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text .h2 {
    width: 100%;
  }

  /* .banner__wrapper-img-sm { */
  /* -webkit-box-orient: vertical; */
  /* -webkit-box-direction: normal; */
  /* -ms-flex-direction: column; */
  /* flex-direction: column;  */
  /* } */
  .banner__wrapper-img-sm .banner__wrapper-img {
    /* margin-right: 0px; */
    margin-bottom: 12px;
  }

  .banner--01 .banner__wrapper--img-03 .banner__wrapper-text {
    left: 32px;
    text-align: left;
  }
  .banner--01 .banner__wrapper--img-03 .banner__wrapper-text h2 {
    margin-left: 0px;
  }

  .banner__wrapper-img-sm .banner__wrapper-img {
    margin-bottom: 12px;
  }

  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text h2,
  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text .h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text .sale-off {
    margin: 15px 0px;
  }

  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text .sale-off h5,
  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text .sale-off .h5 {
    font-size: 14px;
    line-height: 21px;
  }

  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text .button,
  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text .button:hover {
    background-color: transparent;
    padding: 0px;
  }

  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text .sale-off h5,
  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text .sale-off .h5 {
    margin-bottom: 5px;
  }

  .banner-sale__text-content {
    width: 95%;
  }

  .banner--02 .swiper-pagination,
  .banner--02 .banner--02__wrapper-text {
    left: 12px;
  }
  .banner--02 .swiper-pagination {
    bottom: 15px;
  }
  .banner--02 .banner__wrapper-img {
    height: 350px;
  }
  .banner--02 .banner__wrapper-text {
    left: 12px;
  }
  .banner--02 .banner__wrapper-text h2 {
    font-size: 32px;
    line-height: 42px;
    max-width: 100%;
  }
  .banner--02 .banner__wrapper-text .sale-off h5 {
    font-size: 18px;
    line-height: 28px;
  }
}
/** 2.5 Sales Banner Layout */
.cyclone .cards-ss--lg {
  margin-bottom: 24px;
}

@media (max-width: 991px) {
  .cyclone__content .cards-ss {
    margin-bottom: 24px;
  }
  .cyclone .cards-ss--lg {
    margin-bottom: 24px;
  }
}
/** 2.6 Products Layout */

.popular-categories__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 24px;
}

.popular-products {
  padding-bottom: 0px;
}
.popular-products__wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.deals-products__wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas: "lg-card lg-card sm-card-one sm-card-two sm-card-three" "lg-card lg-card sm-card-four sm-card-five sm-card-six" "sm-card-seven sm-card-eight sm-card-nine sm-card-ten sm-card-eleven";
}
.deals-products__wrapper-item--one {
  grid-area: lg-card;
}
.deals-products__wrapper-item--two {
  grid-area: sm-card-one;
}
.deals-products__wrapper-item--three {
  grid-area: sm-card-two;
}
.deals-products__wrapper-item--four {
  grid-area: sm-card-three;
}
.deals-products__wrapper-item--five {
  grid-area: sm-card-four;
}
.deals-products__wrapper-item--six {
  grid-area: sm-card-five;
}
.deals-products__wrapper-item--seven {
  grid-area: sm-card-six;
}
.deals-products__wrapper-item--eight {
  grid-area: sm-card-seven;
}
.deals-products__wrapper-item--nine {
  grid-area: sm-card-eight;
}
.deals-products__wrapper-item--ten {
  grid-area: sm-card-nine;
}
.deals-products__wrapper-item--eleven {
  grid-area: sm-card-ten;
}
.deals-products__wrapper-item--twelve {
  grid-area: sm-card-eleven;
}

.products__content {
  padding: 20px 0px;
  border-bottom: 1px solid #e5e5e5;
}
.products__content:nth-child(1) {
  padding-top: 0px;
}
.products__content:nth-last-child(1) {
  border: 0px;
}
.products__content-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 12px;
}
@media (max-width: 575px) {
  .products__content-title h2 {
    font-size: 20px;
    line-height: 30px;
  }
}
.products__content-title span {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 0px 8px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.products__content-title span.stock-in {
  background: #20b52633;
  color: #2c742f;
}
.products__content-title span.stock-out {
  background: #ea4b481a;
  color: #ea4b48;
}
.products__content-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}
.products__content-info .ratings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.products__content-info .ratings .review-count {
  color: #666666;
}
.products__content-info .dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 12px;
  background-color: #b2b2b2;
}
.products__content-info h5 {
  color: #333333;
  text-transform: uppercase;
}
.products__content-info h5 .counting {
  color: #666666;
}
.products__content-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.products__content-price h2 {
  color: #2c742f;
}
.products__content-price h2 del {
  color: #b2b2b2;
}
.products__content-price .label {
  margin-left: 12px;
  padding: 3px 10px;
  border-radius: 30px;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
  font-weight: 500;
}
.products__content-price .label.sale-off {
  background: #ea4b481a;
  color: #ea4b48;
}
.products__content-brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .products__content-brand {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
.products__content-brand .brand-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.products__content-brand .brand-name h2 {
  margin-right: 8px;
}
.products__content-brand .social-site {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.products__content-brand .social-site h2 {
  color: #1a1a1a;
  text-transform: capitalize;
  margin-right: 10px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
@media (max-width: 767px) {
  .products__content-brand .social-site .social-icon-link {
    margin-right: 6px;
  }
}
.products__content-brand .social-site .social-icon-link a {
  color: #4d4d4d;
}
.products__content-brand .social-site .social-icon-link a:hover {
  color: #fff;
}
.products__content-brand-info {
  color: #808080;
  text-transform: capitalize;
}
.products__content-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 991px) {
  .products__content-action {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .products__content-action {
    max-width: 575px;
    overflow-x: scroll;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 16px;
  }
}
.products__content-action-item {
  margin-right: 12px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.products__content-action .button--md {
  width: 445px;
}
@media (max-width: 1440px) {
  .products__content-action .button--md {
    width: 50%;
  }
}
@media (max-width: 575px) {
  .products__content-action .button--md {
    width: auto;
    padding: 14px 12px;
  }
}
.products__content-action .button-fav {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  overflow: hidden;
  background: #20b5261a;
  color: #01b0df;
}
.products__content-action .button-fav:hover {
  color: #fff;
  background-color: #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.products__content-category {
  text-transform: capitalize;
  color: #1a1a1a;
  margin-bottom: 12px;
}
.products__content-category a {
  font-weight: 400;
  color: #808080;
}
.products__content-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.products__content-tags h5 {
  margin-right: 6px;
  text-transform: capitalize;
  color: #1a1a1a;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
@media (max-width: 575px) {
  .products__content-tags h5 {
    padding-bottom: 16px;
  }
}
@media (max-width: 575px) {
  .products__content-tags-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 575px;
    overflow-x: auto;
  }
}
.products__content-tags-item a {
  color: #808080;
  text-transform: capitalize;
  margin-right: 6px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.products__content-tags-item a:nth-last-child(1) {
  margin-right: 0px;
}
@media (max-width: 575px) {
  .products__content-tags-item a {
    margin-right: 12px;
    padding-bottom: 16px;
    white-space: nowrap;
  }
}
.products__content-tags-item a:hover {
  color: #1a1a1a;
  text-decoration: underline;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .popular-categories__wrapper,
  .popular-products__wrapper {
    grid-template-columns: repeat(4, 1fr);
  }

  .deals-products__wrapper {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "lg-card lg-card sm-card-one sm-card-two " "lg-card lg-card sm-card-three sm-card-four" "sm-card-five sm-card-six sm-card-seven sm-card-eight" "sm-card-nine sm-card-ten sm-card-eleven sm-card-twelve";
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .popular-categories__wrapper,
  .popular-products__wrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .deals-products__wrapper {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "lg-card lg-card sm-card-one" "lg-card lg-card sm-card-two" "sm-card-three sm-card-four sm-card-five" "sm-card-six sm-card-seven sm-card-eight" "sm-card-nine sm-card-ten sm-card-eleven";
  }
}
@media (min-width: 768px) {
  .popular-categories--slider,
  .popular-products--slider,
  .deals-products--slider {
    display: none;
  }
}
@media (max-width: 767px) {
  .popular-categories__wrapper,
  .popular-products__wrapper,
  .deals-products__wrapper {
    display: none;
  }

  .popular-products,
  .deals-products--slider {
    padding-bottom: 30px;
  }
}
.products__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .products__container {
    margin-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .products__container {
    margin-bottom: 12px;
  }
}
.products__gallery-main {
  width: 556px;
  height: 556px;
}
@media (max-width: 767px) {
  .products__gallery-main {
    height: 300px;
  }
}
.products__gallery-thumbs {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
  width: 250px;
  height: 556px;
  margin-right: 12px;
}
@media (max-width: 767px) {
  .products__gallery-thumbs {
    height: 300px;
  }
}
.products__gallery-thumbs .swiper-wrapper {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.products__gallery-thumbs .swiper-slide {
  width: 80px;
  height: 100px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .products__gallery-thumbs .swiper-slide {
    width: 80px;
    height: 80px !important;
  }
}
@media (max-width: 575px) {
  .products__gallery-thumbs .swiper-slide {
    width: 80px;
    height: 60px !important;
  }
}
.products__gallery-thumbs .swiper-slide-thumb-active {
  border: 1px solid #01b0df;
}
.products__gallery-img--lg {
  width: 100%;
  height: 556px;
}
@media (max-width: 767px) {
  .products__gallery-img--lg {
    height: 300px;
  }
}
.products__gallery-img--lg img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
@media (max-width: 575px) {
  .products__gallery-img--lg img {
    -o-object-fit: contain;
    object-fit: contain;
  }
}
.products__gallery-img--sm {
  width: 100%;
  height: 100px;
}
@media (max-width: 575px) {
  .products__gallery-img--sm {
    height: 40px;
  }
}
.products__gallery-img--sm img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
@media (max-width: 575px) {
  .products__gallery-img--sm img {
    -o-object-fit: contain;
    object-fit: contain;
  }
}

.gallery-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}
.gallery-view .product-main-image {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.gallery-view .gallery-main-image {
  margin-left: 12px;
}
.gallery-view .gallery-items-slider {
  height: 420px;
  overflow: hidden;
}
.gallery-view .gallery-items {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  width: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.gallery-view .gallery-items .gallery-item {
  height: 90px;
  width: 80px;
  margin-bottom: 10px;
}
.gallery-view .gallery-items .gallery-item.active {
  border: 1px solid #01b0df;
}
.gallery-view .gallery-items .gallery-item img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
@media (max-width: 991px) {
  .gallery-view .gallery-items .gallery-item img {
    -o-object-fit: contain;
    object-fit: contain;
  }
}
.gallery-view .gallery-items .gallery-prev-item,
.gallery-view .gallery-items .gallery-next-item {
  cursor: pointer;
  display: block !important;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 999;
}
.gallery-view .gallery-items .gallery-prev-item .gallery-icon,
.gallery-view .gallery-items .gallery-next-item .gallery-icon {
  width: 40px;
  height: 40px;
  color: #999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.gallery-view .gallery-items .gallery-prev-item {
  top: 0px;
}
.gallery-view .gallery-items .gallery-next-item {
  bottom: 0px;
}
@media only screen and (max-width: 991px) {
  .gallery-view {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .gallery-view .gallery-items {
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .gallery-view .gallery-main-image {
    width: 100%;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .gallery-view .gallery-main-image .gallery-items {
    width: 100%;
  }
  .gallery-view .gallery-items-slider {
    height: auto;
    overflow: hidden;
  }
  .gallery-view .gallery-items {
    height: 100%;
  }
  .gallery-view .gallery-items .gallery-prev-item {
    -webkit-transform: rotate(-90deg) translateX(50%);
    transform: rotate(-90deg) translateX(50%);
    left: 0;
    top: 50%;
  }
  .gallery-view .gallery-items .gallery-next-item {
    -webkit-transform: rotate(270deg) translateX(50%);
    transform: rotate(270deg) translateX(50%);
    left: unset;
    bottom: unset;
    top: 50%;
    right: 0;
  }
}

/** 2.7 Category Layout */
.card-category {
  position: relative;
  border: 1px solid #e5e5e5;
  overflow: hidden;
}
.card-category li a {
  padding: 15px 20px;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
  font-weight: 400;
  color: #1a1a1a;
  width: 100%;
}
.card-category li a span {
  margin-right: 15px;
  color: #999999;
}
.card-category li a:hover {
  background-color: #01b0df;
  color: #fff;
}
.card-category li a:hover span {
  color: inherit;
}
.card-category--view-all {
  border-top: 1px solid #e5e5e5;
}

@media (max-width: 1199px) {
  .card-category {
    margin: 12px 0px;
  }
}
/** 2.8 Newsletter Layout */
.newsletter {
  padding: 40px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.newsletter__leftcontent h2 {
  color: #1a1a1a;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .newsletter__leftcontent h2 {
    margin-bottom: 15px;
  }
}
.newsletter__leftcontent p {
  color: #999999;
  max-width: 75%;
}
.newsletter__rightcontent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.newsletter__input {
  position: relative;
  border-radius: 46px;
  margin-right: 22px;
}
.newsletter__input input {
  width: 492px;
  height: 52px;
  border-radius: 46px;
  padding: 14px 24px;
  background-color: #fff;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  border: 1px solid #e5e5e5;
}
@media (max-width: 767px) {
  .newsletter__input input {
    width: 100%;
  }
}
.newsletter__input input:focus {
  border-color: #84d187;
}
.newsletter__input input:hover {
  border-color: #84d187;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.newsletter__input input::-webkit-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
  text-transform: capitalize;
}
.newsletter__input input:-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
  text-transform: capitalize;
}
.newsletter__input input::-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
  text-transform: capitalize;
}
.newsletter__input input::placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
  text-transform: capitalize;
}
.newsletter__input button {
  position: absolute;
  top: 0;
  right: 0px;
}
.newsletter__social-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.newsletter__social-icon li {
  margin-right: 8px;
}
.newsletter__social-icon li a {
  color: #4d4d4d;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.newsletter__social-icon li a:hover {
  color: #fff;
  background-color: #01b0df;
}
.newsletter--two {
  padding: 60px 60px;
}
.newsletter--two .newsletter__leftcontent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.newsletter--two .newsletter__leftcontent h2 {
  color: #fff;
}
.newsletter--two .newsletter__leftcontent p {
  max-width: 95%;
  color: #666666;
}
.newsletter--two .newsletter__leftcontent-icon {
  margin-right: 15px;
}
.newsletter--two .newsletter__rightcontent {
  overflow: hidden;
}
.newsletter--two .newsletter__input {
  border: 1px solid transparent;
  background-color: #333333;
}
.newsletter--two .newsletter__input input {
  background-color: #333333;
}
.newsletter--two .newsletter__social-icon li a {
  color: #b3b3b3;
}
.newsletter--two .newsletter__social-icon li a:hover {
  color: #fff;
}
.newsletter--four .newsletter__leftcontent h2 {
  color: #002603;
}
.newsletter--four .newsletter__leftcontent p {
  max-width: 100%;
  color: #7a997c;
}
.newsletter--four .newsletter__input {
  border: 1px solid #dae5da;
}

@media (max-width: 1200px) {
  .newsletter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .newsletter__leftcontent {
    text-align: center;
    margin-bottom: 15px;
  }
  .newsletter__leftcontent p {
    margin: auto;
  }
  .newsletter__rightcontent {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .newsletter__input {
    margin-right: 0px;
  }
  .newsletter__social-icon {
    margin-top: 15px;
  }
  .newsletter--two {
    padding-left: 0px;
    padding-right: 0px;
  }
  .newsletter--two .newsletter__leftcontent {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .newsletter--two .newsletter__leftcontent-icon {
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .newsletter--three .newsletter__brand-logo {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .newsletter__leftcontent p {
    max-width: 100%;
  }
  .newsletter__input {
    width: 305px;
  }
  .newsletter--two .newsletter__leftcontent p {
    max-width: 100%;
  }
}
.newsletter-popup .modal {
  position: relative;
}
.newsletter-popup .modal-body {
  padding: 10px;
}
.newsletter-popup__text-content {
  padding: 0px 40px;
  text-align: center;
  margin-top: 40px;
}
@media (max-width: 991px) {
  .newsletter-popup__text-content {
    padding: 30px 15px;
    margin-top: 0px;
  }
}
.newsletter-popup__text-content h5 {
  text-transform: capitalize;
  color: #1a1a1a;
  margin-bottom: 12px;
}
.newsletter-popup__text-content p {
  color: #999999;
  margin-bottom: 24px;
  text-transform: capitalize;
}
.newsletter-popup__text-content p span {
  color: #ff8a00;
  font-weight: 600;
}
.newsletter-popup__text-content .contact-mail {
  position: relative;
  width: 100%;
  height: 48px;
  overflow: hidden;
  margin-bottom: 50px;
}
@media (max-width: 991px) {
  .newsletter-popup__text-content .contact-mail {
    margin-bottom: 30px;
  }
}
.newsletter-popup__text-content .contact-mail input {
  width: inherit;
  height: inherit;
  border: 1px solid #e5e5e5;
  border-radius: 46px;
  padding-left: 24px;
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
  width: 319px;
  padding: 9px;
}

select#lang {
  position: absolute;
  left: 336px;
  top: 1px;
  padding: 6px 0px;
  border-radius: 21px 0px 0px 21px;
  border: #ccc solid 1px;
}
.newsletter-popup__text-content .contact-mail input::-webkit-input-placeholder {
  text-transform: capitalize;
  color: #808080;
}
.newsletter-popup__text-content .contact-mail input:-ms-input-placeholder {
  text-transform: capitalize;
  color: #808080;
}
.newsletter-popup__text-content .contact-mail input::-ms-input-placeholder {
  text-transform: capitalize;
  color: #808080;
}
.newsletter-popup__text-content .contact-mail input::placeholder {
  text-transform: capitalize;
  color: #808080;
}
.newsletter-popup__text-content .contact-mail input:hover,
.newsletter-popup__text-content .contact-mail input:focus {
  border-color: #01b0df;
}
.newsletter-popup__text-content .contact-mail button {
  position: absolute;
  top: 40%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
  padding: 12px 32px;
  border-radius: 0px 20px 20px 0px;
}
@media (max-width: 575px) {
  .newsletter-popup__text-content .contact-mail button {
    padding: 15px 12px;
  }
}
.newsletter-popup__text-content .btn-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.newsletter-popup__text-content .btn-close:hover {
  color: #1a1a1a;
}
@media (max-width: 991px) {
  .newsletter-popup__text-content .btn-close {
    top: 0px;
    right: 0px;
  }
}
.newsletter-popup__text-content .form-check {
  display: inline-block;
  padding: 0;
  margin-bottom: 0;
}
.newsletter-popup__text-content .form-check label {
  font-size: 14px;
  line-height: 28px;
  color: #666666;
}
.newsletter-popup__img-wrapper {
  position: relative;
  width: 100%;
  height: 380px;
  overflow: hidden;
  border-radius: 4px;
}
.newsletter-popup__img-wrapper img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}

/** 2.9 Hero Layout */
.hero__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.hero__text-content h5 {
  font-size: 56px;
  line-height: 67.2px;
  width: 607px;
  margin-bottom: 32px;
}
.hero__text-content h6 {
  font-size: 48px;
  line-height: 57.6px;
  margin-bottom: 24px;
  width: 552px;
}
.hero__text-content h5,
.hero__text-content h6 {
  color: #1a1a1a;
  font-weight: 600;
}
@media (max-width: 1200px) {
  .hero__text-content h5,
  .hero__text-content h6 {
    max-width: 500px;
    font-size: 48px;
    line-height: 58px;
  }
}
@media (max-width: 767px) {
  .hero__text-content h5,
  .hero__text-content h6 {
    max-width: 100%;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 15px;
  }
}
.hero__text-content .info {
  font-size: 18px;
  line-height: 27px;
  color: #666666;
  font-weight: 400;
}
@media (max-width: 767px) {
  .hero__text-content .info {
    font-size: 14px;
  }
}
.hero__text-content .info--two {
  font-size: 16px;
  line-height: 24px;
  color: #666666;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .hero__text-content .info--two {
    font-size: 14px;
  }
}
.hero__text-content a.button {
  margin-top: 20px;
}
.hero__list-info li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 16px;
}
.hero__list-info li .icon {
  margin-right: 8px;
  color: #2c742f;
}
.hero__list-info li p {
  font-size: 14px;
  line-height: 19.6px;
  color: #666666;
  font-weight: 400;
}
.hero__img-wrapper {
  width: 100%;
  height: 492px;
  border-radius: 8px;
  overflow: hidden;
}
@media (max-width: 991px) {
  .hero__img-wrapper {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .hero__img-wrapper {
    height: 210px;
    margin-top: 16px;
  }
}
.hero__img-wrapper-two-bg {
  position: absolute;
  width: 80%;
  height: 685px;
  z-index: -1;
}
@media (max-width: 1680px) {
  .hero__img-wrapper-two-bg {
    width: 100%;
  }
}
@media (max-width: 1680px) {
  .hero__img-wrapper-two-bg::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.hero__img-wrapper-two-bg img {
  width: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.hero__img-wrapper img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.hero .our-feature--grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}
.hero .our-feature__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.hero .our-feature__item .icon {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  overflow: hidden;
  background: #00b2071a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #2c742f;
  margin-right: 16px;
}
.hero .our-feature__item-info h2 {
  color: #1a1a1a;
  text-transform: capitalize;
  margin-bottom: 8px;
}
.hero .our-feature__item-info p {
  color: #808080;
}
.hero--two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}
.hero--two .hero__text-content {
  margin-left: 48px;
}
@media (max-width: 1200px) {
  .hero--two .hero__text-content {
    margin-left: 0px;
  }
}
.hero--two .hero__text-content h5 {
  color: #002603;
}
@media (max-width: 1680px) {
  .hero--two .hero__text-content h5 {
    color: #fff;
  }
}
@media (max-width: 1680px) {
  .hero--two .hero__text-content p {
    color: #cccccc;
  }
}
@media (max-width: 1400px) {
  .hero--two .hero__text-content p {
    width: 570px;
  }
}
@media (max-width: 1400px) {
  .hero--two .our-feature--grid {
    grid-template-columns: repeat(2, 350px);
  }
}
@media (max-width: 1200px) {
  .hero--two .our-feature--grid {
    width: 100%;
    overflow: hidden;
  }
}
@media (max-width: 767px) {
  .hero--two .our-feature--grid {
    display: none;
  }
}
@media (max-width: 1680px) {
  .hero--two .our-feature__item .icon {
    background-color: #01b0df;
    color: #fff;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
}
@media (max-width: 1680px) {
  .hero--two .our-feature__item-info h2 {
    color: #fff;
  }
}
@media (max-width: 1680px) {
  .hero--two .our-feature__item-info p {
    color: #cccccc;
  }
}
@media (min-width: 768px) {
  .hero--two .our-feature--slider {
    display: none;
  }
}
.hero--two .our-feature--slider .swiper-wrapper {
  padding-bottom: 30px;
}
@media (min-width: 480px) and (max-width: 767px) {
  .hero--two .our-feature--slider .swiper-pagination {
    left: 40px;
  }
}

/** 2.10 Blog Sidebar Layout */
@media (max-width: 991px) {
  .sidebar {
    position: fixed;
    top: 0px;
    left: -5%;
    background: #fff;
    width: 5%;
    height: 100%;
    z-index: 9999;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
}
@media (max-width: 991px) and (max-width: 767px) {
  .sidebar {
    width: 80%;
    left: -80%;
  }
}

@media (max-width: 991px) {
  .sidebar.active {
    left: 0;
    width: 35%;
    -webkit-transition: all 0.6s linear;
    transition: all 0.6s linear;
  }
}
@media (max-width: 767px) {
  .sidebar.active {
    width: 80%;
  }
}
.sidebar.active .filter {
  top: 15px;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.sidebar .filter {
  position: absolute;
  right: -38px;
  top: 30%;
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 8px;
  border-radius: 6px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: #01b0df;
  z-index: 9999;
}
@media (min-width: 992px) {
  .sidebar .filter {
    display: none;
  }
}
.sidebar .filter svg {
  height: 18px;
}
.sidebar .filter:hover {
  background-color: #2c742f;
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
}

.blog__sidebar {
  position: relative;
  height: inherit;
  overflow-y: auto;
  padding: 0 0 15px;
}
.blog__sidebar--item {
  padding-top: 20px;
  padding-bottom: 28px;
  border-bottom: 1px solid #e5e5e5;
}
.blog__sidebar--item:nth-child(1) {
  padding-top: 0px;
}
.blog__sidebar--item:nth-last-child(1) {
  border-bottom: 0px;
}
.blog__sidebar--item h5 {
  text-transform: capitalize;
  margin-bottom: 20px;
}
.blog__search-field {
  width: 100%;
  height: 50px;
  overflow: hidden;
  position: relative;
}
.blog__search-field input {
  width: 100%;
  height: inherit;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  padding-left: 45px;
  font-size: 16px;
  line-height: 20.8px;
  color: #1a1a1a;
  text-transform: capitalize;
}
.blog__search-field input::-webkit-input-placeholder {
  font-size: inherit;
  color: colo(gray-4);
}
.blog__search-field input:-ms-input-placeholder {
  font-size: inherit;
  color: colo(gray-4);
}
.blog__search-field input::-ms-input-placeholder {
  font-size: inherit;
  color: colo(gray-4);
}
.blog__search-field input::placeholder {
  font-size: inherit;
  color: colo(gray-4);
}
.blog__search-field input:hover,
.blog__search-field input:focus {
  border-color: #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.blog__search-field .icon {
  position: absolute;
  top: 50%;
  left: 18px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.blog__top-categories-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 16px;
}
.blog__top-categories-item p {
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
  text-transform: capitalize;
  font-weight: 400;
}
.blog__top-categories-item .number {
  color: #808080;
}
.blog__popular-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.blog__popular-tags-item {
  display: inline-block;
  padding: 6px 16px;
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
  text-transform: capitalize;
  font-weight: 400;
  background-color: #f2f2f2;
  border-radius: 30px;
  margin-bottom: 8px;
  margin-right: 8px;
}
.blog__popular-tags-item:hover,
.blog__popular-tags-item.active {
  background-color: #01b0df;
  color: #fff;
}
.blog-gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.blog-gallery__item {
  margin-right: 6px;
}
.blog-gallery__item .cards-ig__img {
  width: 90px;
  height: 90px;
}
@media (max-width: 1199px) {
  .blog-gallery__item .cards-ig__img {
    width: 60px;
    height: 60px;
  }
}
.blog__recent-product__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}
.blog__recent-product__item:hover .blog__recent-product__item-info h5 {
  color: #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.blog__recent-product__item:nth-last-child(1) {
  margin-bottom: 0px;
}
.blog__recent-product__item-info {
  width: 80%;
}
.blog__recent-product__item-info h5 {
  margin-bottom: 0px;
  color: #1a1a1a;
}
.blog__recent-product__item-info .date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.blog__recent-product__item-info .date .icon {
  margin-right: 8px;
}
.blog__recent-product__item-info .date p {
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
  color: #666666;
  font-weight: 400;
}
.blog__recent-product__img-wrapper {
  width: 100px;
  height: 80px;
  overflow: hidden;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 12px;
}
.blog__recent-product__img-wrapper img {
  width: inherit;
  height: inherit;
}

/** 2.11 Shop Sidebar Layout */
@media (max-width: 991px) {
  .shop__sidebar {
    position: fixed;
    top: 0;
    left: -45%;
    width: 45%;
    background-color: #fff;
    z-index: 9999;
    height: 100%;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .shop__sidebar-content {
    padding: 15px;
    height: 100%;
    overflow-y: auto;
  }
}
@media (max-width: 767px) {
  .shop__sidebar {
    width: 80%;
    left: -80%;
  }
}
.shop__sidebar .filter {
  position: absolute;
  top: 35%;
  -webkit-transform: translateY(-35%);
  transform: translateY(-35%);
  right: -35px;
  background-color: #01b0df;
  color: #fff;
  padding: 8px;
  border-radius: 6px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  z-index: 9999;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
@media (min-width: 992px) {
  .shop__sidebar .filter {
    display: none;
  }
}
.shop__sidebar.active {
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
  left: 0px;
}
.shop__sidebar.active .filter {
  top: 15px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.shop-button {
  border: 0px;
}
.shop-button:not(.collapsed) {
  background-color: #fff;
  color: #1a1a1a;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.shop-button:focus {
  border-color: transparent;
}
.shop-collapse {
  border: 0px;
  border-bottom: 1px solid #e5e5e5;
}
.shop-button {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.shop-button .icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.shop-button.collapsed .icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.shop-button,
.shop-body {
  padding: 0px;
}
.shop-body {
  padding-top: 12px;
  padding-bottom: 26px;
}
.shop-item {
  padding-bottom: 20px;
  border: 0px;
}
.shop .categories-item {
  margin-bottom: 10px;
}
.shop .categories-item:nth-last-child(1) {
  margin-bottom: 0px;
}
.shop .categories-item .form-check input {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
.shop .categories-item .form-check input:checked {
  border: 0.5px solid #01b0df;
  background-color: #01b0df;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.shop .categories-item .form-check-label {
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
  font-weight: 400;
  text-transform: capitalize;
}
.shop .categories-item .form-check-label .current {
  color: #808080;
}
.shop .ratings--list-item {
  margin-bottom: 10px;
}
.shop .ratings--list-item:nth-last-child(1) {
  margin-bottom: 0px;
}
.shop .ratings--list-item .form-check {
  cursor: pointer;
}
.shop .ratings--list-item .form-check input {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #cccccc;
}
.shop .ratings--list-item .form-check input:checked {
  border-color: #01b0df;
  background-color: #01b0df;
}
.shop .ratings--list-item .form-check .star-num {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #1a1a1a;
  margin-left: 5px;
}
.shop .popular-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.shop .popular-tags .tag-btn {
  padding: 8px 16px;
  border-radius: 30px;
  background-color: #f2f2f2;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #1a1a1a;
  text-transform: capitalize;
}
.shop .popular-tags .tag-btn:hover,
.shop .popular-tags .tag-btn.active {
  background-color: #01b0df;
  color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.shop-img-banner {
  position: relative;
  width: 100%;
  height: 295px;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
}
.shop-img-banner img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.shop-img-banner .text-content {
  text-align: center;
  position: absolute;
  top: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
}
.shop-img-banner .text-content h5 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  text-transform: capitalize;
  color: #1a1a1a;
  margin-bottom: 0px;
}
.shop-img-banner .text-content h5 span {
  font-size: 32px;
  line-height: 38.4px;
  font-weight: 600;
  color: #ff8a00;
}
.shop-img-banner .text-content p {
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
  text-transform: lowercase;
  font-weight: 400;
}
.shop-img-banner .text-content .button {
  margin-top: 12px;
  background-color: transparent;
  color: #01b0df;
}
.shop-sale-product-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  margin-bottom: 16px;
}
.shop-sale-product-item:nth-last-child(1) {
  margin-bottom: 0px;
}
.shop-sale-product-item:hover {
  border: 1px solid #01b0df;
  -webkit-box-shadow: 0px 0px 12px 0px #20b52652;
  box-shadow: 0px 0px 12px 0px #20b52652;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.shop-sale-product-item .product-img {
  position: relative;
  width: 102px;
  height: 102px;
  overflow: hidden;
  margin: 0px 15px;
}
.shop-sale-product-item .product-img img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.shop-sale-product-item .product-info h5 {
  color: #4d4d4d;
  text-transform: capitalize;
}
.shop-sale-product-item .product-info .price .current,
.shop-sale-product-item .product-info .price del {
  font-size: 16px;
  line-height: 24px;
}
.shop-sale-product-item .product-info .price .current {
  color: #1a1a1a;
  font-weight: 500;
}
.shop-sale-product-item .product-info .price del {
  color: #999999;
  font-weight: 400;
}
.shop-sale-product-item .product-info .rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shopping-cart {
  position: fixed;
  top: 0;
  right: -455px;
  width: 355px;
  height: 100%;
  padding: 40px;
  overflow-y: auto;
  background-color: #fff;
  z-index: 9999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
@media (max-width: 767px) {
  .shopping-cart {
    right: -100%;
    width: 100%;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
}
.shopping-cart.active {
  right: 0px;
  width: 455px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
@media (max-width: 575px) {
  .shopping-cart.active {
    width: 100%;
  }
}
.shopping-cart-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}
.shopping-cart__product-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 0px;
  border-bottom: 1px solid #e5e5e5;
}
.shopping-cart__product-content-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.shopping-cart__product-content-item .img-wrapper {
  width: 120px;
  height: 100px;
  overflow: hidden;
  margin-right: 8px;
}
.shopping-cart__product-content-item .img-wrapper img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.shopping-cart__product-content-item .text-content p {
  color: #808080;
}
.shopping-cart__product-content-item .text-content p span {
  color: #1a1a1a;
}
.shopping-cart__product-content:nth-child(1) {
  padding-top: 0px;
}
.shopping-cart__product-content:nth-last-child(1) {
  border: 0px;
}
.shopping-cart-product-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}
.shopping-cart-product-info p,
.shopping-cart-product-info span {
  color: #1a1a1a;
}
.shopping-cart-bottom button {
  margin-bottom: 12px;
}
.shopping-cart-bottom button:nth-last-child(1) {
  margin-bottom: 0px;
}

/** 2.12 Breadcrumb Layout */
.breedcrumb__img-wrapper {
  position: relative;
  width: 100%;
  height: 120px;
  overflow: hidden;
}
.breedcrumb__img-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90.02deg,
    rgba(0, 0, 0, 0.7) 0.03%,
    rgba(0, 0, 0, 0) 91.31%
  );
}
.breedcrumb__img-wrapper img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.breedcrumb__content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
}
@media (max-width: 575px) {
  .breedcrumb__content li:nth-child(2),
  .breedcrumb__content li:nth-child(3) {
    display: none;
  }
}
.breedcrumb__content li a {
  font-size: 16px;
  line-height: 24px;
  color: #999999;
  font-weight: 400;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.breedcrumb__content li a svg {
  margin-bottom: 6px;
}
.breedcrumb__content li a span {
  margin: 0px 12px;
}
.breedcrumb__content li.active {
  display: block;
}
.breedcrumb__content li.active a {
  color: #01b0df;
}
.breedcrumb--two {
  padding: 25px 0px;
}
.breedcrumb--two .breedcrumb__content {
  position: relative;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  top: 0;
  left: 0;
}

/** 2.13 Contact Form Layout */
.form-check-label {
  font-size: 14px;
  line-height: 28px;
  color: #4d4d4d;
  font-weight: 400;
  text-transform: capitalize;
}
.form-check-input {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  border: 1px solid #cccccc;
  margin-right: 6px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-check-input:checked {
  background-color: #01b0df;
  border-color: #01b0df;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #01b0df;
}

.contact-form__contact-info,
.contact-form-card {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 56px 0px #00260314;
  box-shadow: 0px 0px 56px 0px #00260314;
  overflow: hidden;
}
.contact-form__contact-info {
  text-align: center;
  padding: 0px 20px;
}
@media (max-width: 991px) {
  .contact-form__contact-info {
    margin-bottom: 24px;
  }
}
.contact-form__contact-info--item {
  padding-top: 30px;
  padding-bottom: 24px;
  border-top: 1px solid #e5e5e5;
}
.contact-form__contact-info--item:nth-child(1) {
  border-top: 0px;
}
.contact-form__contact-info--item:nth-last-child(1) {
  border-bottom: 0px;
}
.contact-form__contact-info--item .icon {
  display: inline-block;
  margin-bottom: 20px;
}
.contact-form__contact-info--item p {
  font-size: 16px;
  line-height: 27.2px;
  color: #333333;
}
.contact-form-card {
  padding: 45px 50px;
  padding-bottom: 50px;
  height: 100%;
}
@media (max-width: 767px) {
  .contact-form-card {
    padding: 30px;
  }
}
.contact-form-card h2 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  color: #1a1a1a;
  text-transform: capitalize;
  margin-bottom: 8px;
}
.contact-form-card p {
  font-size: 14px;
  line-height: 21px;
  color: #808080;
  text-transform: capitalize;
  width: 485px;
}
@media (max-width: 767px) {
  .contact-form-card p {
    width: 100%;
  }
}
.contact-form-card form {
  margin-top: 24px;
}
.contact-form--input {
  position: relative;
  width: 100%;
  height: 48px;
  margin-bottom: 16px;
  overflow: hidden;
}
.contact-form--input label {
  position: absolute;
  top: -26px;
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
  font-weight: 400;
  text-transform: capitalize;
}
.contact-form--input input,
.contact-form--input textarea {
  width: 95%;
  height: inherit;
  padding-left: 16px;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  font-size: 16px;
  line-height: 20.8px;
  color: #1a1a1a;
  background-color: inherit;
}
.contact-form--input input::-webkit-input-placeholder,
.contact-form--input textarea::-webkit-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.contact-form--input input:-ms-input-placeholder,
.contact-form--input textarea:-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.contact-form--input input::-ms-input-placeholder,
.contact-form--input textarea::-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.contact-form--input input::placeholder,
.contact-form--input textarea::placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.contact-form--input input.active,
.contact-form--input input:hover,
.contact-form--input input:focus,
.contact-form--input textarea.active,
.contact-form--input textarea:hover,
.contact-form--input textarea:focus {
  border: 1px solid #01b0df;
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.contact-form--input-area {
  height: 98px;
}
.contact-form--input-area textarea {
  padding-top: 14px;
  resize: none;
}
.contact-form--check input {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #cccccc;
}
.contact-form--check input:checked {
  background-color: #01b0df;
  border: #01b0df;
}
.contact-form--check label {
  font-size: 14px;
  line-height: 28px;
  color: #666666;
  text-transform: capitalize;
  font-weight: 400;
}
.contact-form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 767px) {
  .contact-form-group {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.contact-form-group .contact-form--input {
  margin-right: 16px;
}
.contact-form-group .contact-form--input:nth-last-child(1) {
  margin-right: 0px;
}
@media (max-width: 767px) {
  .contact-form-group .contact-form--input {
    margin-right: 0px;
  }
}
.contact-form-button {
  margin-top: 24px;
}

.contact-form__content-group {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .contact-form__content-group {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.contact-form__content-group .contact-form-input {
  margin-right: 16px;
  width: 100%;
}
@media (max-width: 991px) {
  .contact-form__content-group .contact-form-input {
    margin-right: 0px;
  }
}
.contact-form__content-group .contact-form-input:nth-last-child(1) {
  margin-right: 0px;
}
.contact-form-input {
  margin-bottom: 16px;
  position: relative;
}
.contact-form-input label {
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
  text-transform: capitalize;
  margin-bottom: 6px;
  display: block;
}
.contact-form-input label span {
  color: #666666;
}
.contact-form-input input {
  position: relative;
  width: 100%;
  height: 50px;
  padding-left: 16px;
  padding-right: 60px;
  font-size: 16px;
  line-height: 20.8px;
  color: #1a1a1a;
  font-weight: 400;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  background-color: #fff;
}
@media (max-width: 767px) {
  .contact-form-input input {
    padding-right: 16px;
  }
}
.contact-form-input input::-webkit-outer-spin-button,
.contact-form-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.contact-form-input input::-webkit-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #666666;
}
.contact-form-input input:-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #666666;
}
.contact-form-input input::-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #666666;
}
.contact-form-input input::placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #666666;
}
@media (max-width: 767px) {
  .contact-form-input input::-webkit-input-placeholder {
    font-size: 12px;
  }
  .contact-form-input input:-ms-input-placeholder {
    font-size: 12px;
  }
  .contact-form-input input::-ms-input-placeholder {
    font-size: 12px;
  }
  .contact-form-input input::placeholder {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .contact-form-input input[type="password"] {
    padding-right: 60px;
  }
}
.contact-form-input input:active,
.contact-form-input input:hover,
.contact-form-input input:focus {
  border-color: #01b0df;
  -webkit-transform: all 0.3s linear;
  transform: all 0.3s linear;
}
.contact-form-input .bv_mainselect {
  padding: 0px;
  height: 50px;
}
.contact-form-input .bv_mainselect:hover,
.contact-form-input .bv_mainselect.active {
  border-color: #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.contact-form-input .bv_mainselect .bv_atual {
  border: 0px;
  height: inherit;
  border: 1px solid #e5e5e5;
  line-height: 30px;
  color: #666666;
  padding-left: 16px;
}
.contact-form-input .bv_mainselect .bv_atual:hover,
.contact-form-input .bv_mainselect .bv_atual.active {
  border-color: #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.contact-form-input .bv_mainselect .bv_atual .arrow {
  border-color: #999999;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 3px;
  margin-right: 8px;
}
.contact-form-input .bv_mainselect .bv_ul_inner {
  border: 1px solid #e5e5e5;
  z-index: 9;
}
.contact-form-input .icon {
  position: absolute;
  top: calc(50% + 10px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 18px;
  cursor: pointer;
}
.contact-form-input.filled input {
  color: #666666;
}
.contact-form-textarea textarea {
  resize: none;
  width: 100%;
  height: 100px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 14px 16px;
  font-size: 16px;
  line-height: 20.8px;
  font-weight: 400;
  color: #666666;
}
.contact-form-textarea textarea:hover,
.contact-form-textarea textarea.active {
  border-color: #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.contact-form-textarea textarea::-webkit-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.contact-form-textarea textarea:-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.contact-form-textarea textarea::-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.contact-form-textarea textarea::placeholder {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.contact-form-btn {
  margin-top: 8px;
}

/** 2.14 Maps Layout */
.iframe__canvas {
  width: 100%;
  height: 400px;
  overflow: hidden;
}
.iframe__canvas iframe {
  width: inherit;
  height: inherit;
}

/** 2.15 Footer Layout */
.footer {
  position: relative;
  background-color: #1a1a1a;
  z-index: 1;
  overflow: hidden;
}
.footer__top {
  padding: 66px 0px;
}
@media (max-width: 991px) {
  .footer__brand-info {
    margin-bottom: 38px;
  }
}
.footer__brand-info-logo,
.footer__brand-info h2 {
  margin-bottom: 16px;
}
.footer__brand-info h2 {
  color: #fff;
}
.footer__brand-info p {
  color: #808080;
  max-width: 350px;
  margin-bottom: 22px;
}
.footer__brand-info-contact a {
  margin: 0px 16px;
  max-width: 250px;
}
@media (max-width: 420px) {
  .footer__brand-info-contact a {
    margin: 0px;
    margin: 6px;
  }
}
.footer__brand-info-contact a:nth-child(1) {
  margin-left: 0px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .footer__brand-info-contact a:nth-child(1) {
    margin-bottom: 16px;
  }
}
@media (max-width: 420px) {
  .footer__brand-info-contact a:nth-child(1) {
    margin-top: 0px;
  }
}
.footer__brand-info-contact a:nth-child(2) {
  margin-right: 0px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .footer__brand-info-contact a:nth-child(2) {
    margin: 0px;
  }
}
.footer__brand-info-contact span {
  color: #fff;
  padding-bottom: 6px;
  border-bottom: 1px solid #01b0df;
  font-weight: 500;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.footer__brand-info-contact span:hover {
  color: #01b0df;
  border-color: #2c742f;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.footer__navigation {
  overflow: hidden;
}
@media (max-width: 767px) {
  .footer__navigation {
    margin-bottom: 20px;
  }
}
.footer__navigation-title {
  margin-bottom: 20px;
}
.footer__navigation-title h2 {
  color: #fff;
  text-transform: capitalize;
}
.footer__navigation-link {
  margin-bottom: 12px;
}
.footer__navigation-link a {
  font-size: 14px;
  line-height: 21px;
  color: #999999;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  text-transform: capitalize;
}
.footer__navigation-link a:hover {
  color: #fff;
}
.footer__bottom {
  padding: 30px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #333333;
}
.footer__copyright-text {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #808080;
}
.footer__partner-item {
  margin-right: 8px;
}
.footer__partner-item:nth-last-child(1) {
  margin-right: 0px;
}
.footer__mobile-app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer__mobile-app--item {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 8px;
  padding: 10px;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}
.footer__mobile-app--item:hover {
  background-color: #e5e5e5;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.footer__mobile-app--item:nth-last-child(1) {
  margin-right: 0px;
}
.footer__mobile-app--item span {
  margin-right: 8px;
  color: #1a1a1a;
}
.footer__mobile-app--info h5 {
  font-size: 11px;
  line-height: 14.3px;
  color: #4d4d4d;
}
.footer__mobile-app--info h2 {
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
}
.footer__instagram {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (max-width: 1199px) {
  .footer__instagram {
    width: 350px;
  }
}
@media (max-width: 1460px) {
  .footer__instagram {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
.footer__instagram-item {
  margin-bottom: 6px;
  margin-right: -6px;
}
@media (max-width: 1460px) {
  .footer__instagram-item {
    margin-right: 12px;
  }
}
.footer__instagram-item .cards-ig__img {
  width: 69px;
  height: 69px;
}
.footer--one .footer__brand-info-contact {
  color: #808080;
}
.footer--two {
  background-color: #fff;
}
.footer--two .footer__brand-info-contact span {
  color: #1a1a1a;
}
.footer--two .footer__brand-info-contact span:hover {
  color: #01b0df;
  border-color: #2c742f;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.footer--two .footer__navigation-title h2 {
  color: #1a1a1a;
}
.footer--two .footer__navigation-link a {
  color: #666666;
}
.footer--two .footer__navigation-link a:hover {
  color: #1a1a1a;
}
.footer--two .footer__top {
  background-color: #f2f2f2;
  padding: 60px;
}
@media (max-width: 1200px) {
  .footer--two .footer__top {
    padding: 0px;
    padding-top: 60px;
  }
}
.footer--two .footer__bottom {
  border-top: 0px;
}
.footer--three .footer__mobile-app--item,
.footer--five .footer__mobile-app--item {
  background-color: #333333;
  border-radius: 4px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.footer--three .footer__mobile-app--item:hover,
.footer--five .footer__mobile-app--item:hover {
  background-color: #4d4d4d;
}
.footer--three .footer__mobile-app--item span,
.footer--five .footer__mobile-app--item span {
  color: #fff;
}
.footer--three .footer__mobile-app--info h5,
.footer--five .footer__mobile-app--info h5 {
  color: #b2b2b2;
}
.footer--three .footer__mobile-app--info h2,
.footer--five .footer__mobile-app--info h2 {
  color: #fff;
}
.footer--four {
  background-color: #002603;
}
@media (max-width: 1199px) {
  .footer--four .footer__brand-info {
    margin-bottom: 30px;
  }
}
.footer--four .footer__brand-info p {
  color: #618062;
}
.footer--four .footer__brand-info-contact {
  color: #618062;
}
.footer--four .footer__navigation-link a {
  color: #618062;
}
.footer--four .footer__navigation-link a:hover {
  color: #fff;
}
.footer--four .footer__social-icon-link a {
  color: #96b297;
}
.footer--four .footer__social-icon-link a:hover {
  color: #fff;
}
.footer--four .footer__copyright-text {
  color: #618062;
}
.footer--four .footer__bottom {
  border-top: 0;
  -webkit-box-shadow: 0px -1px 0px 0px #1b421d;
  box-shadow: 0px -1px 0px 0px #1b421d;
}
.footer--five {
  background-color: #1a1a1a;
  overflow: hidden;
}
.footer--five .footer__bottom {
  border-top: 0px;
  position: relative;
}
.footer--five .footer__bottom::after {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: calc(100% * 2);
  height: 1px;
  background-color: #333333;
}
.footer--five .footer__brand-info p {
  color: #999999;
}
.footer--five .footer__navigation-title h2 {
  position: relative;
}
.footer--five .footer__navigation-title h2::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 0px;
  width: 24px;
  height: 2px;
  background-color: #01b0df;
}
.footer--five .footer__navigation-link a {
  color: #999999;
}
.footer--five .footer__navigation-link a:hover {
  color: #fff;
}
.footer--five .footer__mobile-app {
  margin-top: 30px;
}
.footer--five .footer__mobile-app--item {
  border-radius: 4px;
  background-color: #333333;
}
.footer--five .footer__mobile-app--item span {
  color: #fff;
}
.footer--five .footer__mobile-app--info h5 {
  color: #b2b2b2;
}
.footer--five .footer__mobile-app--info h2 {
  color: #fff;
}
.footer--five .footer__copyright-text {
  color: #999999;
}
.footer__overlay-img {
  position: absolute;
  color: #333;
  z-index: -1;
}
.footer__overlay-img.one {
  top: 0;
  left: 0;
  z-index: -1;
}
.footer__overlay-img.two {
  bottom: 50px;
  left: 105px;
  z-index: 1;
}
@media (max-width: 1024px) {
  .footer__overlay-img.two {
    left: 0px;
  }
}
@media (max-width: 767px) {
  .footer__overlay-img.two {
    bottom: 90px;
    z-index: -1;
  }
}
@media (max-width: 575px) {
  .footer__overlay-img.two {
    left: -55px;
  }
}
.footer__overlay-img.three {
  top: 12px;
  right: 18px;
}
.footer__overlay-img.four {
  top: 11px;
  right: 142px;
}
.footer__overlay-img.five {
  top: 106px;
  right: 16px;
}
.footer__overlay-img.six {
  top: 213px;
  right: 60px;
}
.footer__overlay-img.seven {
  top: 165px;
  right: 0px;
}
.footer--four .footer__overlay-img {
  color: #173b1a;
  z-index: -1;
}
@media (max-width: 767px) {
  .footer--four .footer__overlay-img {
    display: none;
  }
}
@media (max-width: 1024px) {
  .footer--four .footer__overlay-img.three {
    top: auto;
    bottom: 0px;
    right: 0px;
  }
}

@media (max-width: 1200px) {
  .footer--two {
    background-color: #f2f2f2;
  }

  .footer--two .footer__brand-info-contact,
  .footer--three .footer__brand-info-contact {
    margin-bottom: 30px;
  }

  .footer--five .social-icon {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .footer__top {
    padding-bottom: 30px;
  }

  .footer__brand-info p {
    max-width: 100%;
  }

  .footer__bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
  .footer__bottom .footer__copyright-text {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .footer__bottom .footer__partner {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-bottom: 15px;
  }

  .footer--two {
    background-color: #f2f2f2;
  }

  .footer--three .footer__bottom .footer__copyright-text,
  .footer--four .footer__bottom .footer__copyright-text {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin: 15px 0px;
  }
  .footer--three .footer__bottom .footer__partner,
  .footer--four .footer__bottom .footer__partner {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    margin-bottom: 0px;
  }
}
@media (max-width: 574px) {
  .footer__mobile-app {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer__mobile-app--item {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 0px;
    margin-bottom: 15px;
  }
}
/** Bar Component  */
.border--bottom-left {
  position: relative;
}
.border--bottom-left::after {
  content: "";
  position: absolute;
  bottom: -16px;
  left: 0;
  width: 80px;
  height: 3px;
  background-color: #01b0df;
}

/** Dots Component  */
.swiper-pagination-bullet {
  background-color: #01b0df;
}

/** Arrow Component  */
.arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 767px) {
  .arrows {
    margin-top: 12px;
  }
}
.arrows button {
  margin-right: 12px;
}
.arrows button:nth-last-child(1) {
  margin-right: 0px;
}
.arrows__btn {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  background-color: #fff;
}
.arrows__btn:hover {
  color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  background-color: #01b0df;
  border-color: transparent;
}

/** Buttons Component  */
.button {
  position: relative;
  font-size: 12px;
  line-height: 15.6px;
  font-weight: 600;
  padding: 10px 24px;
  border-radius: 43px;
  color: #fff !important;
  background-color: #01b0df;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: capitalize;
}
.button span {
  margin-left: 12px;
}
.button:hover {
  background-color: #026168;
}
.button--md,
.button--lg {
  font-weight: 600;
}
.button--md {
  font-size: 14px;
  line-height: 16.8px;
  padding: 14px 32px;
}
.button--md:hover {
  color: #fff;
}
.button--lg {
  font-size: 16px;
  line-height: 19.2px;
  padding: 16px 40px;
}
.button--outline {
  color: #01b0df;
  background-color: transparent;
  border: 2px solid #01b0df;
}
.button--outline:hover {
  color: #2c742f;
  border-color: #2c742f;
  background-color: transparent;
}
.button--disable {
  color: #01b0df;
  background: #56ac591a;
  pointer-events: none;
}
.button--disable:hover {
  color: #2c742f;
  background: #2c742f33;
}

.action-btn {
  width: 40px;
  height: 40px;
  margin: 0 auto !important;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #1a1a1a;
  background-color: #f2f2f2;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.action-btn:hover {
  color: #fff;
  background-color: #01b0df;
  border-color: transparent;
}

.tag {
  color: #fff;
  padding: 3px 8px;
  border-radius: 4px;
  text-transform: capitalize;
}
.tag.danger {
  background-color: #ea4b48;
}
.tag.blue {
  background-color: #2388ff;
}

@media (max-width: 767px) {
  .button--lg {
    padding: 16px 20px;
  }
}
/** Brands Component  */
.brand-name__icon {
  display: inline-block;
  width: 100%;
  border-right: 1px solid #e6e6e6;
  color: #cccccc;
  text-align: center;
  cursor: pointer;
}
.brand-name__icon:hover {
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
  color: #01b0df;
}
.brand-name .swiper-wrapper .swiper-slide:nth-last-child(1) {
  border-right: 0px !important;
}

/** Form Component  */
.form-wrapper {
  position: relative;
  width: 520px;
  margin: auto;
  background-color: #fff;
  border: 1px solid #f2f2f2;
  -webkit-box-shadow: 0px 0px 56px 0px #00260314;
  box-shadow: 0px 0px 56px 0px #00260314;
  overflow: hidden;
  padding: 24px;
}
@media (max-width: 767px) {
  .form-wrapper {
    width: 100%;
  }
}
.form-wrapper h6 {
  text-align: center;
  margin-bottom: 20px;
  color: #1a1a1a;
}
@media (max-width: 767px) {
  .form-wrapper h6 {
    font-size: 28px;
    line-height: 38px;
  }
}
.form-input {
  position: relative;
  width: 100%;
  height: 49px;
  overflow: hidden;
  margin-bottom: 12px;
}
.form-input.filled input {
  color: #666666;
}
.form-input input {
  width: 100%;
  height: inherit;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  padding-left: 16px;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
}
.form-input input::-webkit-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.form-input input:-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.form-input input::-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.form-input input::placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.form-input input:hover,
.form-input input:focus {
  border: 1px solid #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.form-input .icon {
  position: absolute;
  top: 50%;
  right: 16px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.form-input .icon-warning,
.form-input .icon-error,
.form-input .icon-success {
  display: none;
}
.form-input.error .icon-eye,
.form-input.warning .icon-eye,
.form-input.success .icon-eye {
  display: none;
}
.form-input.active {
  border: 1px solid #01b0df;
}
.form-input.active .icon-eye {
  display: block;
}
.form-input.warning {
  border: 1px solid #ff8a00;
}
.form-input.warning .icon-warning {
  display: block;
}
.form-input.warning .icon-eye {
  display: none;
}
.form-input.error {
  border: 1px solid #ea4b48;
}
.form-input.error .icon-error {
  display: block;
}
.form-input.success {
  border: 1px solid #01b0df;
  background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(32, 181, 38, 0.05)),
      to(rgba(32, 181, 38, 0.05))
    ),
    -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(#ffffff));
  background: linear-gradient(
      0deg,
      rgba(32, 181, 38, 0.05),
      rgba(32, 181, 38, 0.05)
    ),
    linear-gradient(0deg, #ffffff, #ffffff);
}
.form-input.success input {
  background-color: inherit;
}
.form-input.success .icon-success {
  display: block;
}
.form-wrapper__content .form-check label,
.form-wrapper__content a,
.form-register {
  font-size: 14px;
  line-height: 28px;
  color: #666666;
  font-weight: 400;
  text-transform: capitalize;
}
.form-wrapper__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.form-wrapper__content .form-check {
  margin-bottom: 0px;
}
.form-wrapper__content .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-wrapper__content .form-check-input:checked {
  background-color: #01b0df;
  border-color: #01b0df;
}
.form-wrapper__content a:hover {
  color: #01b0df;
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.form-button button {
  margin-top: 20px;
}
.form-register {
  text-align: center;
  margin-top: 24px;
}
.form-register a {
  color: #1a1a1a;
  font-weight: 500;
}
.form-register a:hover {
  color: #01b0df;
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
}

/** Cards Component  */
.cards-sm,
.cards-sm--two,
.cards-md,
.cards-lg,
.cards-blog,
.cards-tm,
.cards-mm,
.cards-tm-2__top,
.dashboard-card,
.template-card {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  overflow: hidden;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cards-sm:hover,
.cards-sm--two:hover,
.cards-md:hover,
.cards-lg:hover,
.cards-blog:hover,
.cards-tm:hover,
.cards-mm:hover,
.cards-tm-2__top:hover,
.dashboard-card:hover,
.template-card:hover {
  border: 1px solid #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-box-shadow: 0px 0px 12px 0px #20b52652;
  box-shadow: 0px 0px 12px 0px #20b52652;
}
.cards-sm:hover h5,
.cards-sm:hover h6,
.cards-sm--two:hover h5,
.cards-sm--two:hover h6,
.cards-md:hover h5,
.cards-md:hover h6,
.cards-lg:hover h5,
.cards-lg:hover h6,
.cards-blog:hover h5,
.cards-blog:hover h6,
.cards-tm:hover h5,
.cards-tm:hover h6,
.cards-mm:hover h5,
.cards-mm:hover h6,
.cards-tm-2__top:hover h5,
.cards-tm-2__top:hover h6,
.dashboard-card:hover h5,
.dashboard-card:hover h6,
.template-card:hover h5,
.template-card:hover h6 {
  color: #01b0df;
}
.cards-sm:hover .cards-md__info-right .action-btn,
.cards-sm--two:hover .cards-md__info-right .action-btn,
.cards-md:hover .cards-md__info-right .action-btn,
.cards-lg:hover .cards-md__info-right .action-btn,
.cards-blog:hover .cards-md__info-right .action-btn,
.cards-tm:hover .cards-md__info-right .action-btn,
.cards-mm:hover .cards-md__info-right .action-btn,
.cards-tm-2__top:hover .cards-md__info-right .action-btn,
.dashboard-card:hover .cards-md__info-right .action-btn,
.template-card:hover .cards-md__info-right .action-btn {
  color: #fff;
  background-color: #01b0df;
}

.cards-sm {
  text-align: center;
  padding: 0px 0px 24px 0px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.cards-sm__img-wrapper,
.cards-sm__icon-wrapper {
  width: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
}
.cards-sm__img-wrapper img,
.cards-sm__icon-wrapper img {
  width: inherit;
  height: inherit;
  -o-object-fit: contain;
  object-fit: contain;
}
.cards-sm__img-wrapper {
  height: 130px;
}
.cards-sm__icon-wrapper {
  height: 80px;
}
.cards-sm h5 {
  margin-top: 16px;
  color: #1a1a1a;
}
.cards-sm .quantity {
  font-size: 14px;
  line-height: 21px;
  color: #808080;
  font-weight: 400;
  margin-top: 6px;
}

.cards-sm--two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (min-width: 400px) and (max-width: 1399px) {
  .cards-sm--two {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.cards-sm--two.radius--6 {
  border-radius: 6px;
}
.cards-sm--two__img-wrapper {
  width: 100%;
  height: 112px;
  margin-right: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}
@media (min-width: 400px) and (max-width: 1399px) {
  .cards-sm--two__img-wrapper {
    margin-right: 0px;
    height: 190px;
  }
}
.cards-sm--two__img-wrapper img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
@media (max-width: 1399px) {
  .cards-sm--two__img-wrapper img {
    -o-object-fit: contain;
    object-fit: contain;
  }
}
@media (min-width: 400px) and (max-width: 1399px) {
  .cards-sm--two__content-text {
    width: 100%;
    padding: 16px;
  }
}
.cards-sm--two__action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cards-sm--two__action .action-btn {
  margin-right: 12px;
}
.cards-sm--two .slide {
  height: 50px;
  overflow: hidden;
}
.cards-sm--two .slide--top .slide-item {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cards-sm--two:hover .slide--top .slide-item:nth-child(1) {
  -webkit-transform: translateY(-46px);
  transform: translateY(-46px);
}
.cards-sm--two:hover .slide--top .slide-item:nth-child(2) {
  -webkit-transform: translateY(-46px);
  transform: translateY(-46px);
}

.cards-sm--three {
  padding-top: 24px;
}

.cards-md:hover {
  z-index: 3;
}
.cards-md:hover .cards-md__info-left h6 {
  color: #01b0df;
}
.cards-md:hover .cards-md__info-right .action-btn {
  cursor: pointer;
}
.cards-md:hover .cards-md__favs-list {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
  cursor: pointer;
}
.cards-md__img-wrapper {
  position: relative;
}
.cards-md__img-wrapper a {
  padding: 5px;
  width: 100%;
  height: 230px;
  overflow: hidden;
}
.cards-md__img-wrapper a img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.cards-md__img-wrapper .tag {
  position: absolute;
  top: 16px;
  left: 16px;
}
.cards-md__info {
  padding: 12px;
  position: relative;
}
.cards-md__info-left h6 {
  color: #4d4d4d;
}
.cards-md__info-left span {
  color: #1a1a1a;
}
.cards-md__info-left del {
  color: #999999;
}
.cards-md__favs-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 16px;
  right: 16px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.cards-md__favs-list .action-btn {
  margin-bottom: 8px !important;
  cursor: pointer;
}
@media (max-width: 767px) {
  .cards-md--two .cards-md__img-wrapper a img,
  .cards-md--three .cards-md__img-wrapper a img,
  .cards-md--four .cards-md__img-wrapper a img {
    -o-object-fit: contain !important;
    object-fit: contain !important;
  }
}
.cards-md--two .cards-md__img-wrapper {
  padding: 0;
}
.cards-md--two .cards-md__img-wrapper a {
  width: 100%;
  height: 246px;
}
.cards-md--two .cards-md__img-wrapper a img {
  -o-object-fit: cover;
  object-fit: cover;
}
.cards-md--three .cards-md__img-wrapper {
  padding: 0;
  height: auto;
  margin-bottom: 16px;
}
.cards-md--three .cards-md__img-wrapper a {
  width: 100%;
  height: 312px;
}
.cards-md--three .cards-md__img-wrapper a img {
  -o-object-fit: cover;
  object-fit: cover;
}
.cards-md--three .cards-md__info {
  padding: 20px;
  padding-top: 0px;
}
.cards-md--four .cards-md__img-wrapper {
  padding: 0;
  height: auto;
  margin-bottom: 16px;
}
.cards-md--four .cards-md__img-wrapper a {
  width: 100%;
  height: 300px;
}
.cards-md--four .cards-md__img-wrapper a img {
  -o-object-fit: cover;
  object-fit: cover;
}
.cards-md--four .cards-md__info {
  padding: 20px;
  padding-top: 0px;
}

.cards-lg:hover {
  z-index: 3;
}
.cards-lg__img-wrapper {
  position: relative;
  width: 100%;
  height: 446px;
  overflow: hidden;
}
/*
  .cards-lg__img-wrapper img {
    width: inherit;
    height: inherit;
    -o-object-fit: cover;
       object-fit: cover; }
*/

.cards-lg__img-wrapper a {
  width: inherit;
  height: 100%;
  padding: 5px;
  overflow: hidden;
}
.cards-lg__img-wrapper a img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.cards-lg__img-wrapper .tag-group {
  position: absolute;
  top: 16px;
  left: 16px;
}
.cards-lg__group-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  bottom: 24px;
  left: 50%;
  width: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.cards-lg__group-action button:nth-child(2) {
  margin: 0px 8px;
}
.cards-lg .tag-group span {
  margin-right: 8px;
}
.cards-lg__info {
  padding: 24px;
  padding-top: 0px;
}
.cards-lg__info-price {
  margin: 9px 0px;
}
.cards-lg__info-price span {
  color: #1a1a1a;
}
.cards-lg__info-price del {
  color: #999999;
}
.cards-lg__info-rating li svg {
  width: 15px;
  height: 15px;
}
.cards-lg__info-rating li span {
  font-size: 12px;
  line-height: 15.6px;
  margin-left: 5px;
  color: #808080;
}
.cards-lg__info-countdown {
  margin-top: 20px;
}
.cards-lg__info-countdown h6 {
  color: #999999;
  line-height: 18px;
  margin-bottom: 6px;
}
.cards-lg__favs-list {
  position: absolute;
  top: 10px;
  right: 16px;
}
.cards-lg__favs-list .action-btn {
  margin-bottom: 15px;
}

.cards-ig__img {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.cards-ig__img img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cards-ig__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background: #2b572ecc;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cards-ig__overlay span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: inherit;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
}
.cards-ig:hover .cards-ig__img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cards-ig:hover .cards-ig__overlay {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

.cards-blog {
  border-radius: 8px;
  overflow: hidden;
  background-color: transparent;
}
.cards-blog:hover {
  border-color: transparent;
  -webkit-box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.1);
}
.cards-blog:hover .cards-blog__img-wrapper img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cards-blog:hover .cards-blog__img-wrapper .date {
  background-color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cards-blog:hover .cards-blog__info {
  color: #2c742f;
}
.cards-blog:hover .cards-blog__info .blog-title {
  color: #2c742f;
}
.cards-blog:hover .cards-blog__play-icon {
  color: #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cards-blog__img-wrapper {
  width: 100%;
  height: 325px;
  overflow: hidden;
  position: relative;
}
.cards-blog__img-wrapper img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cards-blog__img-wrapper .date {
  position: absolute;
  left: 24px;
  bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 58px;
  height: 58px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.85);
}
.cards-blog__img-wrapper .date h3 {
  color: #1a1a1a;
}
.cards-blog__img-wrapper .date span {
  color: #808080;
  text-transform: uppercase;
}
.cards-blog__play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 20px 0px #00000033;
  box-shadow: 0px 0px 20px 0px #00000033;
  color: #2c742f;
}
.cards-blog__info {
  background: #fff;
  padding: 24px;
}
.cards-blog__info-tags-item {
  font-size: 14px;
  line-height: 21px;
  color: #4d4d4d;
  font-weight: 400;
  margin-right: 15px;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cards-blog__info-tags-item span {
  color: #b2b2b2;
  margin-right: 8px;
}
.cards-blog__info a {
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 600;
  color: #01b0df;
  margin-top: 12px;
}
.cards-blog__info a span {
  margin-left: 12px;
}
.cards-blog__info .blog-title {
  margin: 8px 0px;
  color: #1a1a1a;
  font-weight: 500;
  font-size: 18px;
}
.cards-blog--two {
  padding: 24px;
  border-radius: 8px;
  background-color: #fff;
}
.cards-blog--two .cards-blog__img-wrapper {
  width: 100%;
  height: 275px;
  border-radius: 4px;
  margin-bottom: 16px;
}
.cards-blog--two .cards-blog__img-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.cards-blog--two .cards-blog__info {
  padding: 0px;
}
.cards-blog--two .cards-blog__info p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #618062;
}
.cards-blog--two .cards-blog__info a {
  margin-top: 20px;
  color: #01b0df;
}

.cards-mb {
  border: 1px solid #e5e5e5;
}
.cards-mb:hover {
  border-color: transparent;
  -webkit-box-shadow: 0px 20px 48px 0px #00260314;
  box-shadow: 0px 20px 48px 0px #00260314;
}
.cards-mb:hover .cards-mb__img-wrapper img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.cards-mb:hover .cards-mb__img-wrapper::after {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.cards-mb:hover .cards-mb__img-wrapper--social {
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
  transform: translateX(-50%) translateY(-50%) scale(1);
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.cards-mb__img-wrapper {
  width: 100%;
  height: 280px;
  overflow: hidden;
  position: relative;
}
.cards-mb__img-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000080;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: all 0.6s cubic-bezier(1, 0, 0.46, 0.76);
  transition: all 0.6s cubic-bezier(1, 0, 0.46, 0.76);
}
.cards-mb__img-wrapper img {
  width: 100%;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.cards-mb__img-wrapper--social {
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%) scale(0.1);
  transform: translateX(-50%) scale(0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: 0.3s all cubic-bezier(0.35, 0.61, 0.24, 0.82);
  transition: 0.3s all cubic-bezier(0.35, 0.61, 0.24, 0.82);
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}
.cards-mb__img-wrapper--social span {
  margin-right: 8px;
}
.cards-mb__img-wrapper--social span a {
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.cards-mb__img-wrapper--social span a:hover {
  background-color: #01b0df;
}
.cards-mb__designation {
  background-color: #fff;
  padding: 16px 20px;
}
.cards-mb__designation h5 {
  color: #1a1a1a;
  text-transform: capitalize;
}
.cards-mb__designation span {
  color: #808080;
  text-transform: capitalize;
}

.cards-ss {
  position: relative;
}
.cards-ss__img-wrapper {
  position: relative;
}
.cards-ss__img-wrapper img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.cards-ss--small-one .cards-ss__img-wrapper {
  width: 100%;
  height: 348px;
  overflow: hidden;
}
.cards-ss--small-one .cards-ss__img-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f6f4f2),
    color-stop(63.13%, rgba(246, 244, 242, 0))
  );
  background: linear-gradient(
    180deg,
    #f6f4f2 0%,
    rgba(246, 244, 242, 0) 63.13%
  );
}
.cards-ss--small-one .cards-ss__content p {
  color: #01b0df;
}
.cards-ss--small-two .cards-ss__img-wrapper {
  width: 100%;
  height: 416px;
  border-radius: 8px;
  overflow: hidden;
}
.cards-ss--small-two .cards-ss__img-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0.3)),
    color-stop(58.4%, rgba(0, 0, 0, 0)),
    color-stop(58.4%, rgba(255, 255, 255, 0))
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(0, 0, 0, 0) 58.4%,
    rgba(255, 255, 255, 0) 58.4%
  );
}
.cards-ss--small-two .overlay-img {
  position: absolute;
  bottom: -10px;
  right: -45px;
  z-index: -1;
}
@media (max-width: 767px) {
  .cards-ss--small-two .overlay-img {
    right: auto;
    left: 0px;
    bottom: auto;
    top: -55px;
  }
}
.cards-ss--small .cards-ss__content h6,
.cards-ss--small .cards-ss__content .h6 {
  font-size: 12px;
  line-height: 12px;
}
.cards-ss--darktext .cards-ss__content h6,
.cards-ss--darktext .cards-ss__content .h6,
.cards-ss--darktext .cards-ss__content h2,
.cards-ss--darktext .cards-ss__content .h2 {
  color: #1a1a1a;
}
.cards-ss--darktext .cards-ss__saleoff p {
  color: #1a1a1a;
}
.cards-ss--md {
  border-radius: 8px;
  overflow: hidden;
}
.cards-ss--md__img-wrapper {
  position: relative;
  width: 100%;
  height: 255px;
  overflow: hidden;
  z-index: 1;
}
.cards-ss--md__img-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    114.4deg,
    rgba(0, 0, 0, 0.25) 8.91%,
    rgba(0, 0, 0, 0) 80.44%
  );
}
.cards-ss--md__img-wrapper img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.cards-ss--md__text-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
}
.cards-ss--md__text-content h5 {
  text-transform: uppercase;
}
.cards-ss--md__text-content h2 {
  width: 185px;
}
.cards-ss--md__text-content .button {
  background-color: #fff;
  color: #01b0df;
}
.cards-ss--md-one .cards-ss--md__text-content,
.cards-ss--md-three .cards-ss--md__text-content {
  left: 24px;
}
.cards-ss--md-two .cards-ss--md__text-content {
  right: 32px;
}
@media (max-width: 767px) {
  .cards-ss--md-two .cards-ss--md__text-content {
    right: auto;
    left: 24px;
  }
}
.cards-ss--md-one .cards-ss--md__text-content h2 {
  color: #fff;
  margin-bottom: 8px;
}
.cards-ss--md-one .cards-ss--md__text-content p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 16px;
}
.cards-ss--md-one .cards-ss--md__text-content p span {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #fff;
}
.cards-ss--md-two .cards-ss--md__text-content h5,
.cards-ss--md-two .cards-ss--md__text-content h2,
.cards-ss--md-three .cards-ss--md__text-content h5,
.cards-ss--md-three .cards-ss--md__text-content h2 {
  color: #002603;
}
.cards-ss--md-two .cards-ss--md__text-content h2,
.cards-ss--md-three .cards-ss--md__text-content h2 {
  margin-bottom: 20px;
}
.cards-ss--md-two .cards-ss--md__text-content h5,
.cards-ss--md-three .cards-ss--md__text-content h5 {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 8px;
  text-transform: uppercase;
}
.cards-ss--lg .cards-ss__img-wrapper {
  width: 100%;
  height: 536px;
  border-radius: 8px;
  overflow: hidden;
}
.cards-ss__content {
  position: absolute;
  top: 35px;
  left: 50%;
  width: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #fff;
  z-index: 1;
}
@media (min-width: 400px) and (max-width: 1399px) {
  .cards-ss__content {
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
.cards-ss__content h6 {
  letter-spacing: 0.03em;
  margin-bottom: 12px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .cards-ss__content h6 {
    margin-bottom: 0px;
  }
}
.cards-ss__content h2 {
  margin-bottom: 8px;
}
@media (max-width: 991px) {
  .cards-ss__content h2 {
    font-size: 28px;
    line-height: 38px;
  }
}
.cards-ss__content a.button {
  margin-top: 24px;
  background-color: #fff;
  color: #01b0df !important;
  text-transform: capitalize;
}
.cards-ss__content a.button:hover {
  background-color: #f2f2f2;
}
.cards-ss__startpackage p {
  font-size: 18px;
  line-height: 27px;
  color: #fff;
}
.cards-ss__startpackage span {
  color: #ff8a00;
}
.cards-ss__saleoff p {
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
}
.cards-ss__saleoff p span {
  color: #fcc900;
  font-weight: 600;
  text-transform: uppercase;
  padding: 6px 12px;
  margin-left: 12px;
  border-radius: 5px;
  display: inline-block;
  background-color: #1a1a1a;
}
.cards-ss__save p {
  font-size: 32px;
  line-height: 38.4px;
  color: #1a1a1a;
  font-weight: 600;
  text-transform: capitalize;
  width: 80%;
  margin: auto;
}
.cards-ss__save p span {
  font-weight: 400;
}

.cards-tm {
  border: 0px;
  padding: 24px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 10px 20px 0px #00000003;
  box-shadow: 0px 10px 20px 0px #00000003;
}
.cards-tm:hover {
  border: 0px;
  -webkit-box-shadow: 0px 10px 20px 0px #00000003;
  box-shadow: 0px 10px 20px 0px #00000003;
}
.cards-tm__text {
  color: #4d4d4d;
  margin-top: 42px;
  margin-bottom: 12px;
}
.cards-tm__info--user-img {
  width: 56px;
  height: 56px;
  border-radius: 100%;
  margin-right: 12px;
  overflow: hidden;
}
.cards-tm__info--user-img img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.cards-tm__info-left--designation h5 {
  color: #1a1a1a;
}
.cards-tm__info-left--designation span {
  color: #999999;
}
.cards-tm__info-right ul li span svg {
  width: 18px;
  height: 18px;
}
.cards-tm .quotes {
  position: absolute;
  top: 24px;
  left: 24px;
  color: #01b0df;
}

.cards-tm-2 {
  position: relative;
  overflow: hidden;
}
.cards-tm-2__top {
  position: relative;
  padding: 32px;
  text-align: center;
  border-radius: 10px;
  overflow: visible;
}
.cards-tm-2__top::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 12px 12px 0 12px;
  border-color: #ffffff transparent transparent transparent;
  z-index: 1;
}
.cards-tm-2__top:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #e5e5e5;
}
.cards-tm-2__top .quotes {
  position: absolute;
  top: 32px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #01b0df;
}
.cards-tm-2__text {
  margin-top: 50px;
  margin-bottom: 0px;
  color: #406b42;
}
.cards-tm-2__bottom {
  margin-top: 28px;
  text-align: center;
}
.cards-tm-2__user {
  width: 64px;
  height: 64px;
  margin: auto;
  margin-bottom: 14px;
  border-radius: 50%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cards-tm-2__user img {
  width: inherit;
  height: inherit;
}
.cards-tm-2__designation h5 {
  color: #002603;
}
.cards-tm-2__designation span {
  color: #7a997c;
}

.cards-ship {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 24px;
  background-color: #fff;
  -webkit-box-shadow: 0px 8px 40px 0px #00260314;
  box-shadow: 0px 8px 40px 0px #00260314;
}
@media (max-width: 1199px) {
  .cards-ship {
    gap: 0px;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .cards-ship {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .cards-ship {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 0;
  }
}
.cards-ship__item {
  height: 100%;
}
@media (max-width: 767px) {
  .cards-ship__item {
    width: 100% !important;
    padding: 24px !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
}
.cards-ship__item-process-info h2 {
  color: #1a1a1a;
}
.cards-ship__item-process-info p {
  color: #999999;
}
.cards-ship__item-icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.cards-ship--one .cards-ship__item,
.cards-ship--two .cards-ship__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cards-ship--one .cards-ship__item-icon,
.cards-ship--two .cards-ship__item-icon {
  margin-right: 16px;
}
.cards-ship--one,
.cards-ship--three {
  border-radius: 8px;
}
.cards-ship--two {
  -webkit-box-shadow: 0px -0.5px 0px 0px #cccccc inset;
  box-shadow: 0px -0.5px 0px 0px #cccccc inset;
}
.cards-ship--three {
  -webkit-box-shadow: 0px 16px 48px 0px #0e6b1514;
  box-shadow: 0px 16px 48px 0px #0e6b1514;
  border: 1px solid #e5e5e5;
}
.cards-ship--four {
  -webkit-box-shadow: 0px -0.5px 0px 0px #cccccc inset;
  box-shadow: 0px -0.5px 0px 0px #cccccc inset;
}
.cards-ship--one {
  padding: 40px 0px;
}
.cards-ship--one .cards-ship__item {
  padding: 0px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (max-width: 1199px) {
  .cards-ship--one .cards-ship__item {
    padding: 24px 0px;
  }
}
.cards-ship--two {
  padding-top: 0px;
  padding-bottom: 0px;
}
@media (max-width: 1199px) {
  .cards-ship--two {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.cards-ship--two .cards-ship__item {
  padding: 40px 0px;
  position: relative;
}
@media (max-width: 767px) {
  .cards-ship--two .cards-ship__item {
    padding: 30px 0px;
  }
}
.cards-ship--two .cards-ship__item:hover .cards-ship__item-icon {
  color: #fff;
  background-color: #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cards-ship--two .cards-ship__item:hover::after {
  height: 2px;
  background-color: #01b0df;
}
.cards-ship--two .cards-ship__item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cards-ship--two .cards-ship__item-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #edf2ee;
  color: #01b0df;
}
.cards-ship--two .cards-ship__item-process-info h2 {
  font-size: 18px;
  line-height: 27px;
  white-space: nowrap;
}
.cards-ship--three {
  margin-bottom: 0px;
  gap: 0;
}
.cards-ship--three .cards-ship__item {
  border-right: 1px solid #dae5da;
  padding: 40px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
@media (max-width: 1199px) {
  .cards-ship--three .cards-ship__item {
    border-bottom: 1px solid #dae5da;
  }
}
@media (max-width: 767px) {
  .cards-ship--three .cards-ship__item {
    padding: 30px;
    text-align: center;
    border-right: 1px solid #dae5da;
  }
}
.cards-ship--three .cards-ship__item:hover {
  background-color: #01b0df;
}
.cards-ship--three .cards-ship__item:hover .cards-ship__item-icon {
  background-color: #fff;
  border-color: transparent;
  color: #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cards-ship--three .cards-ship__item:hover .cards-ship__item-process-info h2,
.cards-ship--three .cards-ship__item:hover .cards-ship__item-process-info .h2 {
  color: #fff;
}
.cards-ship--three .cards-ship__item:hover .cards-ship__item-process-info p {
  color: #fff;
}
.cards-ship--three .cards-ship__item-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  border: 1px solid #dae5da;
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .cards-ship--three .cards-ship__item-icon {
    margin: auto;
    margin-bottom: 16px;
  }
}
.cards-ship--three .cards-ship__item-process-info h2 {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 8px;
}
.cards-ship--three .cards-ship__item-process-info p {
  font-size: 14px;
  line-height: 21px;
}
.cards-ship--four {
  border: 0px;
  padding: 0px;
}
@media (max-width: 767px) {
  .cards-ship--four {
    border-bottom: 0px;
  }
}
.cards-ship--four .cards-ship__item {
  text-align: center;
  padding: 40px;
  position: relative;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
@media (max-width: 767px) {
  .cards-ship--four .cards-ship__item {
    padding: 40px 20px;
    border-bottom: 1px solid #cccccc;
  }
}
.cards-ship--four .cards-ship__item:hover::after {
  height: 4px;
  background-color: #01b0df;
  bottom: -1px;
}
.cards-ship--four .cards-ship__item:hover .cards-ship__item-icon {
  color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cards-ship--four
  .cards-ship__item:hover
  .cards-ship__item-icon
  .background
  svg {
  color: #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cards-ship--four .cards-ship__item::after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cards-ship--four .cards-ship__item-icon {
  display: inline-block;
  margin-bottom: 16px;
  color: #01b0df;
  position: relative;
  width: 72px;
  height: 72px;
}
.cards-ship--four .cards-ship__item-icon .background {
  position: relative;
}
.cards-ship--four .cards-ship__item-icon .background svg {
  width: 72px;
  height: 72px;
  color: #d9f4da;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cards-ship--four .cards-ship__item-icon .background,
.cards-ship--four .cards-ship__item-icon .forground {
  position: absolute;
  top: 0;
  left: 0;
}
.cards-ship--four .cards-ship__item-icon .forground {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.cards-ship--four .cards-ship__item-process-info h2 {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .cards-lg__favs-list {
    display: none;
  }
}
@media (max-width: 767px) {
  .cards-ss__content {
    top: 15%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .cards-ss--lg .cards-ss__img-wrapper,
  .cards-ss--small-two .cards-ss__img-wrapper {
    height: 350px;
  }

  .cards-lg__group-action {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .cards-lg__group-action .action-btn {
    display: none;
  }

  .cards-blog__img-wrapper .date {
    left: 12px;
  }

  .cards-blog__info {
    padding: 24px 12px;
  }
}
@media (max-width: 380px) {
  .cards-blog__info-tags {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .cards-blog__info-tags-item {
    margin-right: 0px;
  }
  .cards-blog__info-tags-item span {
    margin-right: 2px;
  }
}
.dashboard-card {
  padding: 32px;
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.bill-card {
  position: relative;
  width: 100%;
  padding: 24px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .bill-card {
    padding: 16px;
  }
}
.bill-card__content {
  margin-bottom: 24px;
}
.bill-card__content:nth-last-child(1) {
  margin-bottom: 0px;
}
.bill-card__header {
  margin-bottom: 12px;
}
.bill-card__product {
  margin-bottom: 12px;
}
.bill-card__product-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.bill-card__product-item-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.bill-card__product-item-content .img-wrapper {
  position: relative;
  width: 60px;
  height: 60px;
  overflow: hidden;
  margin-right: 6px;
}
.bill-card__product-item-content .img-wrapper img {
  width: inherit;
  height: inherit;
}
.bill-card__memo-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 0px;
  border-bottom: 1px solid #e5e5e5;
}
.bill-card__memo-item:nth-last-child(1) {
  border-bottom: 0px;
}
.bill-card__memo-item p {
  color: #4d4d4d;
  text-transform: capitalize;
}
.bill-card__memo-item span {
  color: #1a1a1a;
  text-transform: capitalize;
}
.bill-card__payment-method {
  margin-bottom: 24px;
}
.bill-card__payment-method-item {
  margin-bottom: 12px;
}
.bill-card__payment-method-item:nth-last-child(1) {
  margin-bottom: 0px;
}

.template-card {
  padding: 40px;
  text-align: center;
}
@media (max-width: 991px) {
  .template-card {
    margin-bottom: 24px;
  }
}
.template-card .icon-box {
  margin-bottom: 25px;
}

.shoping-card {
  position: relative;
  padding: 24px 0px;
  text-align: center;
  border: 1px solid #e5e5e5;
  overflow: hidden;
}
.shoping-card .close-btn {
  position: absolute;
  top: 21px;
  right: 21px;
}
.shoping-card .counter-btn-wrapper {
  margin: auto;
  margin-bottom: 12px;
}
.shoping-card__img-wrapper {
  width: 120px;
  height: 120px;
  margin: auto;
  margin-bottom: 18px;
}
.shoping-card__product-caption {
  color: #1a1a1a;
  margin-bottom: 18px;
}
.shoping-card__product-caption .tag {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  margin-left: 8px;
}
.shoping-card__product-caption .tag--in {
  color: #2c742f;
  background: #20b52633;
}
.shoping-card__product-caption .tag--out {
  color: #ea4b48;
  background: #ea4b4833;
}
.shoping-card__product-price {
  color: #1a1a1a;
  margin-bottom: 16px;
}
.shoping-card__product-price .prev-price {
  color: #999999;
  font-weight: 400;
}

/** Countdown Component  */
#countdown .timer-body-block .table-cell .tab-val {
  font-size: 24px;
  line-height: 36px;
  color: #01b0df;
  font-weight: 400;
}
@media (max-width: 767px) {
  #countdown .timer-body-block .table-cell .tab-val {
    font-size: 18px;
    line-height: 28px;
  }
}
#countdown .timer-body-block .table-cell .tab-unit {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
  font-weight: 400;
  color: #999999;
}

#countdownTwo .timer-body-block {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
#countdownTwo .timer-body-block .table-cell::after {
  top: 0px;
  right: -12px;
  color: #fff;
}
#countdownTwo .timer-body-block .table-cell .tab-val {
  font-size: 24px;
  line-height: 36px;
}
#countdownTwo .timer-body-block .table-cell .tab-unit {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
}

.banner-sale__countdown .timer-body-block {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.info-countdown__card .timer-body-block {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.info-countdown__card .timer-body-block .table-cell .tab-val {
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  color: #1a1a1a;
}
.info-countdown__card .timer-body-block .table-cell .tab-unit {
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.03em;
  color: #999999;
  font-weight: 500;
}

/** Accordion Component  */
.accordion-button {
  position: relative;
  outline: none;
}
.accordion-button:active,
.accordion-button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.accordion-button::after {
  display: none;
}

.faq .accordion-item {
  margin-bottom: 12px;
}
.faq .accordion-button,
.faq .accordion-body {
  border: 1px solid #84d187;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.faq .accordion-body {
  border-top: 0px;
}
.faq .accordion-button,
.faq .accordion-body {
  padding: 16px;
}
.faq .accordion-button {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 16px;
  background-color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-transform: capitalize;
  color: #1a1a1a;
  text-align: left;
  position: relative;
}
.faq .accordion-button.collapsed {
  border-radius: 6px;
  border: 0px;
  background-color: #f2f2f2;
}
.faq .accordion-button.collapsed .icon {
  color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.faq .accordion-button .icon {
  margin-left: auto;
  color: #f2f2f2;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.faq .accordion-body {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #666666;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

/** Social-icons Component  */
.social-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.social-icon-link {
  margin-right: 8px;
}
.social-icon-link a {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #b3b3b3;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.social-icon-link a:hover {
  color: #fff;
  background-color: #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.social-icon-link a svg {
  width: 18px;
}

/** Pagination Component  */
.pagination-item {
  margin-right: 8px;
}
.pagination-item:nth-last-child(1) {
  margin-right: 0px;
}
.pagination-item:first-child .pagination-link,
.pagination-item:last-child .pagination-link {
  border-radius: 50%;
  color: #1a1a1a;
}
.pagination-item:nth-last-child(2),
.pagination-item:nth-last-child(3),
.pagination-item:nth-last-child(4),
.pagination-item:nth-last-child(5) {
  display: none;
}
.pagination-item:hover a {
  color: #fff;
}
.pagination-item.disabled .pagination-link,
.pagination-item .pagination-link.disabled,
.pagination-item .pagination-link:disabled {
  background-color: #f2f2f2;
  border-color: transparent;
  color: #b2b2b2;
}
.pagination-item.disabled .pagination-link:hover,
.pagination-item .pagination-link.disabled:hover,
.pagination-item .pagination-link:disabled:hover {
  color: #b2b2b2;
}
.pagination-link {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #666666;
}
.pagination-link:hover,
.pagination-link:focus,
.pagination-link.active {
  color: #fff;
  background-color: #01b0df;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination-wrapper ul.pagination {
  margin-top: 20px;
}
.pagination-wrapper ul.pagination li .pagination-link {
  border: none;
  margin-left: 0;
}
.pagination-wrapper ul.pagination li:first-child a,
.pagination-wrapper ul.pagination li:last-child a {
  border: 1px solid #e5e5e5;
}
.pagination-wrapper ul.pagination li:first-child a:hover,
.pagination-wrapper ul.pagination li:last-child a:hover {
  border-color: #01b0df;
  color: white;
}
.pagination-wrapper ul.pagination li:first-child a {
  margin-right: 12px;
}
.pagination-wrapper ul.pagination li:last-child a {
  margin-left: 12px;
}

/** Counter Component  */
.timer-body-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.timer-body-block .table-cell {
  margin-right: 22px;
  color: #fff;
  position: relative;
  text-align: center;
}
.timer-body-block .table-cell:nth-last-child(1)::after {
  display: none;
}
.timer-body-block .table-cell::after {
  content: ":";
  position: absolute;
  top: 0;
  right: -10px;
  color: #999999;
  font-size: 24px;
  line-height: 36px;
}
.timer-body-block .table-cell .tab-unit {
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

/** Navigation-card Component  */
.dashboard__nav {
  position: relative;
  border-radius: 8px;
  padding: 24px 0px;
  padding-bottom: 12px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
}
.dashboard__nav .filter-icon {
  display: none;
  position: absolute;
  top: 8px;
  right: -38px;
  background-color: #01b0df;
  padding: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
@media (max-width: 991px) {
  .dashboard__nav {
    position: fixed;
    top: 50%;
    left: -20%;
    width: 20%;
    height: auto;
    z-index: 9;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    visibility: hidden;
    z-index: 9999;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .dashboard__nav.active {
    left: 0px;
    width: 35%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    visibility: visible;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
  .dashboard__nav .filter-icon {
    display: block;
    visibility: visible;
  }
}
@media (max-width: 767px) {
  .dashboard__nav {
    width: 70%;
    left: -70%;
  }
  .dashboard__nav.active {
    width: 70%;
  }
}
.dashboard__nav-title {
  text-transform: capitalize;
  margin-bottom: 16px;
  padding: 0px 24px;
}
.dashboard__nav-item-link {
  border-left: 2px solid transparent;
}
.dashboard__nav-item-link:hover,
.dashboard__nav-item-link.active {
  background-color: #edf2ee;
  border-color: #01b0df;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.dashboard__nav-item-link:hover a,
.dashboard__nav-item-link.active a {
  color: #1a1a1a;
}
.dashboard__nav-item-link a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #666666;
  padding: 16px 24px;
}
.dashboard__nav-item-link a .icon {
  margin-right: 6px;
  width: 30px;
}
.dashboard__nav-item-link a .name {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

/** Progress-bar Component  */
.progress__bar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin: 24px;
  margin-bottom: 40px;
}
@media (max-width: 500px) {
  .progress__bar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 350px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.progress__bar .check-mark {
  display: none;
}
.progress__bar-1x .progress__bar-border-active,
.progress__bar-2x .progress__bar-border-active,
.progress__bar-3x .progress__bar-border-active,
.progress__bar-4x .progress__bar-border-active {
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.progress__bar-1x .progress__bar-border-active {
  width: 20%;
}
@media (max-width: 500px) {
  .progress__bar-1x .progress__bar-border-active {
    width: inherit;
    height: 20%;
  }
}
.progress__bar-2x .progress__bar-border-active {
  width: 35%;
}
@media (max-width: 500px) {
  .progress__bar-2x .progress__bar-border-active {
    width: inherit;
    height: 35%;
  }
}
.progress__bar-3x .progress__bar-border-active {
  width: 70%;
}
@media (max-width: 500px) {
  .progress__bar-3x .progress__bar-border-active {
    width: inherit;
    height: 70%;
  }
}
.progress__bar-4x .progress__bar-border-active {
  width: 100%;
}
@media (max-width: 500px) {
  .progress__bar-4x .progress__bar-border-active {
    width: inherit;
    height: 100%;
  }
}
.progress__bar-border {
  position: absolute;
  top: 30%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 75%;
  height: 10px;
  background-color: #f2f2f2;
  z-index: -1;
}
@media (max-width: 500px) {
  .progress__bar-border {
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 10px;
    height: 90%;
  }
}
.progress__bar-border-active {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  background-color: #01b0df;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.progress__bar-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
}
.progress__bar-item-ball {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 2px dashed #01b0df;
  background-color: #fff;
}
.progress__bar-item-ball .count-number {
  color: #01b0df;
}
.progress__bar-item h2 {
  text-transform: capitalize;
  color: #2c742f;
  margin-top: 8px;
}
.progress__bar-item.active .progress__bar-item-ball {
  background-color: #01b0df;
}
.progress__bar-item.active .progress__bar-item-ball .count-number {
  color: #fff;
  font-weight: 500;
}
.progress__bar-item.active .progress__bar-item-ball .count-number-active {
  display: none;
}
.progress__bar-item.active .progress__bar-item-ball .check-mark {
  display: block;
  color: #fff;
}
.progress__bar-item.active h2 {
  font-weight: 500;
}

/** Counter-btn Component  */
.counter-btn {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background-color: #f2f2f2;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  color: #666666;
}
.counter-btn:hover {
  color: #1a1a1a;
}
.counter-btn-wrapper {
  position: relative;
  width: 125px;
  height: 50px;
  padding: 8px;
  border-radius: 170px;
  border: 1px solid #e5e5e5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.counter-btn-counter {
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #1a1a1a;
}
.counter-btn-counter::-webkit-outer-spin-button,
.counter-btn-counter::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}
.counter-btn-counter[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

/** Range Component  */
#priceRangeSlider {
  height: 6px;
  border: 0px;
}

.noUi-target {
  margin: 50px 0px 10px;
}

.noUi-connects {
  background-color: #e6e6e6;
}

.noUi-horizontal .noUi-handle {
  width: 16px !important;
  height: 16px !important;
  border-radius: 50%;
  border: 2px solid #01b0df;
}

.noUi-handle:after,
.noUi-handle:before {
  display: none !important;
}

.noUi-touch-area {
  width: 16px !important;
}

/** 4.1 Home Page */
.popular-categories--slider .swiper-slide {
  text-align: center;
}

.instagram .cards-ig {
  margin-bottom: 24px;
}

.banner-promo--one .banner-promo__content {
  border-radius: 0px;
  height: 396px;
}

.featured .row:nth-child(1) {
  margin-bottom: 24px;
}
.featured__content-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 50px;
}
@media (max-width: 1399px) {
  .featured__content-card {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
@media (max-width: 767px) {
  .featured__content-card {
    margin-top: 60px;
  }
}
.featured__content-card-item {
  width: 245px;
  margin-right: 22px;
}
@media (max-width: 1399px) {
  .featured__content-card-item {
    width: 32%;
    margin-right: 22px;
    margin-bottom: 22px;
  }
}
@media (max-width: 1199px) {
  .featured__content-card-item {
    margin-right: 12px;
    margin-bottom: 12px;
  }
}
@media (max-width: 767px) {
  .featured__content-card-item {
    width: 48%;
  }
}
@media (max-width: 480px) {
  .featured__content-card-item {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 16px;
  }
}
.featured__content-card-item:nth-last-child(1) {
  margin-right: 0px;
}
@media (max-width: 767px) {
  .featured__content-card-item:nth-child(2),
  .featured__content-card-item:nth-child(4) {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 1399px) {
  .featured__content-card-item:nth-child(3) {
    margin-right: 0px;
  }
}
@media (max-width: 1399px) {
  .featured .cards-ss {
    margin-bottom: 24px;
  }
}
.featured .cards-sm--two {
  margin-bottom: 16px;
}
@media (max-width: 991px) {
  .featured .cards-md--two {
    margin-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .featured .cards-md--two {
    margin-bottom: 12px;
  }
}
.featured__content h2 {
  margin-bottom: 16px;
}

.members-slider--one {
  padding-bottom: 60px;
}
@media (max-width: 767px) {
  .members-slider--one {
    padding-bottom: 30px;
  }
}

.brand-name--two .brand-name-slide--one {
  padding: 60px 0px;
  border: 1px solid #e5e5e5;
}

.testimonial--two {
  padding-left: 60px;
  padding-right: 60px;
}
@media (max-width: 1200px) {
  .testimonial--two {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 767px) {
  .shipping-container {
    padding-bottom: 0px;
  }
}
.shipping-container--03 {
  position: relative;
  width: 1520px;
  margin: auto;
}
@media (max-width: 1680px) {
  .shipping-container--03 {
    width: 95%;
  }
}
.shipping-container--03::after,
.shipping-container--03::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
}
@media (max-width: 767px) {
  .shipping-container--03::after,
  .shipping-container--03::before {
    display: none;
  }
}
.shipping-container--03::before {
  top: 0;
  background-color: #edf2ee;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.shipping-container--03::after {
  bottom: 0;
  background-color: #fff;
}
.shipping-container--03 .swiper-container {
  width: 1400px;
  margin: auto;
  -webkit-box-shadow: 0px 8px 40px 0px #00260314;
  box-shadow: 0px 8px 40px 0px #00260314;
}
@media (max-width: 1680px) {
  .shipping-container--03 .swiper-container {
    width: 90%;
  }
}
@media (max-width: 767px) {
  .shipping-container--03 .swiper-container {
    margin-top: 24px;
    width: 100%;
  }
}

.category--top .container {
  position: relative;
}
.category--top .container .arrows {
  z-index: 3;
}
.category--top .container .arrows__btn {
  position: absolute;
  top: calc(50% + 30px);
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
}
.category--top .container .arrows__btn.swiper-button--prev {
  left: -40px;
}
.category--top .container .arrows__btn.swiper-button--next {
  right: -80px;
}

.testimonial--three .swiper-wrapper {
  padding-bottom: 60px;
}

.shipping-container--three {
  margin-bottom: 0px;
  position: relative;
}
@media (max-width: 991px) {
  .shipping-container--three {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .shipping-container--three {
    margin: 60px 0px;
  }
}
.shipping-container--three::after,
.shipping-container--three::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
}
@media (max-width: 767px) {
  .shipping-container--three::after,
  .shipping-container--three::before {
    display: none;
  }
}
.shipping-container--three::before {
  top: 0;
  background-color: #fff;
}
.shipping-container--three::after {
  bottom: 0;
  background-color: #edf2ee;
}
.shipping-container--three .shipping-container--slider {
  border-radius: 8px;
  border-right: 1px solid #dae5da;
}

.our-products .section__head h2 {
  margin-bottom: 24px;
}
.our-products__menu {
  border: 0px;
}
@media (max-width: 400px) {
  .our-products__menu {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: 320px;
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
.our-products__menu li {
  padding: 15px 0px;
}
@media (max-width: 767px) {
  .our-products__menu li {
    padding-bottom: 0px;
  }
}
.our-products__menu li:nth-last-child(1) button {
  border-right: 0px !important;
}
.our-products__menu li button {
  font-size: 16px;
  line-height: 24px;
  color: #808080;
  font-weight: 500;
  text-transform: capitalize;
  border: 0px !important;
  border-right: 1px solid #b4ccb4 !important;
  padding: 0px 24px;
}
@media (max-width: 767px) {
  .our-products__menu li button {
    padding: 0px;
    padding-right: 15px;
    border-color: transparent !important;
  }
}
@media (max-width: 400px) {
  .our-products__menu li button {
    white-space: nowrap;
  }
}
.our-products__menu li button.active {
  background-color: transparent !important;
  color: #01b0df !important;
  position: relative;
}
.our-products__menu li button.active::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: calc(100% - 40px);
  height: 2px;
  background-color: #01b0df;
}
@media (max-width: 767px) {
  .our-products__menu li button.active::after {
    bottom: -10px;
    left: 0px;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    width: 50%;
  }
}
.our-products__menu li button:hover {
  color: #01b0df;
}
.our-products__content-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 991px) {
  .our-products__content-items {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 767px) {
  .our-products__content-items {
    display: none;
  }
}
@media (min-width: 768px) {
  .our-products__content-slider {
    display: none;
  }
}

.banner-promo {
  position: relative;
}
.banner-promo::after,
.banner-promo::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
}
.banner-promo::after {
  top: 0;
  background-color: #edf2ee;
}
.banner-promo::before {
  bottom: 0;
  background-color: #fff;
}
.banner-promo__content {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 740px;
  overflow: hidden;
  border-radius: 20px;
}
@media (max-width: 991px) {
  .banner-promo__content {
    height: 475px;
  }
}
.banner-promo__content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 44, 2, 0.6)),
    to(rgba(0, 44, 2, 0.6))
  );
  background: linear-gradient(0deg, rgba(0, 44, 2, 0.6), rgba(0, 44, 2, 0.6));
}
.banner-promo__content img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.banner-promo__content-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
}
@media (max-width: 767px) {
  .banner-promo__content-text {
    width: 95%;
  }
}
.banner-promo__content-text span.title {
  font-size: 16px;
  line-height: 16px;
  font-family: "Segoe Script", sans-serif;
  color: #fff;
  font-weight: 400;
  margin-bottom: 12px;
  text-transform: uppercase;
}
.banner-promo__content-text h5 {
  width: 495px;
  margin: auto;
  color: #fff;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .banner-promo__content-text h5 {
    width: 100%;
    font-size: 28px;
    line-height: 38px;
  }
}
.banner-promo__content-text .play-icon {
  color: #fff;
}
.banner-promo__content-text .play-icon:hover {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: #01b0df;
}
@media (max-width: 767px) {
  .banner-promo__content-text .play-icon svg {
    height: 60px;
  }
}

.latest-news {
  position: relative;
}
.latest-news::after,
.latest-news::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
}
.latest-news::before {
  top: 0;
  background-color: #fff;
}
.latest-news::after {
  bottom: 0;
  background-color: #edf2ee;
}

.call-to-action--two .newsletter__input input {
  border-color: transparent;
  color: #999999;
}
.call-to-action--two .newsletter__input input:hover,
.call-to-action--two .newsletter__input input:focus {
  border-color: #b2b2b2;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.call-to-action--four {
  border-top: 1px solid rgba(180, 204, 180, 0.7);
}

.best-deals {
  overflow: hidden;
}
.best-deals__content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.best-deals__content .title {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #01b0df;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.best-deals__content h2 {
  width: 500px;
  color: #002603;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .best-deals__content h2 {
    max-width: 100%;
    font-size: 32px;
    line-height: 42px;
  }
}
.best-deals__content .button {
  margin-top: 24px;
}
.best-deals__img-content {
  z-index: -1;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.best-deals__img-content--one,
.best-deals__img-content--two {
  position: absolute;
}
.best-deals__img-content--one img,
.best-deals__img-content--two img {
  height: inherit;
  -o-object-fit: contain;
  object-fit: contain;
}
.best-deals__img-content--one {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -70px;
  height: 300px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .best-deals__img-content--one {
    left: -350px;
  }
}
@media (max-width: 767px) {
  .best-deals__img-content--one {
    display: none;
  }
}
.best-deals__img-content--two {
  bottom: -100px;
  right: -220px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .best-deals__img-content--two {
    right: -450px;
  }
}
@media (max-width: 767px) {
  .best-deals__img-content--two {
    right: 0px;
  }
}
.best-deals__countdown .timer-body-block .table-cell {
  position: relative;
  width: 96px;
  height: 96px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px 8px;
  border-radius: 6px;
  background-color: #fff;
  margin: 0px 10px;
}
@media (min-width: 375px) and (max-width: 734px) {
  .best-deals__countdown .timer-body-block .table-cell {
    width: 70px;
  }
}
@media (max-width: 575px) {
  .best-deals__countdown .timer-body-block .table-cell {
    width: 60px;
  }
}
.best-deals__countdown .timer-body-block .table-cell::after {
  top: 50% !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #618062 !important;
}
.best-deals__countdown .timer-body-block .table-cell .tab-val {
  font-size: 32px !important;
  line-height: 38.4px !important;
  font-weight: 400;
  color: #01b0df;
}
@media (max-width: 767px) {
  .best-deals__countdown .timer-body-block .table-cell .tab-val {
    font-size: 22px !important;
  }
}
.best-deals__countdown .timer-body-block .table-cell .tab-unit {
  font-size: 14px !important;
  line-height: 14px !important;
  letter-spacing: 0.03em;
  font-weight: 400;
  color: #618062;
}
@media (max-width: 767px) {
  .best-deals__countdown .timer-body-block .table-cell .tab-unit {
    font-size: 10px !important;
  }
}

.sales-banner--two .cards-ss--md {
  margin-bottom: 24px;
}

.category--top {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(20.01%, #f2f5f3),
    to(#ffffff)
  );
  background: linear-gradient(180deg, #f2f5f3 20.01%, #ffffff 100%);
}

.featured--five .cards-md {
  border-radius: 8px;
}
.featured__overlay-img {
  position: absolute;
  z-index: -1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  color: #f2f2f2;
}
.featured__overlay-img--one {
  bottom: 30px;
  left: 0px;
}
@media (max-width: 991px) {
  .featured__overlay-img--one {
    left: -60px;
  }
}
.featured__overlay-img--two {
  bottom: -45px;
  left: calc(10% + 50px);
  z-index: 1;
}
@media (max-width: 767px) {
  .featured__overlay-img--two {
    bottom: -30px;
  }
}
@media (max-width: 575px) {
  .featured__overlay-img--two {
    left: auto;
    right: 0px;
  }
}

.category--top__overlay-img {
  position: absolute;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}
.category--top__overlay-img--one {
  bottom: 0px;
  right: 15%;
}
@media (max-width: 575px) {
  .category--top__overlay-img--one {
    bottom: -30px;
    right: auto;
    left: -30px;
  }
}
.category--top__overlay-img--two {
  right: 0px;
  bottom: 15px;
}

@media (max-width: 767px) {
  .food-store {
    overflow: hidden;
  }
}
.food-store__img-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
}
@media (max-width: 1024px) {
  .food-store__img-wrapper {
    margin-bottom: 30px;
  }
}
.food-store__img-wrapper-img {
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.food-store__img-wrapper-img img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.food-store__img-wrapper-img--one {
  width: 275px;
  height: 400px;
  margin-right: 24px;
}
@media (max-width: 767px) {
  .food-store__img-wrapper-img--one {
    width: 100%;
    margin: 0px;
  }
}
.food-store__img-wrapper-img--two {
  width: 445px;
  height: 455px;
}
@media (max-width: 767px) {
  .food-store__img-wrapper-img--two {
    display: none;
  }
}
.food-store__img-overlay {
  position: absolute;
  pointer-events: none;
}
.food-store__img-overlay--one {
  bottom: 0;
  left: -60px;
  z-index: 2;
}
@media (max-width: 767px) {
  .food-store__img-overlay--one {
    bottom: -35px;
    left: -25px;
  }
}
.food-store__img-overlay--two {
  top: -55px;
  left: 115px;
}
@media (max-width: 767px) {
  .food-store__img-overlay--two {
    display: none;
  }
}
.food-store__content h2 {
  color: #1a1a1a;
  margin-bottom: 26px;
  width: 390px;
}
@media (max-width: 767px) {
  .food-store__content h2 {
    font-size: 28px;
    line-height: 38px;
    width: 100%;
  }
}
.food-store__content-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 26px;
}
.food-store__content-list li .icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #01b0df;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.food-store__content-info {
  margin-left: 12px;
}
.food-store__content-info h5 {
  color: #1a1a1a;
  margin-bottom: 10px;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .food-store__content-info h5 {
    margin-bottom: 0px;
  }
}
.food-store__content-info p {
  color: #808080;
}
.food-store__overlay-shade-img {
  position: absolute;
  pointer-events: none;
  z-index: -1;
}
.food-store__overlay-shade-img--one {
  bottom: 35px;
  left: 0px;
  color: #e6e6e6;
}
@media (max-width: 575px) {
  .food-store__overlay-shade-img--one {
    bottom: -70px;
  }
}
.food-store__overlay-shade-img--two {
  top: 0;
  left: 0;
  color: #f2f2f2;
}
@media (max-width: 767px) {
  .food-store__overlay-shade-img--two {
    display: none;
  }
}
.food-store__overlay-shade-img--three {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 35px;
  color: #f2f2f2;
}
@media (max-width: 767px) {
  .food-store__overlay-shade-img--three {
    right: -60px;
    top: 70%;
  }
}
.food-store__overlay-shade-img--four {
  bottom: -40px;
  right: 100px;
}
@media (max-width: 767px) {
  .food-store__overlay-shade-img--four {
    display: none;
  }
}

.counter__content-items {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  text-align: center;
  z-index: 1;
}
@media (min-width: 576px) and (max-width: 991px) {
  .counter__content-items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 575px) {
  .counter__content-items {
    grid-template-columns: repeat(1, 1fr);
  }
}
.counter__content-item {
  background: rgba(255, 255, 255, 0.07);
  border-radius: 8px;
  padding: 40px;
  overflow: hidden;
}
.counter__content-item span {
  color: #01b0df;
  font-weight: 300;
}
.counter__content-item p {
  color: #fff;
  text-transform: capitalize;
}
.counter__overlay-img {
  position: absolute;
}
.counter__overlay-img.one {
  top: 24px;
  left: 0px;
}
@media (max-width: 767px) {
  .counter__overlay-img.one {
    display: none;
  }
}
.counter__overlay-img.two,
.counter__overlay-img.three {
  color: #f2f2f2;
}
.counter__overlay-img.two {
  top: 14px;
  right: 0px;
}
.counter__overlay-img.three {
  bottom: -10px;
  right: 88px;
}

@media (max-width: 767px) {
  .banner-sales {
    overflow: hidden;
  }
}
.banner-sales__overlay-img {
  position: absolute;
  z-index: -1;
}
.banner-sales__overlay-img.one {
  top: 0;
  right: 30px;
  color: #e6e6e6;
}
@media (max-width: 767px) {
  .banner-sales__overlay-img.one {
    right: -175px;
  }
}
.banner-sales__overlay-img.two {
  top: 40px;
  left: 75px;
  color: #f2f2f2;
}
@media (max-width: 767px) {
  .banner-sales__overlay-img.two {
    left: 0px;
    top: -30px;
  }
}
@media (max-width: 575px) {
  .banner-sales__overlay-img.two {
    display: none;
  }
}
.banner-sales .banner-sale--two {
  margin-bottom: 24px;
}

.news .cards-blog {
  margin-bottom: 40px;
}

@media (max-width: 1200px) {
  .testimonial {
    overflow: hidden;
  }
}
.testimonial--four {
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#ffffff),
      color-stop(63.02%, #f2f5f3)
    ),
    -webkit-gradient(linear, left bottom, left top, from(rgba(32, 181, 38, 0.1)), to(rgba(32, 181, 38, 0.1)));
  background: linear-gradient(180deg, #ffffff 0%, #f2f5f3 63.02%),
    linear-gradient(0deg, rgba(32, 181, 38, 0.1), rgba(32, 181, 38, 0.1));
}
.testimonial__overlay-img {
  position: absolute;
}
.testimonial__overlay-img.one {
  bottom: -30px;
  left: 175px;
}
@media (max-width: 1200px) {
  .testimonial__overlay-img.one {
    left: 0px;
    bottom: auto;
    top: -35px;
  }
}
.testimonial__overlay-img.two {
  top: 140px;
  right: 240px;
}
@media (max-width: 1200px) {
  .testimonial__overlay-img.two {
    display: none;
  }
}
.testimonial__overlay-img.three {
  top: 64px;
  right: 48px;
  color: #e6e6e6;
}
@media (max-width: 1200px) {
  .testimonial__overlay-img.three {
    top: auto;
    bottom: -60px;
    right: 0px;
  }
}

.newsletter--two__content-item {
  position: relative;
  padding: 25px;
  border-radius: 8px;
  border: 1px solid #20b52633;
  height: 220px;
}
@media (max-width: 1199px) {
  .newsletter--two__content-item {
    margin-bottom: 24px;
  }
}
@media (max-width: 991px) {
  .newsletter--two__content-item {
    text-align: center;
  }
}
.newsletter--two__content-item.three {
  justify-self: center;
}
.newsletter--two__content-item .icon {
  margin-bottom: 18px;
}
.newsletter--two__content-item h5 {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 10px;
}
.newsletter--two__content-item p {
  color: #666666;
  font-size: 14px;
  line-height: 21p;
  text-transform: capitalize;
  font-weight: 400;
}
.newsletter--two__content-item p.number {
  font-size: 24px;
  line-height: 24px;
  color: #01b0df;
}
.newsletter--two__form {
  position: relative;
  width: 580px;
  height: 45px;
  border-radius: 500px;
  border: 1px solid #e5e5e5;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .newsletter--two__form {
    width: 400px;
  }
}
@media (max-width: 991px) {
  .newsletter--two__form {
    margin: auto;
    margin-top: 30px;
  }
}
@media (max-width: 575px) {
  .newsletter--two__form {
    width: 260px;
  }
}
.newsletter--two__form input {
  border: 0px;
  width: 75%;
  height: inherit;
  color: #1a1a1a;
  font-size: 14px;
  line-height: 24px;
  padding-left: 24px;
  width: 80%;
}
@media (max-width: 1200px) {
  .newsletter--two__form input {
    width: 65%;
  }
}
@media (max-width: 991px) {
  .newsletter--two__form input {
    padding-left: 15px;
    width: 50%;
  }
}
@media (max-width: 575px) {
  .newsletter--two__form input {
    margin-left: -110px;
    width: 60%;
  }
}
.newsletter--two__form input::-webkit-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.newsletter--two__form input:-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.newsletter--two__form input::-ms-input-placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.newsletter--two__form input::placeholder {
  font-size: inherit;
  line-height: inherit;
  color: #999999;
}
.newsletter--two__form .button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0px;
}
@media (max-width: 575px) {
  .newsletter--two__form .button {
    padding: 14px;
  }
}

@media (max-width: 1440px) {
  .category--top .section__head .arrows button {
    position: static !important;
  }
  .category--top .container .arrows.category--top-arrows-lg {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1440px) {
  .category--top .section__head {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .category--top .section__head h2 span {
    left: 20px;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@media (max-width: 1200px) {
  .call-to-action--two {
    background-color: #1a1a1a;
  }
}
@media (max-width: 991px) {
  .featured .cards-ss--small-one,
  .featured .cards-ss--small-two {
    margin-bottom: 15px;
  }
  .featured__content {
    margin: 16px 0px;
  }
}
@media (min-width: 768px) {
  .featured .swiper-pagination,
  .news .swiper-pagination,
  .testimonial-slider--one .swiper-pagination,
  .instagram-slider--one .swiper-pagination,
  .members-slider--one .swiper-pagination,
  .cyclone--slider .swiper-pagination,
  .newest-products .swiper-pagination,
  .category--top-slider--two .swiper-pagination,
  .news-slider--one .swiper-pagination {
    display: none;
  }

  .our-products .swiper-container {
    display: none;
  }
}
@media (max-width: 767px) {
  .featured .swiper-wrapper,
  .news .swiper-wrapper,
  .popular-categories--slider .swiper-wrapper,
  .popular-products--slider .swiper-wrapper,
  .testimonial-slider--one .swiper-wrapper,
  .testimonial-slider--three .swiper-wrapper,
  .instagram-slider--one .swiper-wrapper,
  .cyclone--slider .swiper-wrapper,
  .newest-products .swiper-wrapper,
  .category--top-slider--two .swiper-wrapper,
  .our-products__content-slider .swiper-wrapper,
  .our-products__content-slider-one .swiper-wrapper,
  .our-products__content-slider-two .swiper-wrapper,
  .our-products__content-slider-three .swiper-wrapper,
  .our-products__content-slider-four .swiper-wrapper,
  .news-slider--one .swiper-wrapper {
    padding-bottom: 30px;
  }

  .category--top .section__head .arrows {
    margin-left: 50px;
  }
}
/** 4.2 About Page */
.members--two {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f2f2f2),
    to(#ffffff)
  );
  background: linear-gradient(180deg, #f2f2f2 0%, #ffffff 100%);
}
.members--two .container {
  position: relative;
}
.members--two .container .arrows .arrows__btn-prev,
.members--two .container .arrows .arrows__btn-next {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}
@media (max-width: 1200px) {
  .members--two .container .arrows .arrows__btn-prev,
  .members--two .container .arrows .arrows__btn-next {
    display: none;
  }
}
.members--two .container .arrows .arrows__btn-prev {
  left: -64px;
}
.members--two .container .arrows .arrows__btn-next {
  right: -64px;
}
.members--two .cards-mb {
  border-radius: 8px;
  overflow: hidden;
}

.testimonial--five {
  background: #f2f2f2;
}

/** 4.3 Blog Details Page */
.single-blog__content {
  margin-top: 32px;
}
.single-blog__content .blog-head-title {
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .single-blog__content .blog-head-title {
    font-size: 26px;
    line-height: 36px;
  }
}
.single-blog__img-wrapper {
  position: relative;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.single-blog__img-wrapper img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.single-blog__img-wrapper.one {
  height: 600px;
  margin-bottom: 32px;
}
@media (max-width: 767px) {
  .single-blog__img-wrapper.one {
    height: 250px;
  }
}
.single-blog__img-wrapper.two,
.single-blog__img-wrapper.three {
  height: 356px;
}
@media (max-width: 767px) {
  .single-blog__img-wrapper.two,
  .single-blog__img-wrapper.three {
    height: 220px;
  }
}
.single-blog--tag-info,
.single-blog--tag-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.single-blog--tag-info {
  margin-bottom: 8px;
}
.single-blog--tag-item {
  margin-right: 20px;
}
@media (max-width: 767px) {
  .single-blog--tag-item {
    margin-right: 12px;
    white-space: nowrap;
  }
}
.single-blog--tag-item .icon {
  margin-right: 5px;
}
.single-blog--tag-item p {
  font-size: 14px;
  line-height: 21px;
  color: #4d4d4d;
  font-weight: 400;
}
.single-blog__author-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 24px;
  -webkit-box-shadow: 0px 1px 0px 0px #e5e5e5;
  box-shadow: 0px 1px 0px 0px #e5e5e5;
}
@media (max-width: 575px) {
  .single-blog__author-details {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}
.single-blog__author-details .author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 575px) {
  .single-blog__author-details .author {
    margin-bottom: 30px;
  }
}
.single-blog__author-details .author-img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  margin-right: 12px;
}
.single-blog__author-details .author-img img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.single-blog__author-details .author-blog-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.single-blog__author-details .author-blog-details span.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #808080;
  margin: 0px 6px;
}
.single-blog__author-details .author-info h5 {
  color: #1a1a1a;
  margin-bottom: 5px;
}
.single-blog__author-details .author-info p {
  font-size: 14px;
  line-height: 21px;
  color: #808080;
  text-transform: capitalize;
}
.single-blog__author-details .social-icon-link a {
  color: #4d4d4d;
}
.single-blog__author-details .social-icon-link a:hover {
  color: #fff;
}
.single-blog__inner-content .blog-title {
  margin-top: 24px;
  margin-bottom: 16px;
}
.single-blog__inner-content p {
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #808080;
  margin-bottom: 24px;
}
.single-blog__inner-content .single-blog--sm-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  .single-blog__inner-content .single-blog--sm-img {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.single-blog__inner-content .single-blog__img-wrapper {
  margin-right: 24px;
}
@media (max-width: 767px) {
  .single-blog__inner-content .single-blog__img-wrapper {
    margin-right: 0px;
    margin-bottom: 15px;
  }
}
.single-blog__inner-content .single-blog__img-wrapper:nth-last-child(1) {
  margin-right: 0px;
}
@media (max-width: 767px) {
  .single-blog__inner-content .single-blog__img-wrapper:nth-last-child(1) {
    margin-bottom: 0px;
  }
}
.single-blog__banner {
  position: relative;
  height: 250px;
  overflow: hidden;
  border-radius: 8px;
}
.single-blog__banner img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.single-blog__banner .text-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 56px;
}
@media (max-width: 575px) {
  .single-blog__banner .text-content {
    left: 30px;
  }
}
.single-blog__banner .text-content .title {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #999999;
  margin-bottom: 4px;
}
.single-blog__banner .text-content h5 {
  font-size: 36px;
  line-height: 43.2px;
  color: #fff;
  margin-bottom: 24px;
}
.single-blog__banner .sales-off {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 40%;
  width: 91px;
  height: 91px;
  border-radius: 50%;
  background-color: #1a1a1a;
  text-align: center;
  vertical-align: middle;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  .single-blog__banner .sales-off {
    top: 90px;
    left: auto;
    right: 60px;
  }
}
@media (max-width: 767px) {
  .single-blog__banner .sales-off {
    width: 80px;
    height: 80px;
    top: 50px;
    right: 15px;
  }
}
@media (max-width: 575px) {
  .single-blog__banner .sales-off {
    right: 0px;
  }
}
.single-blog__banner .sales-off p {
  font-size: 11px;
  line-height: 12.1px;
  letter-spacing: 0.03em;
  color: #b2b2b2;
  text-transform: uppercase;
}
.single-blog__banner .sales-off h5 {
  font-size: 24px;
  line-height: 26.4px;
  font-weight: 600;
  color: #ff8a00;
  margin-bottom: 0px;
}
@media (max-width: 767px) {
  .single-blog__banner .sales-off h5 {
    font-size: 18px;
    line-height: 28px;
  }
}
.single-blog .comment-box {
  padding: 40px 0px;
  position: relative;
}
.single-blog .comment-box h5 {
  color: #1a1a1a;
}
.single-blog .comment-box form {
  margin-top: 36px;
}
.single-blog .comment-box .contact-form--input {
  margin-bottom: 32px;
}
.single-blog .comment-box .contact-form--check {
  margin-top: 16px;
}
@media (max-width: 767px) {
  .single-blog .comment-box .contact-form--check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .single-blog .comment-box .contact-form--check input {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-right: 6px;
  }
}
.single-blog .user-comments {
  padding-bottom: 80px;
}
@media (max-width: 767px) {
  .single-blog .user-comments {
    padding-bottom: 30px;
  }
}
@media (max-width: 575px) {
  .single-blog .user-comments {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.single-blog .user-comments h5 {
  color: #1a1a1a;
  text-transform: capitalize;
  margin-bottom: 24px;
  margin-right: auto;
}
.single-blog .user-comments .user-comments__list .user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 24px 0px;
  border-bottom: 1px solid #e5e5e5;
}
.single-blog .user-comments .user-comments__list .user:nth-child(1) {
  padding-top: 0px;
}
.single-blog .user-comments .user-comments__list .user:nth-last-child(1) {
  border-bottom: 0px;
  padding-bottom: 45px;
}
.single-blog .user-comments .user-comments__list .user-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 12px;
}
.single-blog .user-comments .user-comments__list .user-img img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.single-blog .user-comments .user-comments__list .user-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.single-blog .user-comments .user-comments__list .user-name h5 {
  margin-bottom: 0px;
  text-transform: capitalize;
  color: #1a1a1a;
  position: relative;
  margin-right: 6px;
}
.single-blog .user-comments .user-comments__list .user-name h5::after {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -16px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #1a1a1a;
  margin: 0px 6px;
}
.single-blog .user-comments .user-comments__list .user-name p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #666666;
  margin-left: 12px;
}
.single-blog .user-comments .user-comments__list .user .user-message {
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  font-weight: 400;
}

/** 4.4 FAQ Page */
.faq {
  overflow: hidden;
}
.faq__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: relative;
}
.faq h5 {
  margin-bottom: 32px;
  color: #1a1a1a;
}
@media (max-width: 767px) {
  .faq h5 {
    font-size: 28px;
    line-height: 38px;
    text-align: center;
  }
}
.faq__img-wrapper {
  position: absolute;
  bottom: -100px;
}
@media (max-width: 991px) {
  .faq__img-wrapper {
    position: static;
    margin-bottom: 30px;
  }
}

/** 4.5 Blogs Page */
.filter--search__content {
  margin-top: 32px;
}
.filter--search-result {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .filter--search-result {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.filter--search-result .sort-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.filter--search-result .sort-list label {
  font-size: 14px;
  line-height: 21px;
  color: #808080;
  font-weight: 400;
  text-transform: capitalize;
  margin-right: 6px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.filter--search-result .result-found p {
  font-size: 16px;
  line-height: 21px;
  color: #808080;
  font-weight: 400;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .filter--search-result .result-found p {
    display: none;
  }
}
.filter--search-result .result-found p .number {
  font-weight: 600;
  color: #1a1a1a;
}

.blog-list__wrapper {
  margin-top: 32px;
  margin-bottom: 80px;
}
@media (max-width: 991px) {
  .blog-list__content--desktop {
    display: none;
  }
}
.blog-list__content--desktop .cards-blog {
  margin-bottom: 24px;
}
@media (min-width: 992px) {
  .blog-list--slider {
    display: none;
  }
}
.blog-list--slider.swiper-container {
  padding-bottom: 30px;
}

/** 4.6 Shop Page */
.shop--one {
  margin-top: 24px;
}
.shop__product-items .cards-md {
  border-radius: 6px;
  margin-bottom: 18px;
}

.banner-sales__content {
  width: 100%;
  height: 358px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.banner-sales__content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90.02deg,
    rgba(0, 0, 0, 0.7) 0.03%,
    rgba(0, 0, 0, 0) 91.31%
  );
}
@media (max-width: 991px) {
  .banner-sales__content {
    margin-bottom: 24px;
  }
}
.banner-sales__content img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.banner-sales__content .text-content {
  position: absolute;
  top: 50%;
  left: 60px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
}
@media (max-width: 767px) {
  .banner-sales__content .text-content {
    left: 15px;
  }
}
.banner-sales__content .text-content .title {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  margin-bottom: 12px;
}
.banner-sales__content .text-content h2 {
  color: #fff;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .banner-sales__content .text-content h2 {
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 0px;
  }
}
.banner-sales__content .text-content a.button {
  margin-top: 32px;
}
.banner-sales__content .sale-off {
  position: absolute;
  top: 57px;
  left: 472px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #ff8a00;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
}
@media (max-width: 767px) {
  .banner-sales__content .sale-off {
    top: auto;
    left: auto;
    bottom: 15px;
    right: 15px;
    width: 80px;
    height: 80px;
  }
}
.banner-sales__content .sale-off h5,
.banner-sales__content .sale-off p {
  text-transform: uppercase;
  color: #fff;
}
.banner-sales__content .sale-off h5 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
}
.banner-sales__content .sale-off p {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.03em;
}

.filter__sidebar {
  position: relative;
}
@media (max-width: 991px) {
  .filter__sidebar {
    position: fixed;
    top: 0;
    left: -60%;
    width: 60%;
    height: 100%;
    background: #fff;
    z-index: 9999;
    padding: 30px 0px;
    margin-bottom: 0px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
}
@media (max-width: 767px) {
  .filter__sidebar {
    left: -50%;
    width: 50%;
  }
}
@media (max-width: 580px) {
  .filter__sidebar {
    left: -85%;
    width: 85%;
  }
}
@media (max-width: 991px) {
  .filter__sidebar.active {
    left: 0px;
    width: 60%;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
  }
}
@media (max-width: 767px) {
  .filter__sidebar.active {
    width: 50%;
  }
}
@media (max-width: 580px) {
  .filter__sidebar.active {
    width: 85%;
  }
}
.filter__sidebar.active .filter {
  top: 30px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.filter__sidebar .filter {
  position: absolute;
  top: 35%;
  -webkit-transform: translateY(-35%);
  transform: translateY(-35%);
  right: -35px;
  background-color: #01b0df;
  color: #fff;
  padding: 8px;
  border-radius: 6px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  z-index: 9;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
@media (min-width: 992px) {
  .filter__sidebar .filter {
    display: none;
  }
}
.filter__sidebar .filter-box {
  margin-bottom: 32px;
}
@media (max-width: 991px) {
  .filter__sidebar .filter-box {
    height: 100%;
    overflow-y: auto;
  }
}
.filter__sidebar .filter-box__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 30px 0px;
}
@media (max-width: 991px) {
  .filter__sidebar .filter-box__top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 0px;
    padding-bottom: 15px;
  }
}
.filter__sidebar .filter-box__top-left,
.filter__sidebar .filter-box__top-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 991px) {
  .filter__sidebar .filter-box__top-left,
  .filter__sidebar .filter-box__top-right {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.filter__sidebar .filter-box__top .select-box--item .bv_mainselect {
  width: 178px;
  border: 1px solid #e5e5e5;
  margin-right: 16px;
  padding: 0px;
}
@media (max-width: 991px) {
  .filter__sidebar .filter-box__top .select-box--item .bv_mainselect {
    margin-bottom: 16px;
    margin-right: 0px;
    width: 385px;
  }
}
@media (max-width: 767px) {
  .filter__sidebar .filter-box__top .select-box--item .bv_mainselect {
    width: 250px;
  }
}
.filter__sidebar
  .filter-box__top
  .select-box--item
  .bv_mainselect
  .bv_background {
  width: inherit;
  height: inherit;
  border: 0px;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
  color: #4d4d4d;
}
.filter__sidebar
  .filter-box__top
  .select-box--item
  .bv_mainselect
  .bv_background
  .arrow {
  border-color: #1a1a1a;
  border-width: 0px 1.5px 1.5px 0px;
}
.filter__sidebar
  .filter-box__top
  .select-box--item
  .bv_mainselect
  .bv_background.bv_ul_inner {
  border: 1px solid #e5e5e5;
  z-index: 3;
  margin-top: 15px;
}
.filter__sidebar
  .filter-box__top
  .select-box--item
  .bv_mainselect
  .bv_background.bv_ul_inner
  li {
  border-bottom: 1px solid #e5e5e5;
}
.filter__sidebar
  .filter-box__top
  .select-box--item
  .bv_mainselect
  .bv_background.bv_ul_inner
  li:nth-last-child(1) {
  margin-bottom: 0px;
}
.filter__sidebar .filter-box__top .select-box--item .bv_atual.bv_background {
  padding: 12px 15px;
}
@media (max-width: 991px) {
  .filter__sidebar .filter-box__top-left .select-box--item .bv_mainselect {
    margin-bottom: 16px;
  }
}
.filter__sidebar
  .filter-box__top-right
  .select-box--item:nth-last-child(1)
  .bv_mainselect {
  margin-right: 0px;
}
.filter__sidebar .filter-box__bottom {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 0px;
}
@media (max-width: 991px) {
  .filter__sidebar .filter-box__bottom {
    padding: 16px 12px;
  }
}
.filter__sidebar .filter-box__bottom-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .filter__sidebar .filter-box__bottom-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
.filter__sidebar .filter-box__bottom-content .active__filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 991px) {
  .filter__sidebar .filter-box__bottom-content .active__filters {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
.filter__sidebar .filter-box__bottom-content .active__filters h5 {
  font-size: 14px;
  line-height: 21px;
  color: #808080;
  text-transform: capitalize;
  font-weight: 400;
}
.filter__sidebar .filter-box__bottom-content .active__filters-item {
  margin-left: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (max-width: 991px) {
  .filter__sidebar .filter-box__bottom-content .active__filters-item {
    margin: 15px 0px;
    margin-left: 0px;
  }
}
.filter__sidebar
  .filter-box__bottom-content
  .active__filters-item
  .filter-item {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #1a1a1a;
  margin-right: 24px;
}
.filter__sidebar
  .filter-box__bottom-content
  .active__filters-item
  .filter-item
  .icon {
  margin-left: 10px;
  color: #9a9caa;
}
.filter__sidebar
  .filter-box__bottom-content
  .active__filters-item
  .filter-item
  .icon:hover {
  color: #1a1a1a;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.filter__sidebar .filter-box__bottom-content .filter__result p {
  font-size: 14px;
  line-height: 21px;
  color: #999999;
}
.filter__sidebar .filter-box__bottom-content .filter__result p .number {
  color: #1a1a1a;
  font-weight: 600;
}

.noUi-target {
  margin: 50px 0px 10px;
}

.noUi-connect {
  background: #01b0df !important;
}

.price-range-slider {
  margin-bottom: 30px;
}

/** 4.7 Product Details Page */
@media (max-width: 991px) {
  .related .swiper-wrapper {
    padding-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .related .swiper-pagination {
    display: none;
  }
}

.products-video {
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 20px;
}
.products-video img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.products-video .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #01b0df;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
}
.products-video:hover .play-icon {
  background-color: #84d187;
  -webkit-transition: all 0.6s linear;
  transition: all 0.6s linear;
}
.products-video__card {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  padding: 24px 20px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .products-video__card {
    margin-bottom: 24px;
  }
}
@media (max-width: 540px) {
  .products-video__card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
.products-video__card-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 540px) {
  .products-video__card-item {
    margin-bottom: 24px;
  }
  .products-video__card-item:nth-last-child(1) {
    margin-bottom: 0px;
  }
}
.products-video__card-item .icon {
  margin-right: 15px;
}
.products-video__card-item .text__info h5 {
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
  text-transform: capitalize;
  font-weight: 500;
  margin-bottom: 6px;
}
.products-video__card-item .text__info p {
  font-size: 13px;
  line-height: 19.5px;
  color: #808080;
  text-transform: capitalize;
  font-weight: 400;
}

.products-tab__btn {
  padding: 0px 16px;
  margin-bottom: 32px;
  -webkit-box-shadow: 0px 1px 0px 0px #e5e5e5;
  box-shadow: 0px 1px 0px 0px #e5e5e5;
}
.products-tab__btn .nav {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .products-tab__btn .nav {
    display: -webkit-box;
    max-width: 100%;
    overflow-x: auto;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
@media (max-width: 400px) {
  .products-tab__btn .nav {
    padding-bottom: 16px;
  }
}
.products-tab__btn .nav-link {
  font-size: 16px;
  line-height: 24px;
  color: #808080;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 2px solid transparent;
}
@media (max-width: 767px) {
  .products-tab__btn .nav-link {
    white-space: nowrap;
  }
}
.products-tab__btn .nav-link.active {
  background-color: transparent;
  color: #1a1a1a;
  border-color: #01b0df;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.products-tab__description--text {
  width: 648px;
  font-size: 14px;
  line-height: 21px;
  color: #808080;
  font-weight: 400;
  margin-bottom: 20px;
}
@media (max-width: 1200px) {
  .products-tab__description--text {
    width: 100%;
  }
}
.products-tab__description--list {
  margin-bottom: 20px;
}
.products-tab__description--list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 12px;
}
.products-tab__description--list li .icon {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #01b0df;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 8px;
  color: #fff;
}
.products-tab__description--list li p {
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 400;
  text-transform: capitalize;
  color: #808080;
}
.products-tab__information-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 12px;
}
.products-tab__information-list li .title,
.products-tab__information-list li .title-description {
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
}
.products-tab__information-list li .title {
  width: 112px;
  color: #1a1a1a;
}
.products-tab__information-list li .title-description {
  color: #666666;
}
.products-tab__information-list li .title-description__tags-item {
  color: #666666;
}
.products-tab__information-list li .title-description__tags-item:hover {
  color: #1a1a1a;
  text-decoration: underline;
}
.products-tab__feedback-content {
  padding: 20px 0px;
  border-bottom: 1px solid #e5e5e5;
}
.products-tab__feedback-content:nth-child(1) {
  padding-top: 0px;
}
.products-tab__feedback-content:nth-last-child(2) {
  border-bottom: 0px;
}
.products-tab__feedback-content-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 12px;
}
.products-tab__feedback-content-top .user-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.products-tab__feedback-content-top .user-details__img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 12px;
}
.products-tab__feedback-content-top .user-details__img img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.products-tab__feedback-content-top .user-details__info .user-name {
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
  text-transform: capitalize;
  font-weight: 500;
}
.products-tab__feedback-content-top .user-details__rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.products-tab__feedback-content-top .user-published__info p {
  font-size: 14px;
  line-height: 21px;
  color: #999999;
  text-transform: capitalize;
  font-weight: 400;
}
.products-tab__feedback-comments {
  font-size: 14px;
  line-height: 21px;
  color: #808080;
  text-transform: capitalizes;
  font-weight: 400;
}

/** 4.8 Dashboard Page */
.dashboard__content {
  margin-top: 32px;
}
.dashboard__user-profile:hover,
.dashboard__user-billing:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #e5e5e5;
}
.dashboard__user-profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.dashboard__user-profile-img {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 8px;
}
.dashboard__user-profile-img img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.dashboard__user-profile-info .name,
.dashboard__user-profile-info .designation,
.dashboard__user-profile-info .edit {
  text-transform: capitalize;
}
.dashboard__user-profile-info .name {
  color: #1a1a1a;
}
.dashboard__user-profile-info .designation {
  color: #808080;
}
.dashboard__user-profile-info .edit {
  color: #01b0df;
  margin-top: 9px;
}
.dashboard__user-billing-title {
  line-height: 14px;
  text-transform: uppercase;
  color: #999999;
  letter-spacing: 0.03em;
  margin-bottom: 18px;
}
.dashboard__user-billing-name,
.dashboard__user-billing-location,
.dashboard__user-billing-editaddress {
  text-transform: capitalize;
}
.dashboard__user-billing-name,
.dashboard__user-billing-email,
.dashboard__user-billing-number {
  color: #1a1a1a;
}
.dashboard__user-billing-location {
  color: #666666;
}
.dashboard__user-billing-editaddress {
  color: #01b0df;
  margin-top: 12px;
}
.dashboard__user-billing-editaddress:hover {
  color: #01b0df;
}
.dashboard__user-billing-name,
.dashboard__user-billing-location {
  margin-bottom: 8px;
}
.dashboard__user-billing-email,
.dashboard__user-billing-number {
  margin-bottom: 6px;
}
.dashboard__details-content {
  margin: 24px;
}
@media (max-width: 767px) {
  .dashboard__details-content {
    margin: 12px;
  }
}
.dashboard__details-card,
.dashboard__totalpayment-card {
  position: relative;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .dashboard__details-card,
  .dashboard__totalpayment-card {
    height: auto;
  }
}
.dashboard__details-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 1200px) {
  .dashboard__details-card {
    margin-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .dashboard__details-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.dashboard__details-card-item {
  height: inherit;
  border-right: 1px solid #e5efef;
}
.dashboard__details-card-item:nth-last-child(1) {
  border-right: 0px;
}
@media (max-width: 767px) {
  .dashboard__details-card-item:nth-last-child(1)
    .dashboard__details-card-title {
    border-top: 1px solid #e5efef;
  }
}
@media (max-width: 767px) {
  .dashboard__details-card-item {
    width: 100%;
    border: 0px;
  }
}
.dashboard__details-card-item__inner {
  padding: 14px 20px;
}
@media (max-width: 767px) {
  .dashboard__details-card-item__inner {
    padding: 14px;
  }
}
.dashboard__details-card-item__inner h2 {
  text-transform: capitalize;
  color: #1a1a1a;
  margin-bottom: 8px;
}
.dashboard__details-card-item__inner p {
  text-transform: capitalize;
  color: #666666;
}
.dashboard__details-card-item__inner-contact {
  margin-bottom: 12px;
}
.dashboard__details-card-item__inner-contact h5 {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #999999;
  margin-bottom: 4px;
}
.dashboard__details-card-item__inner-contact p {
  color: #1a1a1a;
}
.dashboard__details-card-title {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: #999999;
  text-transform: uppercase;
  padding: 18px 20px;
  border-bottom: 1px solid #e5e5e5;
}
.dashboard__totalpayment-card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
}
.dashboard__totalpayment-card-header-item {
  padding: 20px;
  border-right: 1px solid #e5e5e5;
}
@media (max-width: 767px) {
  .dashboard__totalpayment-card-header-item {
    padding: 20px 10px;
  }
}
.dashboard__totalpayment-card-header-item:nth-last-child(1) {
  border: 0px;
}
.dashboard__totalpayment-card-header-item h5 {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.03em;
  color: #808080;
  text-transform: uppercase;
  margin-bottom: 6px;
}
.dashboard__totalpayment-card-header-item p {
  color: #1a1a1a;
  text-transform: capitalize;
}
.dashboard__totalpayment-card-body {
  padding: 18px 20px;
}
@media (max-width: 767px) {
  .dashboard__totalpayment-card-body {
    padding: 18px 10px;
  }
}
.dashboard__totalpayment-card-body-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 13px 0px;
  border-bottom: 1px solid #e5e5e5;
}
.dashboard__totalpayment-card-body-item:nth-child(1) {
  padding-top: 0px;
}
.dashboard__totalpayment-card-body-item:nth-last-child(1) {
  border: 0px;
}
.dashboard__totalpayment-card-body-item h5 {
  text-transform: capitalize;
  color: #666666;
}
.dashboard__totalpayment-card-body-item p {
  font-weight: 500;
  color: #1a1a1a;
  text-align: right;
}
.dashboard__totalpayment-card-body-item.total h5 {
  color: #1a1a1a;
}
.dashboard__totalpayment-card-body-item.total p {
  font-weight: 600;
  color: #2c742f;
}
@media (max-width: 991px) {
  .dashboard__user-profile,
  .dashboard__user-billing {
    margin-bottom: 24px;
    height: auto;
  }
}
.dashboard__order-history {
  position: relative;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  overflow: hidden;
}
.dashboard__order-history-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 24px;
}
@media (max-width: 767px) {
  .dashboard__order-history-title {
    padding: 16px 12px;
  }
}
.dashboard__order-history-title h2,
.dashboard__order-history-title a {
  text-transform: capitalize;
}
.dashboard__order-history-title h2 {
  color: #1a1a1a;
}
@media (max-width: 575px) {
  .dashboard__order-history-title h2 {
    font-size: 16px;
  }
}
.dashboard__order-history-title a {
  color: #01b0df;
}
@media (max-width: 575px) {
  .dashboard__order-history-title a {
    font-size: 14px;
  }
}
.dashboard__order-history-table-title,
.dashboard__order-history-table-item {
  font-size: 14px;
  line-height: 21px;
  padding: 12px 24px !important;
  border-bottom: 0px !important;
}
@media (max-width: 767px) {
  .dashboard__order-history-table-title,
  .dashboard__order-history-table-item {
    padding: 12px !important;
  }
}
@media (max-width: 1199px) {
  .dashboard__order-history-table-title,
  .dashboard__order-history-table-item {
    white-space: nowrap;
  }
}
.dashboard__order-history-table-title {
  color: #4d4d4d;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-weight: 500;
  background-color: #f2f2f2 !important;
}
.dashboard__order-history-table-item {
  font-weight: 400;
  color: #333333;
  padding: 12px 24px !important;
}
@media (max-width: 767px) {
  .dashboard__order-history-table-item {
    padding: 12px !important;
  }
}
.dashboard__order-history-table-item:nth-last-child(1) {
  padding-right: 24px !important;
  text-align: right;
}
.dashboard__order-history-table-item .order-total-price {
  font-weight: 500;
}
.dashboard__order-history-table-item .order-total-price .quantity {
  font-weight: 400;
}
.dashboard__order-history-table-item.order-details a {
  font-weight: 500;
  color: #01b0df;
}
.dashboard__order-history-table__product-content tbody tr {
  position: relative;
  width: 100%;
}
.dashboard__order-history-table__product-content tbody tr::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 96%;
  height: 1px;
  background-color: #e5e5e5;
}
.dashboard__order-history-table__product-content
  tbody
  tr:nth-last-child(1)::after {
  height: 0px;
}
.dashboard__order-pagination {
  padding: 32px 0px;
  padding-top: 12px;
}
@media (max-width: 767px) {
  .dashboard__order-pagination {
    padding-top: 32px;
  }
}
.dashboard__content-card {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  margin-bottom: 24px;
}
.dashboard__content-card-header {
  padding: 16px 24px;
  -webkit-box-shadow: 0px 1px 0px 0px #e5e5e5;
  box-shadow: 0px 1px 0px 0px #e5e5e5;
}
.dashboard__content-card-header h5 {
  text-transform: capitalize;
  color: #1a1a1a;
}
.dashboard__content-card-body {
  padding: 24px;
}
.dashboard__content-card-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
@media (max-width: 991px) {
  .dashboard__content-card-img {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 30px;
  }
}
.dashboard__content-img-wrapper {
  width: 224px;
  height: 224px;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 20px;
}
.dashboard__content-img-wrapper img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.dashboard__content .button {
  margin: auto;
  text-align: center;
}
.dashboard__product-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dashboard__product-item-img {
  position: relative;
  width: 70px;
  height: 70px;
  overflow: hidden;
  margin-right: 12px;
}
.dashboard__product-item-img img {
  width: inherit;
  height: inherit;
}
.dashboard__product-item h5 {
  color: #1a1a1a;
}

/** 4.9 Account Page */
.billing__content {
  margin-top: 32px;
}
.billing__content-card:nth-last-child(1) .billing__content-card-body {
  border-bottom: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.billing__content-card-header h2 {
  margin-bottom: 20px;
  color: #1a1a1a;
  text-transform: capitalize;
}
.billing__content-card-body {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #e5e5e5;
}

.shoping-cart {
  padding-top: 40px;
}
@media (max-width: 991px) {
  .shoping-cart__content .cart-table {
    display: none;
  }
}
@media (min-width: 992px) {
  .shoping-cart__mobile {
    display: none;
  }
}
.shoping-cart__mobile .cart-table__share-content {
  border: 1px solid #e5e5e5;
  border-top: 0px;
}
.shoping-cart__mobile .shoping-card {
  border-bottom: 0px;
}

.cart-table {
  position: relative;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  overflow: hidden;
  margin-bottom: 24px;
}
.cart-table-title {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.03em;
  font-weight: 500;
  color: #808080;
  text-transform: uppercase;
  padding: 16px 24px !important;
  border-color: #e5e5e5 !important;
}
.cart-table tbody tr {
  position: relative;
}
.cart-table tbody tr::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 95%;
  height: 1px;
  background-color: #e5e5e5;
}
.cart-table tbody tr:nth-last-child(1)::after {
  height: 0px;
}
.cart-table-item {
  position: relative;
  padding: 12px 24px !important;
  border: 0px;
}
@media (max-width: 991px) {
  .cart-table-item {
    padding: 12px !important;
  }
}
.cart-table-item:nth-child(1) {
  padding-top: 0px !important;
}
.cart-table-item.order-subtotal p {
  margin-right: 15px;
}
.cart-table-item.stock-status .in,
.cart-table-item.stock-status .out {
  padding: 4px 8px;
  border-radius: 4px;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .cart-table-item.stock-status .in,
  .cart-table-item.stock-status .out {
    font-size: 10px;
  }
}
.cart-table-item.stock-status .in {
  background: #20b52633;
  color: #2c742f;
}
.cart-table-item.stock-status .out {
  background: #ea4b4833;
  color: #ea4b48;
}
.cart-table-item.add-cart .add-cart__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.cart-table-item.add-cart .add-cart__wrapper .button {
  margin-right: 25px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.cart-table-item.add-cart .add-cart__wrapper .button--disable {
  background-color: #f2f2f2;
  color: #b2b2b2;
}
.cart-table-item.order-date del {
  color: #999999;
  font-weight: 400;
}
.cart-table__product-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cart-table__product-item-img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin-right: 20px;
}
.cart-table__product-item-img img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}
.cart-table__product-item h5 {
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  font-weight: 400;
}
.cart-table-action-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 20px;
  border-top: 1px solid #e5e5e5;
}
.cart-table-action-btn .button {
  background-color: #f2f2f2;
  color: #4d4d4d;
  font-weight: 600;
}
@media (max-width: 480px) {
  .cart-table-action-btn .button {
    font-size: 12px;
    padding: 14px;
  }
}
.cart-table-action-btn .button:hover {
  background-color: #e5e5e5;
}
.cart-table__share-content {
  padding: 24px 20px;
  border-top: 1px solid #e5e5e5 !important;
}
.cart-table__share-content .social-icon-link {
  margin-right: 16px;
}
.cart-table__share-content .social-icon-link:hover a {
  color: #fff;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.cart-table__share-content .social-icon-link a {
  color: #4d4d4d;
}

.newsletter-card {
  position: relative;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  padding: 30px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .newsletter-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
@media (max-width: 991px) {
  .newsletter-card {
    margin-bottom: 24px;
  }
}
.newsletter-card-title {
  text-transform: capitalize;
  color: #1a1a1a;
}
@media (max-width: 1200px) {
  .newsletter-card-title {
    margin-bottom: 30px;
  }
}
.newsletter-card__input {
  position: relative;
  width: 650px;
  height: 52px;
  border-radius: 46px;
}
@media (max-width: 1400px) {
  .newsletter-card__input {
    width: 550px;
  }
}
@media (max-width: 767px) {
  .newsletter-card__input {
    width: 450px;
  }
}
@media (max-width: 480px) {
  .newsletter-card__input {
    width: 100%;
  }
}
.newsletter-card__input input {
  width: 80%;
  height: inherit;
  border-radius: inherit;
  overflow: hidden;
  border: 1px solid #e5e5e5;
  padding-left: 24px;
  padding-right: 50px;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
}
.newsletter-card__input input:hover {
  border-color: #cccccc;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.newsletter-card__input input::-webkit-input-placeholder {
  color: #999999;
}
.newsletter-card__input input:-ms-input-placeholder {
  color: #999999;
}
.newsletter-card__input input::-ms-input-placeholder {
  color: #999999;
}
.newsletter-card__input input::placeholder {
  color: #999999;
}
.newsletter-card__input button {
  position: absolute;
  top: 0;
  right: 0px;
  background-color: #333333;
  color: #fff;
  z-index: 9;
}
.newsletter-card__input button:hover {
  background-color: #4d4d4d;
}
@media (max-width: 480px) {
  .newsletter-card__input button {
    font-size: 14px;
    padding: 16px;
  }

  .free {
    display: none;
  }
}

/** 4.10 Error Page */
.error__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.error__content-img {
  margin-bottom: 32px;
}
.error__content h2 {
  margin-bottom: 20px;
  text-align: center;
}
@media (max-width: 767px) {
  .error__content h2 {
    font-size: 32px;
    line-height: 42px;
  }
}
.error__content p {
  width: 612px;
  margin: auto;
  font-size: 16px;
  line-height: 24px;
  color: #808080;
  text-align: center;
}
@media (max-width: 767px) {
  .error__content p {
    width: 100%;
  }
}
.error__content a.button {
  margin-top: 24px;
}

.templatecookie-btn {
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  z-index: 10000000000000000 !important;
}
.templatecookie-btn .purchase-btn {
  box-shadow: 0px 0px 3px 0px white !important;
  background-color: #171717 !important;
  color: white !important;
  width: max-content !important;
  display: flex !important;
  align-items: center !important;
  padding: 0px 20px !important;
  height: 50px !important;
  border-radius: 30px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  cursor: pointer !important;
  animation-name: wave-animation !important;
  animation-duration: 2.5s !important;
  animation-iteration-count: infinite !important;
  transform-origin: 70% 70% !important;
}
.templatecookie-btn .purchase-btn span svg {
  margin-left: 8px;
  display: block;
  width: 20px;
  height: 18px;
}
.templatecookie-btn .purchase-btn span svg path {
  stroke: white;
}
.templatecookie-btn .purchase-btn:hover {
  animation-name: unset !important;
}
@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.frz-mobile-footer {
  height: 56px;
  background-color: #fff;
  box-shadow: 0 -4px 20px rgb(0 0 0 / 6%);
  padding: 0 16px;
  position: fixed !important;
  bottom: 0;
  z-index: 10000;
}

.frz-lh-12 {
  line-height: 12px;
}

.frz-font-10 {
  font-size: 10px;
}

.frz-text-center {
  text-align: center;
}

.frz-footer-alignment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.frz-tp-8 {
  padding-top: 8px !important;
}

.frz-green {
  color: #43c6ac;
}

.frz-lh-13 {
  line-height: 13px;
}

.frz-fw-700 {
  font-weight: 700;
}

.frz-fw-700 {
  font-weight: 700;
}
.ptt {
  padding-top: 20px;
}

@media (max-width: 680px) {
  @media (max-width: 480px) {
    .cards-sm h5 {
      margin-top: 12px;
      color: #1a1a1a;
    }
    .nones {
      display: none;
    }
    .banner-slider--one .banner__wrapper--img-01 {
      height: 162px !important;
    }

    .banner-slider--one .banner__wrapper--img-02,
    .banner-slider--one .banner__wrapper--img-03 {
      height: 162px !important;
    }

    .banner__wrapper--img-02,
    .banner__wrapper--img-03 {
      height: 162px !important;
    }
    .banner__wrapper--img-01 {
      height: 162px !important;
    }
    .cards-sm__img-wrapper {
      height: 55px !important;
    }
    .cards-sm {
      text-align: center;
      padding: 0px 0px 24px 0px;
      border-radius: 5px;
      width: 100%;
      /*height: 100%;*/
      overflow: hidden;
      background-color: #f1f1f1;
      height: 92px !important;
    }
    .cards-sm h5 {
      margin-top: 0px !important;
      color: #1a1a1a;
    }
  }
}

@media (max-width: 380px) {
  .banner--01 .banner__wrapper-img {
    width: 300px !important;
  }
}

@media (max-width: 480px) {
  /* .banner--01 .banner__wrapper-img{ */
  /* width:162px !important; */
  /* } */

  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text h2,
  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text .h2 {
    font-size: 13px !important;
    line-height: 15px !important;
    padding-top: 40px !important;
  }
  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text .sale-off {
    margin: 10px 0px !important;
    padding-left: 4px !important;
    border-left: 2px solid #84d187 !important;
  }
  .banner--01 .banner__wrapper--img-01 .banner__wrapper-text .sale-off p {
    color: #cccccc;
    font-size: 12px !important;
    line-height: 17px !important;
  }

  .shop__sidebar {
    width: 160px !important;
    height: 100% !important;
    margin-top: 379px !important;
  }
}

.modal-lg {
  max-width: 580px;
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 580px;
  }
}

.quantity {
  position: absolute !important;
  top: 73px !important;
  margin: 0px 0px !important;
}
#myform {
  text-align: center !important;
  padding: 5px !important;
  border: 1px dotted #ccc !important;
  margin: 2% !important;
}

input.qty {
  width: 47px !important;
  text-align: center !important;
}
.qty {
  width: 40px !important;
  height: 25px !important;
  text-align: center !important;
}
input.qtyplus {
  width: 25px;
  height: 25px;
}
input.qtyminus {
  width: 25px;
  height: 25px;
}

.header__navigation-drop-menu::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 10px;
}
.sidebar-Categories #accordion {
  margin: 10px 20px 0;
}

.sidebar-Categories .card {
  background: none;
  border: none;
  padding: 0;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.sidebar-Categories .card-header {
  background: no-repeat;
  padding: 0;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.fav svg:hover {
  fill: #01b0de !important;
  border: none !important;
}
.header__navigation-menu-link a.cat {
  color: #000;
}
.padi {
  margin: 20px 0px !important;
}

.text-section {
  position: absolute;
  top: 50%;
  left: 86px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 999;
}
.text-section h1 {
  font-size: 45px;
  color: #fff;
  font-weight: bold;
}

.btn-slider {
  padding: 15px 35px;
  background-color: #fff;
  /* border-radius: 50%; */
  color: #0199c2;
  font-weight: bold;
}

.text-section p {
  font-size: 20px;
  font-weight: bold;

  color: #fff;
}

.s1 .cards-tm {
  width: 100%;
}
.s1:nth-child(3) .cards-tm {
  width: 100%;
}
.s1 {
  margin: 0px 10px;
  width: 360px !important;
}
.s1:last-child {
  margin-right: 0;
}
.slick-next {
  top: -54px !important;
  right: 10px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  background-color: #fff;
}
.slick-prev {
  right: 68px;
  top: -54px;
  left: auto;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  background-color: #fff;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-align: center;
  display: block;
  line-height: 45px;
  background: transparent;
}
