body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none !important;
}

a.white {
  text-decoration: none !important;
  color: white !important;
}

a.dul {
  text-decoration: underline !important;
}

p {
  margin: 0 !important;
}

sub,
sup {
  font-size: 0.65rem !important;
  margin-left: -0.25rem !important;
}

.button--md {
  font-size: 2vh;
}

.text-shadow {
  text-shadow: 0 0 6px #00000038;
}

.sale-percent {
  color: #9d0312;
  background-color: #fff0f1;
  padding: 5px;
  margin: 2px;
  border-radius: 3px;
}

.cards-tm__text {
  margin-top: 0;
}

/* PRODUCT INFO TILE */
.cards-md {
  position: relative;
}
.cards-md .cards-md__info {
  display: flex;
  flex-direction: column;
  padding: 0 10px 7px 10px;
}

.cards-md .cards-md__info-left {
  width: 100%;
  max-width: 100%;
}

.cards-md .cards-md__info-left h6 {
  font-weight: 700 !important;
  height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.cards-md .cards-md__info-price {
  float: right;
}

.cards-md .cards-md__info-left > span {
  float: left;
}

.cards-md .card-action-wrqpper {
  display: inline-block;
  width: 100%;
  height: auto;
  position: relative;
}

.cards-md .card-action-wrqpper .counter-btn-wrapper {
  display: inline-flex;
  margin: 0;
  position: relative;
  float: right;
}

.cards-md .cards-md__info-right {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  display: inline-flex;
  height: 40px;
  float: right;
  align-items: center;
}
.cards-md .cards-md__info-right > p {
  margin: 5px !important;
}

.cards-sm__img-wrapper img,
.cards-sm__icon-wrapper img {
  object-fit: fill !important;
}

.user-welcome-header button {
  margin: 0 10px;
}

.user-welcome-header button:hover {
  background-color: #f3f3f3;
}

.form-select:focus {
  box-shadow: none;
}

.form-error {
  display: block;
  color: red;
  margin-top: 10px;
}

.msg-registered {
  display: block;
  padding: 50px 0;
  text-align: center;
}

.fa {
  text-decoration: none !important;
}

.cards-md__info-rating li i {
  cursor: pointer;
  transform-origin: center;
  transition-duration: 0.5s;
  transform: scale(1) rotate(0deg);
}

.animate-rating {
  animation: rating-star 2s infinite;
}

@keyframes rating-star {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    color: red;
    transform: scale(1.5) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

/* STAR RATINGS */
.cards-md__info-rating-parent {
  position: relative;
  display: inline-block;
}

.cards-md__info-rating-upper {
  position: absolute;
  width: 0;
  overflow: hidden;
  padding: 0;
  top: 0;
  left: 0;
}

.shop__product-items .cards-md__info-price .font-body--lg-500,
.shop__product-items .cards-md__info-price .font-body--lg-400 {
  font-size: inherit;
}

.item-in-cart {
  pointer-events: none !important;
}

.item-in-cart .action-btn {
  background-color: #b2b2b2 !important;
}

.item-in-wishlist {
  background-color: #f74444 !important;
  color: white !important;
}

.action-btn:hover {
  color: #fff;
  background-color: #01b0df !important;
  border-color: transparent;
}

.loading-screen {
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 89%);
  flex-direction: column;
  align-content: center;
  justify-content: center;
  z-index: 1000;
}
.loading-screen .loading-contant {
  text-align: center;
  display: inline-block;
  font-size: 2rem;
  color: #6b6b6b;
}

a.button {
  text-decoration: none;
}
.shopping-cart {
  padding: 5px !important;
  z-index: 1000 !important;
}
.shopping-cart-header {
  background-color: #01b0df;
  margin-bottom: 0 !important;
  color: white;
}

.shopping-cart__product-content {
  padding: 5px !important;
}

.cart-header-btn {
  display: block;
  position: relative;
  background: none;
  height: 50px;
  width: 50px;
}

.shopping-cart-header .cart-header-btn {
  color: white;
}

.shopping-cart-bottom {
  text-align: center;
  padding: 0 10px 20px 10px;
}

.shopping-cart-product-info {
  margin-bottom: 0 !important;
}

.shopping-cart-bottom a {
  width: 100%;
}

.cart-empty {
  display: block;
  text-align: center;
  margin-top: 50px;
}

.cart-table .cart-table-item {
  padding: 12px !important;
}

.header__navigation-drop-menu {
  height: auto !important;
  max-height: 350px !important;
}

.shop__sidebar-content {
  width: 100%;
  max-width: 350px;
  height: 100%;
  overflow: auto;
  background-color: #fff;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #dee2e6;
}

.shop__sidebar-content a {
  color: #676767 !important;
  text-decoration: none;
}

.left__navigation-menu {
  padding-left: 0 !important;
  margin-left: -3px;
  width: auto;
  display: inline-block;
  margin-bottom: 0;
}

.left__navigation-menu-item {
  display: inline-block;
  margin: 1px 0;
  width: 100%;
}

.left__navigation-menu-item > a {
  display: block;
  background-color: #01b0df;
  width: 100%;
  border-bottom: 1px solid #8d8d8d;
  color: white !important;
  padding: 5px;
  text-align: center;
}

.left__navigation-menu-item > a:after {
  content: "\21B3";
  display: inline-block;
  position: absolute;
  top: -30px;
  left: -10px;
}

.dropdown-submenu {
  padding-left: 1.25rem !important;
  position: relative;
  background: #fafafa;
}

.dropdown-submenu::before {
  content: "\2022";
  display: inline-block;
  position: absolute;
  top: -28px;
  left: -10px;
}

.cards-tm h5 {
  margin-left: 40px;
}

/** HEADERS ALL **/

.header__top-content .header__top-left p,
.header__top-content .header__dropdown .bv_mainselect .bv_background,
.header__top-content .header__in a,
.header__top-content .header__in span {
  font-size: 0.9rem !important;
  line-height: 15.6px !important;
  color: #ffffff;
}

.header__navigation-menu {
  margin-bottom: 0 !important;
}

.header__sidebar.active {
  left: 0px;
  width: 308px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.mobile-menu-bg {
  background: red;
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1000;
}

.shop__sidebar-content .menu-item a {
  display: inline-block;
  margin: 5px 0;
  white-space: nowrap;
  padding: 2px;
  line-height: 110%;
}

.shop__sidebar-content .menu-item a:active,
.shop__sidebar-content .menu-item a:hover {
  text-decoration: underline !important;
  color: black !important;
}

.product-cardview_preloader {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background: #ffffffe3;
  top: 0;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  user-select: none;
}

@keyframes preload-anim {
  0% {
    background-position: -600px -100px;
  }
  100% {
    background-position: 600px -100px;
  }
}

/*MODALS*/
.modal {
  background-color: #000000cc;
}

.modal-lg,
.modal-xl,
.modal-lg.preview,
.modal-xl.preview {
  max-width: 952px !important;
}

/* product preview modal */
.modal-body .productsView .slick-track {
  height: 400px;
}

.modal-body .productsView .slick-slide > div {
  height: 100%;
}

.modal-body .productsView .slick-slide > div > img {
  object-fit: contain;
}

select#type {
  position: absolute;
  left: 471px;
  top: 0px;
  padding: 7px 0px;
  border-radius: 21px 0px 0px 21px;
  border: #ccc solid 1px;
}

.loader-productdetails-modal {
  display: block;
  text-align: center;
  padding: 50px;
}

.product-preview-carousal {
  max-width: 400px;
  margin: auto;
}

/* adjust preview modal img height */
.modal-body .productsView .slick-list {
  min-height: 400px;
  border: 1px solid #d1d1d1;
}

.counter-btn-wrapper {
  height: auto !important;
  padding: 2px !important;
}

.counter-btn-wrapper .item-count-product,
.counter-btn-counter[type="number"] {
  width: 100%;
  text-align: center;
  display: block;
  line-height: 34px;
}

.products__content-brand {
  display: flex;
  align-items: flex-start !important;
  justify-content: center;
  margin-bottom: 16px;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
}

.products__content-action-item {
  height: unset !important;
  padding: 4px;
}

.products__content-action .button-fav {
  height: 42px !important;
  width: 42px !important;
}

.product-desc.newsletter__social-icon {
  padding: 0 !important;
  margin: 0 !important;
}

.slick-dots.slick-thumb {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  bottom: -5px;
  white-space: nowrap;
}

.slick-dots.slick-thumb li {
  width: 60px;
  height: 60px;
  display: inline-block;
  position: relative;
  border: 1px solid #9e9e9e;
  box-sizing: border-box;
  overflow: hidden;
}

.slick-dots.slick-thumb img {
  width: 100%;
  position: relative;
}

.show-more-btn {
  display: block;
  text-align: center;
  user-select: none;
  padding: 10px;
  background-color: #f2f2f2;
  cursor: pointer;
}

/*popular-category*/

.popular-category_mobile {
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  flex-wrap: wrap;
}

.popular-category_mobile .cards-sm {
  max-width: 75px;
  margin: 5px;
  height: 90px;
  border: 1px solid #e3e3e3;
}

.cards-sm h5 {
  font-size: 0.9rem;
}

/*ALERT*/
.alert {
  position: fixed !important;
  opacity: 0;
  bottom: -100px;
  width: auto;
  max-width: 800px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000000;
  transition-duration: 0.25s;
}

.alert-show {
  opacity: 1;
  bottom: 40px;
}

.alert-hide {
  opacity: 0;
  bottom: -100px;
}

.alert-danger {
  color: white !important;
  background-color: #bf2230 !important;
  border-color: #9d0312 !important;
}

.alert-success {
  color: #ffffff !important;
  background-color: #008b4c !important;
  border-color: #018b4c !important;
}

@keyframes example {
  100% {
    display: none;
  }
}

@keyframes example {
  0% {
    bottom: -100px;
  }
  10% {
    bottom: 40px;
  }
  90% {
    bottom: 40px;
  }
  100% {
    bottom: -100px;
  }
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

/* category list */
.cat-list-container {
  height: 100%;
  border: 1px solid #01b0df;
  display: flex;
  flex-direction: column;
  max-height: 1000px;
}

.cat-list-heading {
  display: block;
  text-align: center;
  padding: 10px;
  background-color: #01b0df;
  color: white;
  font-size: 1.25rem;
  font-weight: 700;
}

/* mobile menu for product categories */
.cat-menu-mobile {
  display: none;
  position: relative;
}
.sub-cat-menu-mobile {
  display: none;
  margin-left: -10px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .rt {
    display: none;
  }
  .section__head {
    margin-bottom: 10px;
  }
  .alert {
    max-width: 90%;
  }
  /* .container-md, .container-sm, .container{
    max-width: unset !important;
  } */
}

/* HEADER SEARCH */
.header_searchfrom_mobile {
  display: none;
}

@media screen and (max-width: 992px) {
  .header__top-content {
    display: none !important;
  }
  /* HEADER SEARCH */
  .header_searchfrom_mobile {
    display: inline-flex;
    width: 100%;
  }
  .header_searchfrom_mobile .search-box {
    width: 100%;
    border: none;
    border-radius: 5px 0 0 5px;
    padding: 0 10px;
  }

  .header_searchfrom_mobile .search-btn {
    position: relative;
    background: #0000008f;
    border-radius: 0;
    height: auto;
    outline: none;
    border-radius: 0 5px 5px 0;
  }
}

@media screen and (max-width: 768px) {
  .all-categories .popular-categories__wrapper {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
  }

  .cat-list-container {
    display: none;
  }

  .cat-menu-mobile {
    display: block;
    position: relative;
    padding: 5px;
    background: #ffffff;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    font-size: 0 !important;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 10px;
  }

  .category-tile {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: relative;
    color: #077b9b !important;
    font-size: 1.5vh;
    line-height: 1.5vh;
    margin: 5px;
  }

  .left__navigation-menu .category-tile {
    width: 140px;
    height: 130px;
    margin: 5px 10px;
  }

  .left__navigation-menu .category-tile .tile-icon {
    object-fit: fill;
  }

  .category-tile-content {
    display: flex;
    height: 100%;
    width: 100%;
    color: #077b9b !important;
    border: 1px solid #01b0df;
    border-radius: 5px;
    position: absolute;
    white-space: break-spaces;
    text-align: center;
    flex-direction: column;
    padding: 0;
    pointer-events: all;
  }
  .category-tile.active,
  .category-tile.subcat,
  .category-tile.active .category-tile-content,
  .category-tile.subcat .category-tile-content {
    border-color: #32003f61 !important;
    color: #4e0060 !important;
    pointer-events: none !important;
    box-shadow: 0px 0px 5px;
    font-weight: 700;
  }

  .category-tile-content .tile-icon {
    height: 70%;
    display: block;
    border-bottom: 1px solid;
    object-fit: contain;
  }

  .category-tile-content .tile-icon .fa-2x {
    position: relative;
    top: 28%;
  }

  .category-tile-content > span {
    max-height: 3.25vh;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30%;
    /* line-height: 1.6; */
    /* white-space: nowrap; */
  }

  .shop__sidebar {
    display: none;
  }
  .sub-cat-menu-mobile {
    display: inline-block;
    position: relative;
  }
}

@media screen and (max-width: 480px) {
  .left__navigation-menu .category-tile {
    width: 80px;
    height: 80px;
  }

  .all-categories .popular-categories__wrapper {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .all-categories .cards-sm {
    padding: 0;
    max-width: 165px;
    background-color: #f1f1f1;
    height: 190px !important;
  }

  .all-categories .cards-sm__img-wrapper {
    height: unset !important;
  }

  .all-categories .cards-sm h5 {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    line-height: 1.2;
    position: relative;
    height: 35px;
    padding: 0 10px;
  }

  /* product tile */
  .shop__product-items .cards-md {
    display: flex;
    height: 120px;
  }

  .shop__product-items .cards-md__img-wrapper {
    width: 30%;
    min-width: 100px;
  }

  .shop__product-items .cards-md__img-wrapper a {
    padding: 0;
    height: 100px;
    position: relative;
    margin-top: 9px;
  }

  .shop__product-items .cards-md__img-wrapper a img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;
  }

  .shop__product-items .cards-md__info {
    padding: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 1000px;
    max-width: 100%;
  }

  .shop__product-items .cards-md__info .cards-md__info-rating-parent {
    display: none;
  }

  .shop__product-items .cards-md__info.align-items-center {
    align-items: flex-start !important;
  }

  .shop__product-items .cards-md__info .cards-md__info-left {
    max-width: 100%;
    width: 100%;
  }

  .shop__product-items .cards-md__info .cards-md__info-left h6 {
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    /* height: unset; */
    width: auto;
    margin: 2px 0;
    overflow: visible;
  }

  .shop__product-items .cards-md .cards-md__info-price {
    float: none;
    clear: both;
    font-size: 90%;
  }

  .shop__product-items .cards-md__info .cards-md__info-right {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    top: -5px;
   margin-right: -65px;
  }

  .shop__product-items .cards-md__info .cards-md__info-right p {
    width: 100%;
    text-align: right;
/*     padding-right: 5px; */
  }

  .shop__product-items .action-btn {
    min-width: 40px;
    min-height: 40px;
  }

  .shop--one {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 280px) {
  .all-categories .popular-categories__wrapper {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .all-categories .cards-sm__img-wrapper {
    height: unset !important;
  }

  .all-categories .cards-sm {
    padding: 0;
    max-width: 165px;
    background-color: #f1f1f1;
    height: 115px !important;
  }
}

.header__sidebar-menu {
  position: fixed;
  top: 0;
  left: -200%;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background: #000000db;
  transition: all 0.25s linear;
}

.header__close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
  line-height: 0;
}

.header__mobile-top {
  margin: 10px 10px 10px 0;
}

.header__mobile-bottom {
  background: #f9f9f9;
  padding: 20px;
}

.header__mobile-links {
  background: white;
  display: flex;
  height: 100%;
  width: 80%;
  max-width: 400px;
  top: 0;
  left: 0;
  position: absolute;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
}

.header__sidebar .header__sidebar-bg {
  background-color: #00000087;
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1000;
}

.header__mobile-menu-item-link {
  margin-left: 30px;
}

.header__mobile-menu-item-link .drop-icon {
  left: -22px !important;
}

.drop-icon {
  pointer-events: none;
}

.newsletter-popup__text-content p {
  text-transform: none !important;
}

.products__content-brand .brand-name {
  flex-wrap: wrap;
}

.products__content-brand .brand-name h2 {
  margin: 0;
  margin-right: 8px;
  line-height: 30px;
}

.products__content-brand .brand-name a {
  display: inline-block;
  color: #3e3426 !important;
  margin: 3px;
  text-decoration: none !important;
  background: #f9f5f1;
  font-size: 80%;
  padding: 8px 15px;
  white-space: nowrap;
  text-transform: lowercase;
  border-radius: 200px;
}

.products__content-item {
  display: block;
  text-align: left;
  margin: 5px 0;
}

.breadcrumb-target {
  color: #01b0df;
}

.product-testimonial {
  padding: 10px;
  margin: 5px;
}

.product-testimonial h5 {
  display: inline-block;
}

.product-testimonial span ul {
  padding: 0;
  margin: 0;
}
.search-header {
  margin-bottom: 20px;
}

.awssld__content > img,
.awssld__content > video {
  object-fit: fill !important;
}

/* home page banner left big */
.awssld {
  height: 100% !important;
  max-height: 600px !important;
  overflow: hidden !important;
}

@media screen and (max-width: 912px) {
  .awssld__bullets {
    display: none !important;
  }
  .banner__wrapper-img-sm {
    margin-top: 10px !important;
  }
}

.contact-form--input input,
.contact-form--input textarea {
  width: 100% !important;
}

.coupon-box .newsletter-card__input .coupon-info {
  display: none;
}

.coupon-applied .newsletter-card-title {
  text-align: inherit;
}

.coupon-applied .newsletter-card__input input {
  display: none;
}

.coupon-applied .newsletter-card__input button {
  min-width: 145px;
  background-color: #ff5722;
}

.coupon-applied .newsletter-card__input .coupon-info {
  display: flex;
  flex-direction: column;
  margin-right: 100px;
  border: 1px solid #ff5722;
  height: 51px;
  padding-right: 50px;
  padding-left: 20px;
  border-radius: 26px 0 0 26px;
  font-size: 50%;
  line-height: 150%;
  overflow: hidden;
}

@media screen and (max-width: 480px) {
  .cards-md__info .media-card {
    padding: 12px;
  }
}
.media-card {
  max-width: 100%;
}
@media screen and (max-width: 480px){
  .customhide{display:none !important;}}


  .disabledCursor { 
    cursor: default;
  }